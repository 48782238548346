import React from 'react'
import { noop, toInteger } from 'lodash-es'
import { Tooltip, Zoom } from '@material-ui/core'
// @ts-ignore
import { ReactComponent as PlusIcon } from '../assets/plus.svg'
// @ts-ignore
import { ReactComponent as MinusIcon } from '../assets/minus.svg'

interface Props {
  className?: string
  currentZoom: number
  isInteractive: boolean
  minZoom?: number
  maxZoom?: number
  onZoomIn?: () => void
  onZoomOut?: () => void
}

export const ZoomControls: React.FC<Props> = ({
  className = '',
  currentZoom,
  isInteractive,
  minZoom = 0,
  maxZoom = 100,
  onZoomIn = noop,
  onZoomOut = noop,
}) => {
  if (!currentZoom) return null

  const disableZoomIn = toInteger(currentZoom) >= maxZoom
  const disableZoomOut = toInteger(currentZoom) <= minZoom

  return (
    <div className={`absolute z-2 top-4 right-4 ${className}`}>
        <Tooltip title="Zoom In" arrow placement="left" TransitionComponent={Zoom}>
          <div>
            <Icon className="mb-1" onClick={onZoomIn} disable={disableZoomIn || !isInteractive}>
              <PlusIcon className="text-dark-grey w-3 hover:text-red"/>
            </Icon>
          </div>
          {/* div wrapper is necessary only to display the tooltip */}
        </Tooltip>
      
        <Tooltip title="Zoom Out" arrow placement="left" TransitionComponent={Zoom}>
          <div>
            <Icon onClick={onZoomOut} disable={disableZoomOut || !isInteractive}>
              <MinusIcon className="text-dark-grey w-3 hover:text-red" />
            </Icon>
          </div>
          {/* div wrapper is necessary only to display the tooltip */}
        </Tooltip>
    </div>
  )
}

interface IconProps {
  className?: string
  onClick: () => void
  disable: boolean
}
const Icon: React.FC<IconProps> = ({ className = '', onClick, disable, children }) => {
  return (
    <div
      className={`transition-colors duration-500 flex justify-center items-center box-content w-10 h-10 rounded shadow cursor-pointer bg-white ${
        disable ? 'bg-light-grey pointer-events-none' : ''
      } ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
