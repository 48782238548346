import React from 'react'
import ReactSelect, { OptionsType, StylesConfig } from 'react-select'

export type OptionType = { value: string; label: string }

interface Props {
  className?: string
  options: OptionsType<OptionType>
  [x: string]: any
}

export const Select: React.FC<Props> = ({ className = '', options, ...props }) => {
  // const customStyles: StylesConfig<OptionType, false> = {
  //   option: (provided, { data, isDisabled, isFocused, isSelected }) => {
  //     return {
  //       ...provided,
  //       backgroundColor: isDisabled ? 'grey' : isFocused ? '#E7E7E7' : 'white',
  //       color: '#555555',
  //     }
  //   },
  // }

  return (
    <div className={`overflow-visible ${className}`}>
      <ReactSelect
        options={options}
        menuPosition="fixed"
        // styles={customStyles}
        {...props}
      />
    </div>
  )
}
