import { cloneDeep, set, throttle } from 'lodash-es'
import { when } from 'mobx'
import { types as t, getRoot } from 'mobx-state-tree'
import {
  MAP_STYLES,
  VISIBILITY_VISIBLE,
  VISIBILITY_NONE,
  MAP_STYLE_AREE_URBANE,
} from '../lib/constants'
import { zoom as defaultZoom, mapCenter } from '../lib/map-config'
import { Vector, MapStyles, LayerInfo } from '../types'
import { StateInstance } from './index'

interface FlyToOptions {
  zoom?: number
  center?: [number, number]
  duration?: number
}

export const MapModel = t
  .model('MapModel', {
    hoveredFeatureId: t.maybe(t.string),
    clickedFeatureId: t.maybe(t.string),
    zoom: t.optional(t.number, defaultZoom),
    center: t.optional(t.frozen<Vector>(), mapCenter),
    mapRendered: t.optional(t.boolean, false),
    mapStyleKey: t.optional(t.string, MAP_STYLE_AREE_URBANE),
    mapStyles: t.optional(t.frozen<MapStyles>(), MAP_STYLES),
  })
  .views((self) => ({
    get root(): StateInstance {
      return getRoot<StateInstance>(self)
    },
  }))
  .views((self) => ({
    get sortedLayersByZIndexFromBottom(): [string, LayerInfo][] {
      const layers = self.mapStyles[self.mapStyleKey].layers
      return Object.entries(layers).sort((f, s) => f[1].zIndex - s[1].zIndex)
    },
    get sortedLayersByShowOrder(): [string, LayerInfo][] {
      const layers = self.mapStyles[self.mapStyleKey].layers
      return Object.entries(layers).sort((f, s) => f[1].showOrder - s[1].showOrder)
    },
  }))
  .actions((self) => ({
    setClickedFeatureId(id: string | number | undefined) {
      self.clickedFeatureId = String(id)
    },

    resetClickedFeatureId() {
      self.clickedFeatureId = undefined
    },

    setHoveredFeatureId(id: string | number | undefined) {
      self.hoveredFeatureId = String(id)
    },

    resetHoveredFeatureId() {
      self.hoveredFeatureId = undefined
    },
  }))
  .extend((self) => {
    let mapboxMap: mapboxgl.Map | undefined
    return {
      views: {
        get mapboxMap() {
          return self.mapRendered ? mapboxMap : undefined
        },

        get pngMapDataUrl() {
          if (!mapboxMap) return
          return mapboxMap.getCanvas().toDataURL('image/png')
        },
      },
      actions: {
        setMapboxMap(map: mapboxgl.Map) {
          mapboxMap = map
          self.mapRendered = true
        },
        unsetMapboxMap() {
          self.mapRendered = false
          mapboxMap = undefined
        },
      },
    }
  })
  .actions((self) => ({
    setZoomSilently(zoom: number) {
      self.zoom = zoom
    },

    resetZoomSilently() {
      self.zoom = defaultZoom
    },

    setCenterSilently(center: Vector) {
      self.center = center
    },

    resetCenterSilently() {
      self.center = mapCenter
    },

    setZoom(zoom: number) {
      self.zoom = zoom
      self.mapboxMap?.flyTo({ zoom })
    },

    setCenter(center: Vector) {
      self.center = center
      self.mapboxMap?.flyTo({ center })
    },

    setMapStyleKey(key: string) {
      self.mapStyleKey = key
    },

    resetMapStyleKey() {
      self.mapStyleKey = MAP_STYLE_AREE_URBANE
    },

    toggleLayerVisibility(layerKey: string) {
      const mapStylesNew = cloneDeep(self.mapStyles)
      const oldVisibility = self.mapStyles[self.mapStyleKey].layers[layerKey].visibility
      const newVisibility =
        oldVisibility === VISIBILITY_VISIBLE ? VISIBILITY_NONE : VISIBILITY_VISIBLE
      set(mapStylesNew, [self.mapStyleKey, 'layers', layerKey, 'visibility'], newVisibility)
      self.mapStyles = mapStylesNew
    },

    setMapStyles(mapStyles: MapStyles) {
      self.mapStyles = mapStyles
    },

    resetMapStyles() {
      self.mapStyles = MAP_STYLES
    },
  }))
  .actions((self) => {
    function onMapMove(e: mapboxgl.MapboxEvent) {
      const map = e.target
      const zoom = map.getZoom()
      const center = map.getCenter().toArray() as Vector
      self.setZoomSilently(zoom)
      self.setCenterSilently(center)
    }

    function followMap() {
      const eventName = 'move'
      const handler = throttle(onMapMove, 150, { trailing: true })
      self.mapboxMap!.on(eventName, handler)
    }

    return {
      flyTo(options: FlyToOptions) {
        const { center, zoom } = options
        if (center !== undefined) self.setCenterSilently(center)
        if (zoom !== undefined) self.setZoomSilently(zoom)
        self.mapboxMap?.flyTo({ center: center || self.center, zoom: zoom || self.zoom })
      },
      afterCreate() {
        when(
          () => Boolean(self.mapboxMap),
          () => followMap()
        )
      },
    }
  })
