import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

export function useLocalization(id: string): string {
  const intl = useIntl()
  const [message, set] = useState(() => intl.formatMessage({ id }))

  useEffect(() => {
    set(intl.formatMessage({ id }))
  }, [intl, id])

  return message
}
