import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../state'
import { Map } from './Map'
import { FiltersPanel } from './FiltersPanel'
import { FloatingButtons } from './FloatingButtons'
import { FiltersSummary } from './FiltersSummary'
import { Neighborhoods } from './Neighborhoods'
import { CollaboratePanel } from './CollaboratePanel'
import { Story } from './Story'
import { OtherPanel } from './OtherPanel'
import { InfoPanel } from './InfoPanel'
import { LayersButton } from './LayersButton'

interface Props {
  className?: string
}

export const Index: React.FC<Props> = observer(({ className = '' }) => {
  const {
    ui: {
      userCanInteract,
      showCollaboratePanel,
      showFiltersPanel,
      showAreaPanel,
      showStoryPanel,
      showOtherPanel,
      showInfoPanel,
      showFloatingButtons,
    },
    filters: { showFiltersSummary },
  } = useMst()

  return (
    <div className={`flex flex-col w-full h-full ${className}`}>
      <div className="flex-grow relative w-full h-full">
        {showCollaboratePanel ? (
          <CollaboratePanel />
        ) : (
          <div>
            {showFiltersSummary && <FiltersSummary />}
            {showFiltersPanel && <FiltersPanel />}
            {showAreaPanel && <Neighborhoods />}
            <Story className={`${showStoryPanel ? 'visible' : 'hidden'}`} />
            {showOtherPanel && <OtherPanel />}
            {showInfoPanel && <InfoPanel />}
            {showFloatingButtons && <FloatingButtons />}
            <LayersButton />
          </div>
        )}

        <Map isInteractive={userCanInteract} />
      </div>
    </div>
  )
})
