import { IReactionDisposer, reaction } from 'mobx'
import { getRoot, Instance, types as t } from 'mobx-state-tree'
import { nth } from 'lodash-es'
import { StoryStep } from '../types'
import { Timer, TimerEvent } from '../lib/Timer'
import { STORIES_STEP_DURATION } from '../lib/constants'
import { MAP } from '../components/FloatingButtons'

export const StoryModel = t
  .model('StoryModel', {
    id: t.string,
    steps: t.frozen<StoryStep[]>(),
    stepIndex: 0,
    isPlaying: false,
  })
  .views((self) => ({
    get root() {
      return getRoot(self) as any
    },
  }))
  .views((self) => ({
    get currentStep() {
      return nth(self.steps, self.stepIndex)
    },

    get hasNextStep() {
      return self.stepIndex < self.steps.length - 1
    },

    get hasPrevStep() {
      return self.stepIndex > 0
    },

    get exits() {
      return self.steps.length > 0
    },
  }))
  .actions((self) => ({
    setStepIndex(index: number) {
      self.stepIndex = index
    },

    resetStepIndex() {
      self.stepIndex = 0
    },

    setIsPlaying(isPlaying: boolean) {
      self.isPlaying = isPlaying
    },
  }))
  .extend(() => {
    let timer = new Timer(STORIES_STEP_DURATION)
    return {
      views: {
        get timer() {
          return timer
        },
      },
    }
  })
  .actions((self) => ({
    skipNext() {
      if (self.hasNextStep) {
        self.timer.reset()
        self.setStepIndex(self.stepIndex + 1)
      }
    },

    skipPrev() {
      if (self.hasPrevStep) {
        self.timer.reset()
        self.setStepIndex(self.stepIndex - 1)
      }
    },

    pauseStory() {
      self.timer.pause()
    },
  }))
  .actions((self) => {
    const applyCurrentStep = () => {
      const {
        currentStep,
        root: { filters, map },
      } = self
      if (!currentStep) return
      const { feature, activeFilters = {}, center, zoom, mapStyleKey, mapStyles } = currentStep
      filters.setStoryFilters(feature, activeFilters)
      map.setMapStyleKey(mapStyleKey)
      map.setMapStyles(mapStyles)
      map.flyTo({ center, zoom })
    }

    const handleRunning = (e: TimerEvent) => {
      self.setIsPlaying(e.running)
    }

    const resetTimer = () => {
      const nextIndex = self.hasNextStep ? self.stepIndex + 1 : 0
      self.timer.reset()
      self.setStepIndex(nextIndex)
      self.timer.start()
    }

    const playStory = () => {
      self.timer.start()
      applyCurrentStep()
    }

    let disposer: IReactionDisposer

    const afterCreate = () => {
      self.timer.on('start', handleRunning)
      self.timer.on('stop', handleRunning)
      self.timer.on('pause', handleRunning)
      self.timer.on('stop', resetTimer)
      disposer = reaction(() => [self.currentStep], applyCurrentStep)
    }

    const beforeDestroy = () => {
      self.timer.off('start', handleRunning)
      self.timer.off('stop', handleRunning)
      self.timer.off('pause', handleRunning)
      self.timer.off('stop', resetTimer)
      self.resetStepIndex()
      disposer()
    }

    const quitStoryModality = () => {
      const {
        ui: { isStoryModality, setModality },
      } = self.root
      if (isStoryModality) setModality(MAP)
      self.pauseStory()
    }

    return { afterCreate, beforeDestroy, playStory, resetTimer, quitStoryModality }
  })

export interface StoryModelInstance extends Instance<typeof StoryModel> {}
