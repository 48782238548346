import React from 'react'
import { observer } from 'mobx-react-lite'
import { noop } from 'lodash-es'
import { useMst } from '../state'
import { SUBHEADER_HEIGHT } from '../lib/constants'
import { MetadataSpreadsheetRow } from '../types'
// @ts-ignore
import { ReactComponent as ArrowIcon } from '../assets/arrow.svg'
import { useLocalization } from '../lib/hooks/useLocalization'

interface Props {
  className?: string
  width: number | string
  [x: string]: any
}

export const ObservableFeatures: React.FC<Props> = observer(
  ({ className = '', width, ...rest }) => {
    const {
      data: { observableFeatures },
      filters: { observingFeature, setObservingFeature },
    } = useMst()

    if (!observingFeature) return null

    return (
      <div className={`${className}`} style={{ width, height: SUBHEADER_HEIGHT }} {...rest}>
        <div className="w-full h-full flex flex-nowrap overflow-x-scroll">
          <div
            className="items-center justify-left py-5 flex flex-row leading-5 pl-5 mr-2"
            style={{ backgroundColor: '#EEEEEE', minWidth: 222 }}
          >
            <div className="leading-5 text-sm uppercase">{useLocalization('select_indicator')}</div>
            <ArrowIcon className="w-3 mx-3 pb-1" />
          </div>

          <div className="flex">
            {observableFeatures?.map((feature, i) => {
              const { question } = feature
              const isFeatureSelected = question === observingFeature.question

              return (
                <Feature
                  key={i}
                  isFeatureSelected={isFeatureSelected}
                  onClick={() => setObservingFeature(feature)}
                  feature={feature}
                />
              )
            })}
          </div>
          <div className="pr-4" />
        </div>
      </div>
    )
  }
)

interface FeatureProps {
  className?: string
  feature: MetadataSpreadsheetRow
  isFeatureSelected: boolean
  onClick: () => void
  disabled?: boolean
}
const Feature: React.FC<FeatureProps> = ({
  className = '',
  feature,
  isFeatureSelected,
  onClick,
  disabled = false,
}) => {
  return (
    <div
      className={`flex flex-shrink-0 justify-center items-center mx-3 uppercase ${
        isFeatureSelected
          ? 'text-black font-semibold border-b-2 border-black cursor-pointer'
          : disabled
          ? 'text-light-grey'
          : 'text-grey hover:text-black cursor-pointer'
      } ${className}`}
      onClick={disabled ? noop : onClick}
    >
      {feature.question}
    </div>
  )
}
