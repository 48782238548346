import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { extent } from 'd3-array'
import { scaleLinear, scaleBand } from 'd3-scale'
import { Vector, Value } from '../types'
import { useMst } from '../state'

interface Props {
  className?: string
  question: string
  width: number
  height: number
  [x: string]: any
}

const DEBUG = false
const MARGINS = {
  top: 0,
  rigth: 0,
  bottom: 0,
  left: 0,
}
const LABEL_RECT_HEIGHT = 24
const LABEL_RECT_TOP_PADDING = 4
const LABELS_AREA_HEIGHT = LABEL_RECT_HEIGHT + LABEL_RECT_TOP_PADDING

export const BarchartVertical: React.FC<Props> = observer(
  ({ className = '', question, width, height, ...rest }) => {
    const {
      filters: { addFilter, activeFilters, isFeatureFiltered, barchartFilterResult },
    } = useMst()
    const [hoveredBarIndex, setHoveredBarIndex] = useState<null | number>(null)

    const info = barchartFilterResult(question)
    const values = Object.values(info).map((i) => i.percentage)
    const [minValue, maxValue] = extent(values) as Vector
    const featureValues = Object.entries(info).sort(([, a], [, b]) => b.percentage - a.percentage)

    // only to avoid svg warning when width (or height) is 0 and so becomes negative
    const w = Math.max(0, width)
    const h = Math.max(0, height)

    const x = 0
    const y = 0
    const chartHeight = h - MARGINS.top - MARGINS.bottom
    const barsAreaHeight = chartHeight - LABELS_AREA_HEIGHT

    const categories = featureValues.map((f) => f[0])
    const barsScale = scaleBand<Value>()
      .domain(categories)
      .range([MARGINS.left, w - MARGINS.rigth])
      .paddingInner(0.1)
      .paddingOuter(0.2)
    const barWidth = barsScale.bandwidth()
    const heightScale = scaleLinear<number, number>()
      .domain([0, maxValue])
      .range([0, barsAreaHeight])

    const yTopBarsArea = y + MARGINS.top
    const yBottomBarsArea = yTopBarsArea + heightScale.range()[1]

    const isSomethingFiltered = isFeatureFiltered(question)

    return (
      <div className={`${className}`} {...rest}>
        <svg className="" width={w} height={h} x={x} y={y}>
          {DEBUG && (
            <g>
              <rect x={x} y={y} width={w} height={h} fill="none" stroke="black" />
              <rect
                x={x + MARGINS.left}
                y={y + MARGINS.top}
                width={w - MARGINS.left - MARGINS.rigth}
                height={h - MARGINS.top - MARGINS.bottom}
                fill="red"
                fillOpacity={0.1}
                stroke="red"
              />
              <line
                x1={barsScale.range()[0]}
                y1={yTopBarsArea}
                x2={barsScale.range()[1]}
                y2={yTopBarsArea}
                stroke="cyan"
                strokeWidth={1}
              />
            </g>
          )}

          <rect
            className="fill-grey"
            x={barsScale.range()[0]}
            y={yBottomBarsArea}
            width={barsScale.range()[1] - barsScale.range()[0]}
            height={1}
          />

          <g className="-bars">
            {featureValues.map(([category, { percentage, filteredPercentage }], i) => {
              const x = barsScale(category) as number

              const barPercentageHeight = heightScale(percentage)
              const barFilteredPercentageHeight = heightScale(filteredPercentage)
              const barPercentageYTop = yTopBarsArea + (barsAreaHeight - barPercentageHeight)
              const barFilteredPercentageYTop =
                yTopBarsArea + (barsAreaHeight - barFilteredPercentageHeight)

              // @ts-ignore
              const isSelected = activeFilters[question].includes(category)
              const isHovered = i === hoveredBarIndex
              const fillColor = computeFillColor(isSomethingFiltered, isSelected, isHovered)
              const labelRectStrokeColor = computeLabelRectStrokeColor(
                isSomethingFiltered,
                isSelected,
                isHovered
              )
              const labelRectFillColor = computeLabelRectFillColor(
                isSomethingFiltered,
                isSelected,
                isHovered
              )
              const textColor = computeTextColor(isSomethingFiltered, isSelected, isHovered)

              return (
                <g
                  key={i}
                  className="cursor-pointer"
                  onPointerEnter={() => setHoveredBarIndex(i)}
                  onPointerLeave={() => setHoveredBarIndex(null)}
                  onClick={() => addFilter(question, category)}
                >
                  <rect
                    className={`stroke-${fillColor} fill-transparent`}
                    x={x}
                    y={barPercentageYTop}
                    width={barWidth}
                    height={barPercentageHeight}
                  />
                  <rect
                    className={`fill-${fillColor}`}
                    x={x}
                    y={barFilteredPercentageYTop}
                    width={barWidth}
                    height={barFilteredPercentageHeight}
                  />

                  <rect
                    className={`stroke-${labelRectStrokeColor} fill-${labelRectFillColor}`}
                    x={x}
                    y={yBottomBarsArea + LABEL_RECT_TOP_PADDING}
                    width={barWidth}
                    height={LABEL_RECT_HEIGHT}
                  />
                  <text
                    className={`fill-${textColor}`}
                    x={x + barWidth / 2}
                    y={yBottomBarsArea + LABEL_RECT_TOP_PADDING + LABEL_RECT_HEIGHT / 2}
                    width={barWidth}
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    {category}
                  </text>
                </g>
              )
            })}
          </g>
        </svg>
      </div>
    )
  }
)

function computeFillColor(isSomethingFiltered: boolean, isSelected: boolean, isHovered: boolean) {
  if (isSelected) return 'dark-blue'
  else if (!isSomethingFiltered) {
    if (isHovered) return 'grey'
    else return 'mid-grey'
  } else {
    if (isHovered) return 'mid-grey'
    else return 'grey'
  }
}

function computeLabelRectStrokeColor(
  isSomethingFiltered: boolean,
  isSelected: boolean,
  isHovered: boolean
) {
  if (isSelected) return 'dark-blue'
  else if (!isSomethingFiltered) {
    return 'grey'
  } else {
    if (isHovered) return 'mid-grey'
    else return 'grey'
  }
}

function computeLabelRectFillColor(
  isSomethingFiltered: boolean,
  isSelected: boolean,
  isHovered: boolean
) {
  if (isSelected) return 'dark-blue'
  else return 'transparent'
}

function computeTextColor(isSomethingFiltered: boolean, isSelected: boolean, isHovered: boolean) {
  if (isSelected) return 'white'
  else if (!isSomethingFiltered) {
    if (isHovered) return 'dark-grey'
    else return 'dark-grey'
  } else {
    if (isHovered) return 'super-grey'
    else return 'grey'
  }
}
