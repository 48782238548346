import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { noop } from 'lodash-es'
import { useMst } from '../state'
import { Timer, TimerEvent } from '../lib/Timer'
import { useLocalization } from '../lib/hooks/useLocalization'
import { Divider, Header, Panel } from './Panel'
import { Select } from './Select'
// @ts-ignore
import { ReactComponent as SkipNextIcon } from '../assets/skip_next.svg'
// @ts-ignore
import { ReactComponent as SkipPrevIcon } from '../assets/skip_previous.svg'
// @ts-ignore
import { ReactComponent as PlayIcon } from '../assets/play.svg'
// @ts-ignore
import { ReactComponent as PauseIcon } from '../assets/pause.svg'

interface Props {
  className?: string
}

export const Story: React.FC<Props> = observer(({ className = '' }) => {
  const {
    ui: { setIsStoryPanelOpen, isMobile, toggleIsStoryPanelCollapsed, isStoryPanelCollapsed },
    data: { story, setStory, storiesData },
  } = useMst()

  if (!story) return null

  const { currentStep, timer } = story!
  const options = storiesData.map((s) => ({ value: s.id, label: s.id }))

  return (
    <Panel className={`${className}`} isCollapsed={isStoryPanelCollapsed}>
      <div className="mb-6">
        <Header
          onClick={
            isMobile ? () => toggleIsStoryPanelCollapsed() : () => setIsStoryPanelOpen(false)
          }
          collapsable={isMobile}
          isCollapsed={isStoryPanelCollapsed}
        >
          {useLocalization('story')}
        </Header>

        <Select
          className="mx-6"
          options={options}
          onChange={(e: any) => setStory(e.value)}
          defaultValue={options[0]}
          isClearable={false}
          isSearchable={false}
        />

        {/* <div className="mx-2">
          <select
            className="w-full border border-dark-grey"
            name="storySelect"
            id="storySelect"
            defaultValue={story?.id}
            onChange={(e) => setStory(e.target.value)}
          >
            {storiesData.map((story, i) => {
              return (
                <option key={i} value={story.id} onClick={() => setStory(story.id)}>
                  {story.id}
                </option>
              )
            })}
          </select>
        </div> */}

        <div className="mx-6">
          <StepsBar className="mt-3" timer={timer} />
        </div>
      </div>

      {!isStoryPanelCollapsed && (
        <div className="flex flex-col flex-grow bg-red-300 overflow-y-auto px-6 text-black">
          <div className="text-xl pb-2">{currentStep?.title}</div>
          <div
            className="mb-2 leading-snug text-dark-grey"
            dangerouslySetInnerHTML={{ __html: currentStep!.description as string }}
          />
        </div>
      )}

      <Divider className="mb-2" />

      <Controller className="mb-2" />
    </Panel>
  )
})

interface TimerProgressBarProps {
  className?: string
  isActive?: boolean
  timer: Timer
}
const TimerProgressBar: React.FC<TimerProgressBarProps> = ({
  timer,
  className = '',
  isActive = false,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const handleTick = (e: TimerEvent) => {
      const div = ref.current
      if (!div) return
      div.style.width = `${Math.min(1, e.elapsed / e.duration) * 100}%`
    }
    timer.on('all', handleTick)
    return () => timer.off('all', handleTick)
  }, [])

  return (
    <div className={`w-full h-2 bg-grey rounded-full overflow-hidden ${className}`}>
      {isActive && <div ref={ref} className="w-0 h-full bg-red" />}
    </div>
  )
}

interface StepsBarProps {
  className?: string
  timer: Timer
}

const StepsBar: React.FC<StepsBarProps> = observer(({ className = '', timer }) => {
  const {
    data: { story },
  } = useMst()

  return (
    <div className={`flex space-x-2 ${className}`}>
      {story?.steps.map((step, i) => {
        return (
          <div key={i} className="w-full">
            <TimerProgressBar key={story!.id} timer={timer} isActive={i === story.stepIndex} />
          </div>
        )
      })}
    </div>
  )
})

interface ControllerProps {
  className?: string
}
const Controller: React.FC<ControllerProps> = observer(({ className = '' }) => {
  const {
    data: { story },
  } = useMst()
  const { skipNext, skipPrev, playStory, pauseStory, isPlaying, hasPrevStep, hasNextStep } = story!

  return (
    <div className="flex justify-center">
      <div className={`inline-flex rounded p-1 ${className}`}>
        <Button className="mr-2" type="prev" disabled={!hasPrevStep} onClick={skipPrev} />
        {isPlaying ? (
          <Button className="mr-2" type="pause" disabled={false} onClick={pauseStory} />
        ) : (
          <Button className="mr-2" type="play" disabled={!hasNextStep} onClick={playStory} />
        )}

        <Button type="next" disabled={!hasNextStep} onClick={skipNext} />
      </div>
    </div>
  )
})

type ButtonType = 'prev' | 'play' | 'pause' | 'next'
interface ButtonProp {
  className?: string
  type: ButtonType
  onClick?: () => void
  disabled?: boolean
}
const Button: React.FC<ButtonProp> = ({
  className = '',
  type,
  onClick = noop,
  disabled = false,
}) => {
  const Icon = iconComponent(type, 'w-4 h-4')

  return (
    <div
      className={`shadow w-11 h-11 flex justify-center items-center rounded ${
        disabled ? '' : 'cursor-pointer'
      } bg-white ${disabled ? 'text-dark-grey' : 'text-red'} hover:bg-lighterest-grey ${className}`}
      onClick={disabled ? noop : onClick}
    >
      {Icon}
    </div>
  )
}

function iconComponent(type: ButtonType, className: string) {
  switch (type) {
    case 'prev':
      return <SkipPrevIcon className={className} />
    case 'play':
      return <PlayIcon className={className} />
    case 'pause':
      return <PauseIcon className={className} />
    case 'next':
      return <SkipNextIcon className={className} />
    default:
      throw new Error(`"${type}" is not a valid button type.`)
  }
}
