import {
  FormType,
  FORMTYPE_CHECKBOX,
  FORMTYPE_DROPDOWN,
  FORMTYPE_MULTIPLE_CHOICE,
  FORMTYPE_PARAGRAPH,
  FORMTYPE_SCALE,
  FORMTYPE_TEXT,
  Measure,
  MEASURE_AVERAGE,
  MetadataSpreadsheetRow,
  Tab,
  Viz,
} from '../types'
import { HORIZONTAL_BARCHART, VERTICAL_BARCHART, RANGE_BARCHART, TRUE } from './constants'

export function chartType(formType: FormType): Viz {
  switch (formType) {
    case FORMTYPE_TEXT:
    case FORMTYPE_PARAGRAPH:
    case FORMTYPE_CHECKBOX:
    case FORMTYPE_DROPDOWN:
    case FORMTYPE_MULTIPLE_CHOICE:
      return HORIZONTAL_BARCHART
    case FORMTYPE_SCALE:
      return RANGE_BARCHART
    default:
      throw new Error(`Something went wrong matching form type ('${formType}') to chart.`)
  }
}

export function toRigthType(value: any, type: FormType): Number | String | Boolean {
  switch (type) {
    case FORMTYPE_TEXT:
    case FORMTYPE_SCALE:
      return rawToNumber(value)

    case FORMTYPE_PARAGRAPH:
    case FORMTYPE_DROPDOWN:
    case FORMTYPE_MULTIPLE_CHOICE:
      return rawToString(value)

    case FORMTYPE_CHECKBOX:
      return rawToBolean(value)

    default:
      throw new Error(`Something went wrong converting "${value}".`)
  }
}

export function rawToString(value: any) {
  return value.toString()
}
export function rawToNumber(value: any) {
  return value === '' ? NaN : Number(value.replace(',', '.'))
}
export function rawToBolean(value: any) {
  return value === TRUE ? true : false
}

export function parseMetadata(metadata: MetadataSpreadsheetRow[]): MetadataSpreadsheetRow[] {
  const result = metadata.map((metadatum) => ({
    ...metadatum,
    question_check: rawToBolean(metadatum.question_check),
    question: rawToString(metadatum.question),
    'question-label': rawToString(metadatum['question-label']),
    hint: rawToString(metadatum.hint),
    is_required: rawToBolean(metadatum.is_required),
    tab: rawToString(metadatum.tab) as Tab,
    type: rawToString(metadatum.type) as FormType,
    is_observable: rawToBolean(metadatum.is_observable),
    measure: (rawToString(metadatum.measure) as Measure) || DEFAULT_MEASURE,
    text_range_min: rawToNumber(metadatum.text_range_min),
    text_range_max: rawToNumber(metadatum.text_range_max),
    bars_numbers: rawToNumber(metadatum.bars_numbers) || DEFAULT_BAR_NUMBER,
    exclude_from_site: rawToBolean(metadatum.exclude_from_site),
  }))
  return result
}

export const DEFAULT_BAR_NUMBER = 5
export const DEFAULT_MEASURE = MEASURE_AVERAGE as Measure
export const DEFAULT_HEATMAP_VALUE = 0
