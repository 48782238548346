import { GeoJSONSourceRaw, LngLat } from 'mapbox-gl'
import { Vector } from '../types'
import { buildGeojsonSource } from './map-utils'
import { circle, Properties, Units } from '@turf/turf'

export const mapCenter = [11.3395, 44.4961] as Vector // Bologna
export const mapCenterLngLat = { lng: mapCenter[0], lat: mapCenter[1] } as LngLat
export const mapBounds = [
  [0, 0],
  [90, 90],
] as [Vector, Vector]

export const mapBoundsStory = [
  [10.9234, 44.2653],
  [11.7052, 44.6397]
] as [Vector, Vector]

export const mapBoundsCrowd = [
  [10, 44],
  [12, 45]
] as [Vector, Vector]


export const zoom = 11
export const maxZoom = 20
export const minZoom = 10.5
export const mapBoundsPadding = 200



export const mapDefaultConfig = {
  center: mapCenter,
  maxBounds: mapBoundsStory,
  zoom: zoom,
  maxZoom: maxZoom,
  minZoom: minZoom,
  bearing: 0,
  pitch: 0,
  keyboard: false,
  dragPan: true,
  dragRotate: false,
  trackResize: true,
  pitchWithRotate: false,
  touchZoomRotate: false,
}

export const mapCoverDefaultConfig = {
  center: mapCenter,
  maxBounds: mapBounds,
  zoom: 15.1,
  maxZoom: maxZoom,
  minZoom: minZoom,
  bearing: 0,
  pitch: 50,
  keyboard: false,
  dragPan: true,
  dragRotate: false,
  trackResize: true,
  pitchWithRotate: false,
  touchZoomRotate: false,
  interactive: false,
  preserveDrawingBuffer: true,
  style: `mapbox://styles/fondazioneinnovazioneurbana/ckqge4f1v3kj418prw3jdddgu`,
}

export const crowdmapDefaultConfig = {
  center: mapCenter,
  maxBounds: mapBoundsCrowd,
  zoom: zoom,
  maxZoom: maxZoom,
  minZoom: minZoom,
  bearing: 0,
  pitch: 0,
  keyboard: false,
  dragPan: true,
  dragRotate: false,
  trackResize: true,
  pitchWithRotate: false,
  touchZoomRotate: false,
  style: 'mapbox://styles/fondazioneinnovazioneurbana/ckqlyjwco0e4o17nn8yuof5xn',
}
const radius = 15
const options = {
  steps: 1000,
  units: 'kilometers' as Units,
  properties: {} as Properties,
}
export const crowdmapCircleBoundaryFeature = circle(mapCenter, radius, options)
export const crowdmapCircleBoundarySource = buildGeojsonSource([
  crowdmapCircleBoundaryFeature,
]) as GeoJSONSourceRaw
