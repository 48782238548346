import React from 'react'
import { observer } from 'mobx-react-lite'
import './main.css'
import { useMst } from './state'
import { Homepage } from './components/Homepage'
import { Index } from './components/Index'
import { FullPageLoader } from './components/FullPageLoader'
import { Header } from './components/Header'
import { MenuPage } from './components/MenuPage'
import { AboutPage } from './components/AboutPage'
import { TutorialPage } from './components/TutorialPage'
import { VideoPage } from './components/VideoPage'
import { Loader } from './components/Loader'

export const App: React.FC = observer(() => {
  const {
    ui: {
      isInHomepage,
      isMenuPageOpen,
      isAboutPageOpen,
      isTutorialPageOpen,
      isVideoPageOpen,
      isMobile,
      isHomepageModalDatasetOpen,
      headerHeight,
    },
    retrievingFromSerialized,
  } = useMst()

  return (
    <div className="w-full h-full relative flex flex-col">
      <Loader />

      <Header />

      <div style={{ height: isMenuPageOpen ? '100%' : `calc(100% - ${headerHeight}px)` }}>
        {isMenuPageOpen ? <MenuPage /> : isInHomepage ? <Homepage /> : <Index />}
        {retrievingFromSerialized && <FullPageLoader />}
      </div>

      {isTutorialPageOpen && <TutorialPage />}

      {isVideoPageOpen && <VideoPage />}

      {isAboutPageOpen && <AboutPage />}

      {isMobile && isHomepageModalDatasetOpen && (
        <div className="w-full h-full absolute top-0 left-0 z-1 bg-black bg-opacity-30" />
      )}
    </div>
  )
})
