import { EventSystem } from './EventSystem'

export interface TimerEvent {
  type: 'tick' | 'stop' | 'start' | 'pause' | 'reset' | 'all'
  timer: Timer
  elapsed: number
  duration: number
  running: boolean
  completed: boolean
}

export class Timer {
  private _duration: number
  private events: EventSystem
  private intervalId?: number
  private startTime: number

  private _elapsed: number
  private _running: boolean
  private _completed: boolean

  constructor(duration = Infinity) {
    this._duration = duration
    this.events = new EventSystem()
    this.startTime = Date.now()

    this._elapsed = 0
    this._running = false
    this._completed = false
  }

  get elapsed() {
    return this._elapsed
  }

  get duration() {
    return this._duration
  }

  get running() {
    return this._running
  }

  get completed() {
    return this._completed
  }

  private fire(eventname: TimerEvent['type']) {
    const event: TimerEvent = {
      type: eventname,
      timer: this,
      elapsed: this._elapsed,
      duration: this._duration,
      running: this._running,
      completed: this._completed,
    }
    this.events.fire(eventname, event)
    this.events.fire('all', event)
  }

  private tick() {
    this._elapsed = Date.now() - this.startTime
    this.fire('tick')

    const timeToStop = this._duration - this._elapsed

    if (timeToStop <= 0) {
      this._completed = true
      this._running = false
      this.fire('stop')
    } else {
      const nextTick = Math.min(timeToStop, 1000 / 60)
      this.intervalId = setTimeout(() => this.tick(), nextTick) as any
    }
  }

  start() {
    if (this._running || this._completed) return
    this._running = true
    this.startTime = Date.now() - this._elapsed
    this.fire('start')

    this.tick()
  }

  pause() {
    this._running = false
    clearInterval(this.intervalId)
    this.fire('pause')
  }

  reset(duration?: number) {
    this._completed = false
    this._elapsed = 0
    this.startTime = Date.now()
    if (duration != null) {
      this._duration = duration
    }
    this.fire('reset')
  }

  on(eventname: TimerEvent['type'], callback: (event: TimerEvent) => void) {
    this.events.subscribe(eventname, callback)
  }
  off(eventname: TimerEvent['type'], callback: (event: TimerEvent) => void) {
    this.events.unsubscribe(eventname, callback)
  }
}
