import React from 'react'
import { observer } from 'mobx-react-lite'
import { extent } from 'd3-array'
import { scaleLinear, scaleBand } from 'd3-scale'
import { Vector } from '../types'
import { useMst } from '../state'
import { RangeSlider } from './RangeSlider'
import { FilterValueInfo } from '../state/Filters'
import { MetadataSpreadsheetRow } from '../types'

const DEBUG = false
const RADIUS = 10
const DRAGGING_RADIUS = 15
const SPACE_BETWEEN_BARS_AND_SLIDER = 0
const SLIDER_AREA_HEIGHT = DRAGGING_RADIUS + SPACE_BETWEEN_BARS_AND_SLIDER
const LABEL_RECT_HEIGHT = 15
const LABEL_RECT_TOP_PADDING = 40
const LABELS_AREA_HEIGHT = LABEL_RECT_HEIGHT + LABEL_RECT_TOP_PADDING
const BAR_STROKE_WIDTH = 1
const MARGINS = {
  top: 0,
  rigth: 2,
  bottom: 0,
  left: 2,
}

interface Props {
  className?: string
  question: string
  width: number
  height: number
  [x: string]: any
}

export const BarchartRange: React.FC<Props> = observer(
  ({ className = '', question, width, height, ...rest }) => {
    const {
      data: { metadataByQuestion },
      filters: { activeFilters, addRangeFilter, rangeFilterResult },
    } = useMst()

    const metadatum = metadataByQuestion[question] as MetadataSpreadsheetRow
    const { uniqValues } = metadatum

    // only to avoid svg warning when width (or height) is 0 and so becomes negative
    const w = Math.max(0, width)
    const h = Math.max(0, height)

    const x = 0
    const y = 0
    const chartAreaHeight = h - MARGINS.top - MARGINS.bottom
    const barsAreaHeight = chartAreaHeight - LABELS_AREA_HEIGHT - SLIDER_AREA_HEIGHT

    const yTopBarsArea = y
    const yBottomBarsArea = y + barsAreaHeight
    const yTopSlider = yBottomBarsArea
    const yTopLabels = yTopSlider + SLIDER_AREA_HEIGHT

    const info = rangeFilterResult(question) as Record<string, FilterValueInfo>
    const values = Object.values(info).map((i) => i.percentage)
    const [minValue, maxValue] = extent(values) as Vector
    const featureValues = Object.entries(info).sort(([, a], [, b]) => b.percentage - a.percentage)

    const categories = Object.keys(info)
    const barsScale = scaleBand()
      .domain(categories)
      .range([x + MARGINS.left + DRAGGING_RADIUS, w - MARGINS.rigth - DRAGGING_RADIUS])
      .paddingInner(0.1)
      .paddingOuter(0.2)
    const barWidth = barsScale.bandwidth()
    const heightScale = scaleLinear<number, number>()
      .domain([0, maxValue])
      .range([0, barsAreaHeight - BAR_STROKE_WIDTH])

    const [minFilteredValue, maxFilteredValue] = activeFilters[question] as Vector

    return (
      <div className={`${className}`} {...rest}>
        <svg width={w} height={h} x={x} y={y}>
          {DEBUG && (
            <g>
              <rect x={x} y={y} width={w} height={h} fill="none" stroke="black" />
              <rect
                x={x + MARGINS.left}
                y={y + MARGINS.top}
                width={w - MARGINS.left - MARGINS.rigth}
                height={h - MARGINS.top - MARGINS.bottom}
                fill="none"
                stroke="steelblue"
              />
              <rect
                x={barsScale.range()[0]}
                y={y + MARGINS.top}
                width={barsScale.range()[1] - barsScale.range()[0]}
                height={h - MARGINS.top - MARGINS.bottom}
                fill="none"
                stroke="red"
              />
              <rect
                x={barsScale.range()[0]}
                y={yTopBarsArea}
                width={barsScale.range()[1] - barsScale.range()[0]}
                height={barsAreaHeight}
                fill="green"
                fillOpacity={0.1}
              />
              <rect
                x={barsScale.range()[0]}
                y={yTopSlider}
                width={barsScale.range()[1] - barsScale.range()[0]}
                height={SLIDER_AREA_HEIGHT}
                fill="orange"
                fillOpacity={0.1}
              />
              <rect
                x={barsScale.range()[0]}
                y={yTopLabels}
                width={barsScale.range()[1] - barsScale.range()[0]}
                height={LABEL_RECT_HEIGHT}
                fill="purple"
                fillOpacity={0.1}
              />
            </g>
          )}

          {/* bars */}
          <g>
            {featureValues.map(([bucket, { percentage, filteredPercentage }], i) => {
              const x = barsScale(bucket)
              const barPercentageHeight = heightScale(percentage)
              const barFilteredPercentageHeight = heightScale(filteredPercentage)
              const barPercentageYTop = yTopBarsArea + (barsAreaHeight - barPercentageHeight)
              const barFilteredPercentageYTop =
                yTopBarsArea + (barsAreaHeight - barFilteredPercentageHeight)

              return (
                <g key={i}>
                  <rect
                    className={`stroke-dark-blue fill-transparent`}
                    x={x}
                    y={barPercentageYTop}
                    width={barWidth}
                    height={barPercentageHeight}
                    rx={2}
                  />
                  <rect
                    className={`fill-dark-blue`}
                    x={x}
                    y={barFilteredPercentageYTop}
                    width={barWidth}
                    height={barFilteredPercentageHeight}
                    rx={2}
                  />
                </g>
              )
            })}
          </g>

          <RangeSlider
            width={Math.max(0, barsScale.range()[1] - barsScale.range()[0])}
            outerDomain={uniqValues}
            innerDomainStart={activeFilters[question] as Vector}
            updateDomain={(newRange: Vector) => addRangeFilter(question, newRange)}
            x={barsScale.range()[0]}
            y={yTopSlider - DRAGGING_RADIUS + BAR_STROKE_WIDTH + 2} // + 2 same of range rect height
            radius={RADIUS}
            draggingRadius={DRAGGING_RADIUS}
            barsScale={barsScale}
          />

          <g transform={`translate(${0}, ${yTopLabels + LABEL_RECT_TOP_PADDING})`}>
            <text
              className="fill-super-grey font-medium"
              x={barsScale.range()[0]}
              y={-15}
              textAnchor="start"
              dominantBaseline="hanging"
              fontSize={LABEL_RECT_HEIGHT}
            >
              {`${Math.round(minFilteredValue)}`}
            </text>
            <text
              className="fill-mid-grey"
              x={barsScale.range()[0]}
              y={0}
              textAnchor="start"
              dominantBaseline="hanging"
              fontSize={LABEL_RECT_HEIGHT - 2}
            >
              {`min: ${uniqValues[0]}`}
            </text>
            <text
              className="fill-super-grey font-medium"
              x={barsScale.range()[1]}
              y={-15}
              textAnchor="end"
              dominantBaseline="hanging"
              fontSize={LABEL_RECT_HEIGHT}
            >
              {`${Math.round(maxFilteredValue)}`}
            </text>
            <text
              className="fill-mid-grey"
              x={barsScale.range()[1]}
              y={0}
              textAnchor="end"
              dominantBaseline="hanging"
              fontSize={LABEL_RECT_HEIGHT - 2}
            >
              {`max:${uniqValues[1]}`}
            </text>
          </g>
        </svg>
      </div>
    )
  }
)
