import { useRef, useState, useEffect, useCallback } from 'react'

export interface DragEvent {
  deltaX: number
  deltaY: number
}

const getPageXY = (event: any) => {
  const { pageX, pageY } = event.touches?.[0] ?? event
  return { pageX, pageY }
}

export function useDrag(
  onDrag: (event: DragEvent) => void,
  onDragStart = () => {},
  onDragEnd = () => {},
  autoCursor = true,
  givenRef: any = null
) {
  const ref = givenRef ? givenRef : useRef(null)

  const startDragRef = useRef({ x: null, y: null })
  const [isDragging, setIsDragging] = useState(false)

  const handleMouseMove = useCallback(
    (event) => {
      if (event.cancelable) {
        event.preventDefault()
      }

      if (!onDrag) return

      const { pageX, pageY } = getPageXY(event)
      // @ts-ignore
      const deltaX = pageX - startDragRef.current.x
      // @ts-ignore
      const deltaY = pageY - startDragRef.current.y
      onDrag({ deltaX, deltaY })
    },
    [onDrag, startDragRef, isDragging, setIsDragging]
  )

  const handleMouseUp = useCallback(() => {
    startDragRef.current = { x: null, y: null }
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
    document.removeEventListener('touchmove', handleMouseMove)
    document.removeEventListener('touchend', handleMouseUp)
    setIsDragging(false)
    onDragEnd && onDragEnd()
  }, [setIsDragging, startDragRef, handleMouseMove, onDragEnd])

  const handleMouseDown = useCallback(
    (event) => {
      if (event.cancelable) {
        event.preventDefault()
      }

      const { pageX: x, pageY: y } = getPageXY(event)
      startDragRef.current = { x, y }
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
      document.addEventListener('touchmove', handleMouseMove, { passive: false })
      document.addEventListener('touchend', handleMouseUp, { passive: false })
      setIsDragging(true)
      onDragStart && onDragStart()
    },
    [startDragRef, handleMouseMove, handleMouseUp, onDragStart]
  )

  useEffect(() => {
    const refCurrent = ref.current

    if (refCurrent) {
      refCurrent.addEventListener('mousedown', handleMouseDown)
      refCurrent.addEventListener('touchstart', handleMouseDown, { passive: false })
    }

    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener('mousedown', handleMouseDown)
        refCurrent.removeEventListener('touchstart', handleMouseDown, { passive: false })
      }
    }
  }, [ref, handleMouseDown])

  useEffect(() => {
    const refCurrent = ref.current
    if (refCurrent && autoCursor) {
      if (isDragging) {
        document.body.style.cursor = 'grabbing'
        refCurrent.style.cursor = 'grabbing'
      } else {
        document.body.style.cursor = ''
        refCurrent.style.cursor = 'grab'
      }
    }
  }, [ref, isDragging, autoCursor])

  return givenRef ? [isDragging] : [ref, isDragging]
}
