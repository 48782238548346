import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../state'
import { MARGIN_PANELS, MAX_DESCRIPTION_LENGTH } from '../lib/constants'
import { CollaborateDatum } from '../types'
import { useLocalization } from '../lib/hooks/useLocalization'

const FORM_ID = 'textarea-description'
const TITLE_AREA_HEIGHT = 95
const CONTENT_AREA_HEIGHT = (isMobile: boolean, isOpen: boolean) =>
  isOpen ? (isMobile ? 305 : 370) : 0
const BUTTON_AREA_HEIGHT = 67
const ALERT_HEIGHT = 44
const VERTICAL_SPACE_BETWEEN_ALERT_AND_PANEL = 10
const TOTAL_HEIGHT = (isMobile: boolean, isOpen: boolean) =>
  TITLE_AREA_HEIGHT + CONTENT_AREA_HEIGHT(isMobile, isOpen) + BUTTON_AREA_HEIGHT

export const CollaboratePanel: React.FC = observer(({}) => {
  const {
    ui: { isMobile, isColaboratePopupVisible, thereWasACloudFunctionError },
  } = useMst()

  return (
    <>
      {isColaboratePopupVisible && <AlertPopup />}
      {thereWasACloudFunctionError && <AlertErrorPopup />}
      <div
        className={`absolute z-4 ${
          isMobile ? 'w-19/20 left-0 right-0 mx-auto' : 'right-4'
        } flex flex-col bg-white rounded-lg`}
        style={{ bottom: MARGIN_PANELS(isMobile) }}
      >
        <TitleArea />
        <HorizontalDivider />
        <ContentArea />
        <ButtonArea />
      </div>
    </>
  )
})
interface TitleAreaProps {
  className?: string
}
export const TitleArea: React.FC<TitleAreaProps> = observer(({ className = '' }) => {
  const {
    ui: { isMobile, isCollaboraPanelOpen, setIsCollaboraPanelOpen },
  } = useMst()
  return (
    <div
      className={`${isMobile ? 'w-19/20' : 'w-80'} flex flex-col pt-5 px-6 ${className}`}
      style={{ height: TITLE_AREA_HEIGHT }}
    >
      <div className="text-xl text-red">{useLocalization('collaborate')}</div>
      {isCollaboraPanelOpen ? (
        <div
          className={`py-2 uppercase text-sm text-mid-grey hover:text-black cursor-pointer ${className}`}
          onClick={() => setIsCollaboraPanelOpen(false)}
        >
          {`< ${useLocalization('restart')}`}
        </div>
      ) : (
        <div className={`text-dark-grey`}>{useLocalization('center_pointer')}</div>
      )}
    </div>
  )
})

interface ContentAreaProps {
  className?: string
}
export const ContentArea: React.FC<ContentAreaProps> = observer(({ className = '' }) => {
  const {
    data: { activeDatasetInfo },
    ui: { isMobile, isCollaboraPanelOpen, errorMessages },
  } = useMst()

  return (
    <div
      className={`${
        isMobile ? 'w-full' : 'w-80'
      } flex flex-col px-6 animate duration-500 ${className}`}
      style={{ height: CONTENT_AREA_HEIGHT(isMobile, isCollaboraPanelOpen) }}
    >
      <div className="overflow-y-auto h-full flex flex-col justify-between">
        <div>
          <div
            className="pt-3"
            dangerouslySetInnerHTML={{ __html: activeDatasetInfo.description }}
          />

          <div className="flex flex-grow mt-3">
            <Textarea />
          </div>
          <div className="text-red text-xs mt-1 mb-3">
            {errorMessages.map((errMsg, i) => (
              <div key={i}>{errMsg}</div>
            ))}
          </div>
        </div>
        <div className="text-dark-grey text-xs">{useLocalization('report_data')}</div>
      </div>
    </div>
  )
})

interface TextareaProps {
  className?: string
}
export const Textarea: React.FC<TextareaProps> = observer(({ className = '' }) => {
  const {
    data: { collaborateCoordinates, collaborate, activeDatasetInfo },
    ui: {
      crowdmapAnswer,
      setCrowdmapAnswer,
      isCrowdmapAnswerValid,
      setIsCollaboraPanelOpen,
      setIsColaboratePopupVisible,
      setThereWasACloudFunctionError,
      setIsLoading,
    },
  } = useMst()

  const [text, setText] = useState<string>('')

  return (
    <form
      className={`w-full h-24 ${className}`}
      id={FORM_ID}
      onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        if (isCrowdmapAnswerValid) {
          const isSuccess = await collaborate(crowdmapAnswer as CollaborateDatum)
          setThereWasACloudFunctionError(!isSuccess)
          setIsLoading(false)
          if (isSuccess) {
            setText('')
            setIsCollaboraPanelOpen(false)
            setIsColaboratePopupVisible(true)
          }
        }
        setIsLoading(false)
      }}
    >
      <textarea
        className="border-2 w-full h-full resize-none p-2 rounded-md"
        value={text}
        placeholder={useLocalization('collaborate_placeholder')} // a hint to the user of what can be entered in the control
        required={true} // specifies that the user must fill in a value before submitting a form
        autoFocus={true} // lets you specify that a form control should have input focus when the page loads
        maxLength={MAX_DESCRIPTION_LENGTH + 1} // maximum number of characters that the user can enter
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          const description = e.target.value
          setText(description)
          setCrowdmapAnswer({
            description,
            Latitude: collaborateCoordinates!.lat,
            Longitude: collaborateCoordinates!.lng,
            category: activeDatasetInfo.title,
          })
        }}
      />
    </form>
  )
})

interface ButtonAreaProps {
  className?: string
}
export const ButtonArea: React.FC<ButtonAreaProps> = observer(({ className = '' }) => {
  const {
    ui: {
      isMobile,
      isCollaboraPanelOpen,
      setIsCollaboraPanelOpen,
      setIsColaboratePopupVisible,
      isCrowdmapAnswerValid,
    },
  } = useMst()

  return (
    <div
      className={`${isMobile ? 'w-full' : 'w-80'} px-6 pt-2`}
      style={{ height: BUTTON_AREA_HEIGHT }}
    >
      <div
        className={`flex justify-center items-center rounded shadow ${
          isCollaboraPanelOpen && !isCrowdmapAnswerValid
            ? 'cursor-not-allowed bg-lighterest-grey text-mid-grey'
            : 'cursor-pointer border border-red text-red hover:text-white hover:bg-red'
        } ${className}`}
        style={{ height: 39 }}
        onClick={() => {
          if (isCollaboraPanelOpen && !isCrowdmapAnswerValid) return
          if (!isCollaboraPanelOpen) {
            setIsCollaboraPanelOpen(true)
            setIsColaboratePopupVisible(false)
          }
        }}
      >
        {isCollaboraPanelOpen ? (
          <input
            className={`${
              !isCrowdmapAnswerValid ? 'cursor-not-allowed' : 'cursor-pointer'
            } w-full h-full bg-transparent`}
            type="submit"
            form={FORM_ID}
            value={useLocalization('report')}
            disabled={!isCrowdmapAnswerValid}
          />
        ) : (
          useLocalization('add_location')
        )}
      </div>
    </div>
  )
})

interface AlertPopupProps {
  className?: string
  ms?: number
}
const AlertPopup: React.FC<AlertPopupProps> = observer(({ className = '', ms = 5000 }) => {
  const {
    ui: { setIsColaboratePopupVisible, isMobile },
  } = useMst()

  const [toShow, setToShow] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setToShow(false)
      setIsColaboratePopupVisible(false)
    }, ms)
    return () => clearTimeout(timer)
  }, [])

  const label = useLocalization('thanks_report')

  if (!toShow) return null

  return (
    <div
      className={`absolute z-4 ${
        isMobile ? 'w-19/20 left-0 mx-auto right-0' : 'w-80 right-4'
      } flex bg-white rounded-lg ${className}`}
      style={{
        height: ALERT_HEIGHT,
        bottom:
          TOTAL_HEIGHT(isMobile, false) +
          VERTICAL_SPACE_BETWEEN_ALERT_AND_PANEL +
          MARGIN_PANELS(isMobile),
      }}
    >
      <div className="w-2 h-full bg-red rounded-tl-lg rounded-bl-lg" />
      <div className="flex flex-grow items-center pl-2 py-4">{label}</div>
    </div>
  )
})

interface AlertErrorPopupProps {
  className?: string
  ms?: number
  message?: string
}
const AlertErrorPopup: React.FC<AlertErrorPopupProps> = observer(
  ({ className = '', ms = 5000, message = "C'è stato un errore :( Riprova." }) => {
    const {
      ui: { setIsColaboratePopupVisible, isMobile },
    } = useMst()

    const [toShow, setToShow] = useState(true)

    useEffect(() => {
      const timer = setTimeout(() => {
        setToShow(false)
        setIsColaboratePopupVisible(false)
      }, ms)
      return () => clearTimeout(timer)
    }, [])

    if (!toShow) return null

    return (
      <div
        className={`absolute z-4 ${
          isMobile ? 'w-19/20 left-0 mx-auto right-0' : 'w-80 right-4'
        } flex bg-white rounded-lg ${className}`}
        style={{
          height: ALERT_HEIGHT,
          bottom:
            TOTAL_HEIGHT(isMobile, true) +
            VERTICAL_SPACE_BETWEEN_ALERT_AND_PANEL +
            MARGIN_PANELS(isMobile),
        }}
      >
        <div className="w-2 h-full bg-black rounded-tl-lg rounded-bl-lg" />
        <div className="flex flex-grow items-center pl-2 py-4">{message}</div>
      </div>
    )
  }
)

interface HorizontalDividerProps {
  className?: string
}
export const HorizontalDivider: React.FC<HorizontalDividerProps> = ({ className = '' }) => {
  return <div className={`border border-lighter-grey ${className}`} />
}
