export const USER_KEY = 'user'
export const ADMIN = 'admin'
const USER = [ADMIN] as const
export const FULL_SCREEN = 'fullscreen'

type User = typeof USER[number]

function isValidUser(user: string | null): user is User {
  return USER.includes(user as User)
}

export function getUserType(): User | null {
  const urlParams = new URLSearchParams(window.location.search)
  const user = urlParams.get(USER_KEY)
  if (!isValidUser(user)) return null
  return user
}

export function getEnableDownloadData() {
  const urlParams = new URLSearchParams(window.location.search)
  const isFullScreen = urlParams.has(FULL_SCREEN)
  const enableMapDownload = !isFullScreen
  return enableMapDownload
}
