import React from 'react'
import { observer } from 'mobx-react-lite'
// @ts-ignore
import { ReactComponent as CloseIcon } from '../assets/close.svg'

interface Props {
  className?: string
  value: string | number
  onClick: () => void
  [x: string]: any
}

export const Pill: React.FC<Props> = observer(({ className = '', value, onClick, ...rest }) => {
  return (
    <div
      className={`cursor-pointer flex items-center rounded-md px-2 py-px ${className}`}
      onClick={onClick}
      {...rest}
    >
      <div className="pr-3 text-xs" style={{marginTop:`4px`}}>
        {value}
        </div>
      <CloseIcon style={{ width: 10 }} />
    </div>
  )
})
