import React from 'react'
import { observer } from 'mobx-react-lite'

interface Props {
  className?: string
  onClick: () => void
  children: React.ReactNode
  [x: string]: any
}

export const TextButton: React.FC<Props> = observer(
  ({ className = '', onClick, children, ...rest }) => {
    return (
      <div
        className={`cursor-pointer text-dark-grey hover:text-dark-blue hover:font-semibold font-normal underline ${className}`}
        style={{ fontSize: 14 }}
        onClick={onClick}
        {...rest}
      >
        {children}
      </div>
    )
  }
)
