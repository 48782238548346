import { SIGTTOU } from 'constants'
import { extent, min, max } from 'd3-array'
import { trim } from 'lodash-es'
import { Vector } from '../types'

/**
 * Return the biggest extent range.
 */
export function numericExtent(dataset: any[], userRange: Vector): Vector {
  const [minDataValue, maxDataValue] = extent(dataset)
  const [minRangeValue, maxRangeValue] = userRange
  const minRange = min([minDataValue, minRangeValue])
  const maxRange = max([maxDataValue, maxRangeValue])
  return [minRange, maxRange] as Vector
}

/**
 * From "N, N, label, label" to { range: [N, N], labels: [label, label] }.
 */
export function parseScaleTypeDefinition(
  definition: string
): { range: Vector; labels: [string, string] } {
  const values = definition.split(',')
  const rawRange = values.slice(0, 2)
  const range = rawRange.map((s: string) => Number(trim(s))) as Vector // remove whitespace and trasform to number
  const labels = values.slice(-2) as [string, string]
  return { range, labels }
}
