import React from 'react'
import { observer } from 'mobx-react'
import { round } from 'lodash-es'
import { useMst } from '../state'
import { GeoJSONFeature } from '../types'
import { getCodiceAreaStatistica, getNomeAreaStatistica } from '../lib/map-utils'
import { Divider, Header, Panel } from './Panel'
import { useLocalization } from '../lib/hooks/useLocalization'
// @ts-ignore
import { ReactComponent as LensIcon } from '../assets/lens.svg'

interface Props {
  className?: string
}

export const Neighborhoods: React.FC<Props> = observer(({ className = '' }) => {
  const {
    data: { sortedFilteredNeighborhoods },
    ui: { setNeighborhoodSearch, neighborhoodSearch, setIsAreaPanelOpen },
  } = useMst()

  function handleSearchInput(e: React.ChangeEvent<HTMLInputElement>) {
    setNeighborhoodSearch(e.target.value)
  }

  return (
    <Panel className={`${className}`} position="right">
      <Header onClick={() => setIsAreaPanelOpen(false)}>{useLocalization('ranking')}</Header>

      <div className="flex items-center border border-light-grey mx-2 rounded">
        <LensIcon className="w-3 m-4 text-black" />
        <input
          type="text"
          className="py-3 pr-2 flex flex-grow"
          placeholder={`${useLocalization('rankingPlaceholder')}`}
          onChange={handleSearchInput}
          value={neighborhoodSearch}
        />
      </div>

      <Divider className="my-2" />

      <div className="overflow-y-scroll h-full pt-2">
        {(sortedFilteredNeighborhoods as GeoJSONFeature[]).map((f) => (
          <Neighborhood neighborhood={f} key={f.id} />
        ))}
      </div>
    </Panel>
  )
})

interface NeighborhoodProps {
  neighborhood: GeoJSONFeature
}

const Neighborhood: React.FC<NeighborhoodProps> = observer(({ neighborhood }) => {
  const {
    map: { hoveredFeatureId, setHoveredFeatureId, clickedFeatureId, setClickedFeatureId },
    data: { featuresMeasureValuesById, neighborhoodsMaxValue },
  } = useMst()

  const isHovered = hoveredFeatureId && hoveredFeatureId === String(neighborhood.id)
  const isClicked = clickedFeatureId && clickedFeatureId === String(neighborhood.id)
  const statisticAreaName = getNomeAreaStatistica(neighborhood)
  const statisticAreaCode = getCodiceAreaStatistica(neighborhood)
  const neighborhoodValue = featuresMeasureValuesById[statisticAreaCode] || 0
  const formattedValue = round(neighborhoodValue, 2)
  const formattedAndAdjustedValue = formattedValue === Infinity ? '-' : formattedValue
  const part = neighborhoodValue / neighborhoodsMaxValue
  const width = `${Math.round(part * 100)}%`
  const height = 4

  return (
    <div className="flex flex-col pb-5">
      <div
        className={`px-6 flex justify-between items-center pb-2 cursor-pointer  ${
          isHovered || isClicked ? 'font-semibold text-black bg-lighterest-grey' : ' text-dark-grey'
        }`}
        onMouseOver={() => setHoveredFeatureId(neighborhood.id)}
        onClick={() => setClickedFeatureId(neighborhood.id)}
      >
        <span>{statisticAreaName}</span>
        <span>{formattedAndAdjustedValue}</span>
      </div>

      <div className="px-6 mb-1">
        <div className={`h-1 rounded-full relative bg-lightest-grey w-full`} style={{ height }}>
          <div
            className={`rounded-full h-full absolute top-0 left-0 ${
              isHovered || isClicked ? 'bg-black' : 'bg-mid-grey'
            }`}
            style={{ width }}
          />
        </div>
      </div>
    </div>
  )
})
