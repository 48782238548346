import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { extent } from 'd3-array'
import { ScaleLinear, scaleLinear } from 'd3-scale'
import { Vector } from '../types'
import { useMst } from '../state'
import { formatPercentage } from '../lib/format-utils'
import { numericLabelWidth } from './FeatureFilter'

interface Props {
  className?: string
  question: string
  width: number
  [x: string]: any
}

export const BarchartHorizontal: React.FC<Props> = observer(
  ({ className = '', question, width, ...rest }) => {
    const {
      filters: { addFilter, activeFilters, barchartFilterResult },
    } = useMst()
    const [hoveredIndex, setHoveredIndex] = useState<null | number>(null)

    const info = barchartFilterResult(question)
    const values = Object.values(info).map((i) => i.percentage)
    const [minValue, maxValue] = extent(values) as Vector
    const featureValues = Object.entries(info).sort(([, a], [, b]) => b.percentage - a.percentage)

    const barWidthScale = scaleLinear()
      .domain([0, maxValue])
      .range([0, Math.max(0, width - numericLabelWidth)])

    return (
      <div className={`${className}`} {...rest}>
        {featureValues.map(([valueName, valueInfo], valueIndex) => {
          const { filteredDatasetCount, percentage, filteredPercentage } = valueInfo
          const isHovered = hoveredIndex === valueIndex
          const formattedFilteredPercentage = formatPercentage(filteredPercentage || 0)
          const isChecked = (activeFilters[question] as string[]).includes(valueName)
          const textColor = isHovered ? 'super-grey' : isChecked ? 'dark-blue' : 'dark-grey'
          const barColor = isHovered ? 'super-grey' : isChecked ? 'dark-blue' : 'mid-grey'
          const backgroundIndicator = isHovered ? '#e6f2fc' : isChecked ? '#f5faff' : ''

          return (
            <div
              key={valueIndex}
              className="cursor-pointer py-2 px-4"
              onClick={() => addFilter(question, valueName)}
              onPointerEnter={() => setHoveredIndex(valueIndex)}
              onPointerLeave={() => setHoveredIndex(null)}
              style={{backgroundColor: backgroundIndicator}}
            >
              <div className={`text-md pt-3 text-${textColor}`}>{valueName}</div>

              <div className="flex justify-between items-center">
                <Bar
                  className="mt-1 flex flex-grow"
                  percentage={percentage}
                  filteredPercentage={filteredPercentage}
                  widthScale={barWidthScale}
                  color={barColor}
                />
                <div
                  className={`text-${barColor} text-right text-xs mr-1 pt-1 font-semibold`}
                  style={{ width: numericLabelWidth }}
                >
                  {`${filteredDatasetCount}`}
                  <span className={`font-normal`}> {`| ${formattedFilteredPercentage}`}</span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
)

interface BarProps {
  className?: string
  filteredPercentage: number
  percentage: number
  widthScale: ScaleLinear<number, number>
  color: string
  [x: string]: any
}
const Bar: React.FC<BarProps> = observer(
  ({ className = '', filteredPercentage, percentage, widthScale, color, ...rest }) => {
    const barHeight = 4
    const strokeWidth = 1
    const containerWidth = widthScale.range()[1]
    const emptyBarWidthScaled = widthScale(percentage)
    const filledBarWidthScaled = widthScale(filteredPercentage)

    return (
      <div className={`${className}`} {...rest}>
        <svg
          width={containerWidth + 2 * strokeWidth}
          height={barHeight + 2 * strokeWidth}
          x={0}
          y={0}
        >
          <rect
            className={`fill-${color}`}
            x={strokeWidth}
            y={strokeWidth}
            width={filledBarWidthScaled}
            height={barHeight}
            rx={2}
          />
          <rect
            className={`stroke-${color}`}
            x={strokeWidth}
            y={strokeWidth}
            width={emptyBarWidthScaled}
            height={barHeight}
            fill="none"
            strokeWidth={strokeWidth}
            rx={2}
          />
        </svg>
      </div>
    )
  }
)
