import React from 'react'
import { observer } from 'mobx-react-lite'
import { noop } from 'lodash-es'
import { useMst } from '../state'
import { useLocalization } from '../lib/hooks/useLocalization'
import { Divider, Header, Panel } from './Panel'

interface Props {
  className?: string
}

export const OtherPanel: React.FC<Props> = observer(({ className = '' }) => {
  const {
    ui: { setIsOtherPanelOpen, setIsInfoPanelOpen, enabledDownloadData },
    map: { pngMapDataUrl },
  } = useMst()

  const closePanel = () => setIsOtherPanelOpen(false)
  const downloadMapLabel = useLocalization('download_map')
  const exploreDatasetLabel = useLocalization('explore_dataset')
  const moreInfoLabel = useLocalization('more_info')

  return (
    <Panel className={`${className}`} position="bottom">
      <Header onClick={closePanel}>{`${useLocalization('altro')}`}</Header>
      <Divider />

      {enabledDownloadData && (
        <a href={`${pngMapDataUrl}`} download="map.png">
          <Row
            className="border-b border-lightest-grey"
            onClick={() => closePanel()}
            label={downloadMapLabel}
          />
        </a>
      )}

      <Row
        onClick={() => {
          setIsInfoPanelOpen(true)
          closePanel()
        }}
        label={moreInfoLabel}
      />
    </Panel>
  )
})

interface RowProps {
  className?: string
  onClick: () => void
  label: string
  disabled?: boolean
}
const Row: React.FC<RowProps> = ({ className = '', onClick, label, disabled = false }) => {
  return (
    <div
      className={`${className} px-6 py-3 hover:bg-lighterest-grey ${
        !disabled ? 'text-dark-grey hover:text-black cursor-pointer' : 'text-light-grey'
      }`}
      onClick={!disabled ? onClick : noop}
    >
      {label}
    </div>
  )
}
