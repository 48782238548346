import { observer } from 'mobx-react-lite'
import React from 'react'
import { useMst } from '../state'
import ReactPlayer from 'react-player'
// @ts-ignore
import introvideo from '../assets/Montage.mp4'
import { useLocalization } from '../lib/hooks/useLocalization'

export const VideoPage: React.FC = observer(() => {
  const {
    ui: { setIsVideoPageOpen },
  } = useMst()

  return (
    <div className="flex flex-col w-full h-full absolute  py-4 top-0 left-0 z-5 bg-video-grey">
      <div className="top-0 sticky z-1 px-6">
        <div
          className="flex text-xs underline pb-2 pt-3 mx-2 text-white justify-center text-md cursor-pointer border border-video-grey hover:border-white"
          onClick={() => setIsVideoPageOpen(false)}
        >
          {useLocalization('close')}
        </div>
        <div className="border-b border-gray-300 my-3"></div>
      </div>
      <ReactPlayer
        url={introvideo}
        loop
        volume={0.1}
        autoPlay
        controls={true}
        playing
        width="100vw"
        height="calc(100% - 50px)"
      />
    </div>
  )
})
