import React from 'react'

interface Props {
  className?: string
  [x: string]: any
}

export const LegendIconLine: React.FC<Props> = ({ className = '', w, ...props }) => {
  const x = 0
  const y = 0
  const h = w
  const strokeWidth = 1.5
  const l = w / 4

  return (
    <svg width={w} height={h} x={x} y={y}>
      <path
        d={`M${0} ${3 * l}, L${1.2 * l} ${2.3 * l}, L${l} ${l}, L${2.5 * l} ${2.5 * l}, L${
          2.5 * l
        } ${l}, L${w} ${0.8 * l}`}
        fill="none"
        strokeWidth={strokeWidth}
        {...props}
      />
    </svg>
  )
}
