import React from 'react'
import { useDrag, DragEvent } from '../lib/hooks/useDrag'

interface Props {
  className?: string
  x: number
  y: number
  onDrag: (e: DragEvent) => void
  onDragStart: () => void
  onDragEnd: () => void
  width?: number
  height?: number
  fill?: string
  fillOpacity?: number
  rx?: number
  ry?: number
  [key: string]: any
}

export const DraggableRect: React.FC<Props> = ({
  className = '',
  x,
  y,
  onDrag,
  onDragStart,
  onDragEnd,
  width = 100,
  height = 30,
  fill = 'black',
  fillOpacity = 1,
  rx = 0,
  ry = 0,
  ...props
}) => {
  const [ref] = useDrag(onDrag, onDragStart, onDragEnd)

  return (
    <rect
      className={`cursor-pointer select-none ${className}`}
      ref={ref}
      x={x}
      y={y}
      width={width}
      height={height}
      rx={rx}
      ry={ry}
      fill={fill}
      opacity={fillOpacity}
      {...props}
    />
  )
}
