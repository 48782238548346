import {
  mapValues,
  groupBy,
  maxBy,
  meanBy,
  minBy,
  sumBy,
  xor,
  zipObject,
  range,
  uniq,
  fromPairs,
  difference,
} from 'lodash-es'
import { extent } from 'd3-array'
import {
  Measure,
  MEASURE_AVERAGE,
  MEASURE_COUNT,
  MEASURE_MAX,
  MEASURE_MIN,
  MEASURE_MODE,
  MEASURE_SUM,
  Vector,
} from '../types'

type Iteratee<T> =
  | string
  | number
  | symbol
  | [string | number | symbol, any]
  | ((value: T) => unknown)
export function summarize<T, U>(
  dataset: T[],
  grouper: Iteratee<T>,
  groupReducer: (group: T[]) => U,
  uniqValues: string[] = []
) {
  const grouped = groupBy(dataset, grouper)
  const summary = mapValues(grouped, groupReducer)
  const summaryKeys = Object.keys(summary)
  const missingKeys = difference(uniqValues, summaryKeys)
  const pairs = missingKeys.map((k) => [k, 0])
  return { ...summary, ...fromPairs(pairs) }
}

export function measureFunctionByType<T>(type: Measure, feature: string): (group: T[]) => number {
  switch (type) {
    case MEASURE_AVERAGE:
      return (group) => meanBy(group, feature)
    case MEASURE_MODE:
      return (group) => modeBy(group, feature)
    case MEASURE_SUM:
      return (group) => sumBy(group, feature)
    case MEASURE_MIN:
      return (group) => {
        const minObj = minBy(group, feature)
        return minObj ? minObj[feature] : 0
      }
    case MEASURE_MAX:
      return (group) => {
        const maxObj = maxBy(group, feature)
        return maxObj ? maxObj[feature] : 0
      }
    case MEASURE_COUNT:
      return (group) => group.length
    default:
      throw new Error(
        `Measure type is not correct. Its value is '${type}' but should be one of the following values: 'sum', 'mean', 'min', 'max', 'length'.`
      )
  }
}

export function addOrRemove<T>(collection: T[], value: T) {
  return xor(collection, [value])
}

export function extentRoundedToStep(dataset: number[], step: number): Vector {
  const [minValue, maxValue] = extent(dataset) as Vector
  const roundedMinValue = Math.floor(minValue / step) * step
  const roundedMaxValue = Math.ceil(maxValue / step) * step
  return [roundedMinValue, roundedMaxValue]
}

export function isBetween(value: number, [min, max]: Vector) {
  if (min > max) throw new Error(`Your min value is greater than the max value: ${min} > ${max}.`)
  return value >= min && value < max
}

export function isBetweenIncluded(value: number, [min, max]: Vector) {
  if (min > max) throw new Error(`Your min value is greater than the max value: ${min} > ${max}.`)
  return value >= min && value <= max
}

export function matrix2json<T extends object = any>(matrix: string[][]): T[] {
  const keys = matrix[0]
  return matrix.slice(1).map((values) => zipObject(keys, values)) as T[]
}

/**
 * Given a range and a number of bucktes, it returns the buckets.
 * Example:
 *    [0, 10], 5 → [[0, 2], [2, 4], [4, 6], [6, 8], [8, 10]]
 */
export function bucketsFromRange(extent: Vector, bucketsNumber: number): Vector[] {
  const [min, max] = extent
  const bucketRange = (max - min) / bucketsNumber
  return range(1, bucketsNumber + 1).map((i) => {
    const start = bucketRange * (i - 1) + min
    const end = start + bucketRange
    return [start, end]
  })
}

function valuesWithFrequency(values: number[]): Array<{ value: number; freq: number }> {
  const uniqValues = uniq(values)
  return uniqValues.map((value) => ({ value, freq: values.filter((v) => v === value).length }))
}

function moreFrequentlyOccurringValues(values: number[]) {
  const valuesWithFreq = valuesWithFrequency(values)
  const frequencies = valuesWithFreq.map((f) => f.freq)
  const maxFreq = Math.max(...frequencies)
  const moreFrequentlyOccurringValuesWithFreq = valuesWithFreq.filter((f) => f.freq === maxFreq)
  return moreFrequentlyOccurringValuesWithFreq.map((d) => d.value)
}

function mode(values: number[]) {
  return Math.max(...moreFrequentlyOccurringValues(values))
}

function modeBy<T>(dataset: T[], key: string) {
  const values = dataset.map((d) => d[key])
  return mode(values)
}
