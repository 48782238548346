import { LayersVisibility, MapStyles } from '../types'

export const WHITE = (opacity = 1) => `rgba(255, 255, 255, ${opacity})`
export const BLACK = (opacity = 1) => `rgba(0, 0, 0, ${opacity})`
export const GOLD = (opacity = 1) => `rgba(250, 202, 80, ${opacity})`
export const SPRING_GREEN = (opacity = 1) => `rgba(32, 247, 183, ${opacity})`
export const RED = (opacity = 1) => `rgba(230, 0, 45, ${opacity})` // FIU red
export const BLUE = (opacity = 1) => `rgba(10, 95, 165, ${opacity})` // FIU dark blue
export const BLUE_MAP = (opacity = 1) => `rgba(39, 70, 165, ${opacity})` // FIU dark blue

export const DOTS_COLOR = RED()
export const DOTS_STROKE_COLOR = WHITE(0.2)
export const DOTS_STROKE_WIDTH = 1
export const FEATURES_FILL_COLOR = (opacity = 1) => BLUE_MAP(opacity)
export const FEATURES_STROKE_COLOR = BLACK()

export const HEADER_HEIGHT = 50
export const SUBHEADER_HEIGHT = 56

export const HORIZONTAL_BARCHART = 'horizontalBarchart'
export const VERTICAL_BARCHART = 'verticalBarchart'
export const RANGE_BARCHART = 'rangeBarchart'

export const STORIES_STEP_DURATION = 10_000

export const MARGIN_PANELS = (isMobile: boolean) => (isMobile ? 8 : 16)

export const TRUE = 'TRUE'
export const FALSE = 'FALSE'
export const VISIBILITY_VISIBLE = 'visible'
export const VISIBILITY_NONE = 'none'

export const AREA_STATISTICA = 'AreaStatistica'
export const QUARTIERE = 'Quartiere'
export const LATITUDE = 'Latitude'
export const LONGITUDE = 'Longitude'
export const COLUMNS_TO_AVOID = ['Timestamp']

// collaborate results [repo: fiu-cloud-function]
export const NOT_VALID_REQUEST = 'Richiesta non valida'
export const NOT_VALID_PAYLOAD = 'Payload non valido'
export const ADDED_LINE = 'Riga aggiunta!'

// languages
export const IT = 'it'
export const EN = 'en'
const FULL_LANGUAGE = (navigator.languages && navigator.languages[0]) || navigator.language || EN
export const LANGUAGE = FULL_LANGUAGE!.split('-')[0]
// error messages
export const MAX_DESCRIPTION_LENGTH = 100
export const ERROR_COORDINATES =
  "Coordinate non valide. Punta il puntatore all'interno dell'area cerchiata."
export const ERROR_DESCRIPTION_LENGTH = `Il testo può contenere massimo ${MAX_DESCRIPTION_LENGTH} caratteri.`

// layers
// used geojson in src/data
export const FEATURES_ID_SOURCE = 'features-source'
export const FEATURES_FILL_ID_LAYER = 'features-fill'
export const FEATURES_STROKE_ID_LAYER = 'features-stroke'

// use dataset in spreadsheets
export const DOTS_ID_SOURCE = 'dots-source'
export const DOTS_ID_LAYER = 'dots'

export const CROWDMAP_BOUNDARY_CIRCLE_ID_SOURCE = 'circle-boundary-source'
export const CROWDMAP_BOUNDARY_CIRCLE_FILL_ID_LAYER = 'circle-boundary-fill-style'
export const CROWDMAP_BOUNDARY_CIRCLE_STROKE_ID_LAYER = 'circle-boundary-stroke-style'

// ---------------------------------------------------------------------------

// styles names: names are from Mapbox Studio style templates (https://studio.mapbox.com/)
export const MAP_STYLE_AREE_URBANE = 'Aree urbane'
export const MAP_STYLE_TRASPORTI = 'Trasporti'
export const MAP_STYLE_STORICA = 'Storica'
export const MAP_STYLE_SATELLITARE = 'Satellitare'
export const MAP_STYLE_SPAZI_PUBBLICI = 'Spazi Pubblici'
export const MAP_STYLE_SERVIZI = 'Servizi'

// layers names
export const BASIC_BIKE_PATH_LAYER = 'basic-bike-paths'
export const BASIC_BUS_STOPS_LAYER = 'basic-tper-fermate-autobus'
export const BASIC_THIRTY_KMH_AREAS_LAYER = 'basic-zone-30kmh'
export const BASIC_VEGETABLE_GARDEN_AREAS_LAYER = 'basic-aree-ortive'
export const SATELLITE_THIRTY_KMH_AREAS_LAYER = 'satellite-zone-30kmh'
export const SATELLITE_VEGETABLE_GARDEN_AREAS_LAYER = 'satellite-aree-ortive'
export const SATELLITE_HOTSPOSTS_LAYER = 'satellite-hotspots'
export const AREE_URBANE_CENTRI_INNOVAZIONE_LAYER = 'Centri Innovazione'
export const AREE_URBANE_MARGINALITA_SOCIALE_LAYER = 'Marginalita Sociale'
export const AREE_URBANE_SPECIALIZZATE_RESIDENZIALI_LAYER = 'Specializzate Residenziali'
export const AREE_URBANE_RISCHIO_INDUSTRIALE_LAYER = 'Rischio Industriale'
export const AREE_URBANE_INCIDENTI_RILEVANTI_LAYER = 'Incidenti Rilevanti'
export const TRASPORTI_AREE_PEDONALI_LAYER = 'Aree pedonali'
export const TRASPORTI_PISTE_CICLOPEDONALI_LAYER = 'Piste ciclopedonali'
export const TRASPORTI_TRAM_LAYER = 'Tram'
export const TRASPORTI_BUS_LAYER = 'Bus'
export const STORICA_PORTICI_LAYER = 'Portici'
export const STORICA_PLESSI_ALMA_MATER_LAYER = 'Plessi Alma Mater'
export const STORICA_EDIFICI_STORICI_LAYER = 'Edifici Storici'
export const SPAZI_UFFICIO_RELAZIONI_PUBBLICHE_LAYER = 'Ufficio Relazioni Pubbliche'
export const SPAZI_PARCHI_E_AREE_VERDI_LAYER = 'Parchi e Aree verdi'
export const SPAZI_PUBBLICI_SPAZI_PUBBLICI_LAYER = 'Spazi Pubblici'
export const SPAZI_PUBBLICI_PIAZZE_LAYER = 'Piazze'
export const SERVIZI_ECCELLENZA_SANITARIA_LAYER = 'Eccellenza Sanitaria'
export const SERVIZI_LUOGHI_DI_CULTURA_LAYER = 'Luoghi di Cultura'
export const SERVIZI_BIBLIOTECHE_LAYER = 'Biblioteche'
export const SERVIZI_SERVIZI_PER_GIOVANI_LAYER = 'Servizi per Giovani'
export const SERVIZI_MERCATI_COMUNALI_LAYER = 'Mercati Comunali'
export const SERVIZI_SCUOLE_LAYER = 'Scuole'
export const SERVIZI_TRASPORTI_LAYER = 'Trasporti'

const FEATURES_FILL_LAYERS = (zIndex: number, showOrder: number): LayersVisibility => ({
  [FEATURES_FILL_ID_LAYER]: {
    visibility: VISIBILITY_VISIBLE,
    name: 'Valore Aree',
    type: 'fill',
    zIndex,
    showOrder,
  },
})
const FEATURES_STROKE_LAYERS = (zIndex: number, showOrder: number): LayersVisibility => ({
  [FEATURES_STROKE_ID_LAYER]: {
    visibility: VISIBILITY_VISIBLE,
    name: 'Bordo Aree',
    type: 'line',
    zIndex,
    showOrder,
  },
})
const DOTS_LAYERS = (zIndex: number, showOrder: number): LayersVisibility => ({
  [DOTS_ID_LAYER]: {
    visibility: VISIBILITY_VISIBLE,
    name: 'Studenti',
    type: 'circle',
    zIndex,
    showOrder,
  },
})

export const MAP_STYLES: MapStyles = {
  [MAP_STYLE_AREE_URBANE]: {
    name: 'Aree urbane',
    url: 'mapbox://styles/fondazioneinnovazioneurbana/ckqlybl9x3pqo17n2vdpb41e5',
    layers: {
      [AREE_URBANE_INCIDENTI_RILEVANTI_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Incidenti rilevanti',
        type: 'fill',
        zIndex: 6,
        showOrder: 8,
      },
      [AREE_URBANE_RISCHIO_INDUSTRIALE_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Rischio industriale',
        type: 'fill',
        zIndex: 5,
        showOrder: 7,
      },
      [AREE_URBANE_SPECIALIZZATE_RESIDENZIALI_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Specializzate residenziali',
        type: 'fill',
        zIndex: 4,
        showOrder: 6,
      },
      [AREE_URBANE_MARGINALITA_SOCIALE_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Marginalità sociale',
        type: 'fill',
        zIndex: 3,
        showOrder: 5,
      },
      [AREE_URBANE_CENTRI_INNOVAZIONE_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Centro di innovazione',
        type: 'fill',
        zIndex: 2,
        showOrder: 4,
      },
      ...FEATURES_STROKE_LAYERS(1, 3),
      ...FEATURES_FILL_LAYERS(0, 2),
      ...DOTS_LAYERS(7, 1),
    },
  },
  [MAP_STYLE_TRASPORTI]: {
    name: 'Trasporti',
    url: 'mapbox://styles/fondazioneinnovazioneurbana/ckqgfavjf007y17msu9m5ngd0',
    layers: {
      ...DOTS_LAYERS(6, 1),
      [TRASPORTI_BUS_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Bus',
        type: 'line',
        zIndex: 5,
        showOrder: 7,
      },
      [TRASPORTI_TRAM_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Tram',
        type: 'line',
        zIndex: 4,
        showOrder: 6,
      },
      [TRASPORTI_PISTE_CICLOPEDONALI_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Piste ciclopedonali',
        type: 'line',
        zIndex: 3,
        showOrder: 5,
      },
      [TRASPORTI_AREE_PEDONALI_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Aree pedonali',
        type: 'fill',
        zIndex: 2,
        showOrder: 4,
      },
      ...FEATURES_STROKE_LAYERS(1, 3),
      ...FEATURES_FILL_LAYERS(0, 2),
    },
  },
  [MAP_STYLE_STORICA]: {
    name: 'Storica',
    url: 'mapbox://styles/fondazioneinnovazioneurbana/ckqlym1n51s7p19lcxdfhdd83',
    layers: {
      ...DOTS_LAYERS(5, 1),
      [STORICA_EDIFICI_STORICI_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Edifici storici',
        type: 'circle',
        zIndex: 4,
        showOrder: 6,
      },
      [STORICA_PLESSI_ALMA_MATER_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Plessi Alma Mater',
        type: 'fill',
        zIndex: 3,
        showOrder: 5,
      },
      [STORICA_PORTICI_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Portici',
        type: 'fill',
        zIndex: 2,
        showOrder: 4,
      },
      ...FEATURES_STROKE_LAYERS(1, 3),
      ...FEATURES_FILL_LAYERS(0, 2),
    },
  },
  [MAP_STYLE_SATELLITARE]: {
    name: 'Satellitare',
    url: 'mapbox://styles/fondazioneinnovazioneurbana/ckqge5qf40k0l17qg9yonw6g5',
    layers: {
      ...DOTS_LAYERS(2, 1),
      ...FEATURES_STROKE_LAYERS(1, 3),
      ...FEATURES_FILL_LAYERS(0, 2),
    },
  },
  [MAP_STYLE_SPAZI_PUBBLICI]: {
    name: 'Spazi pubblici',
    url: 'mapbox://styles/fondazioneinnovazioneurbana/ckqgnk228003e18qfrlk4bqic',
    layers: {
      ...DOTS_LAYERS(6, 1),
      [SPAZI_UFFICIO_RELAZIONI_PUBBLICHE_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Ufficio Relazioni Pubbliche',
        type: 'symbol',
        zIndex: 5,
        showOrder: 7,
      },
      [SPAZI_PARCHI_E_AREE_VERDI_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Parchi e aree verdi',
        type: 'fill',
        zIndex: 4,
        showOrder: 6,
      },
      [SPAZI_PUBBLICI_SPAZI_PUBBLICI_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Spazi pubblici',
        type: 'fill',
        zIndex: 3,
        showOrder: 5,
      },
      [SPAZI_PUBBLICI_PIAZZE_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Piazze',
        type: 'fill',
        zIndex: 2,
        showOrder: 4,
      },
      ...FEATURES_STROKE_LAYERS(1, 3),
      ...FEATURES_FILL_LAYERS(0, 2),
    },
  },
  [MAP_STYLE_SERVIZI]: {
    name: 'Servizi',
    url: 'mapbox://styles/fondazioneinnovazioneurbana/ckqgnrjuo0a7g17rs7vvpmdlu',
    layers: {
      ...DOTS_LAYERS(9, 1),
      [SERVIZI_LUOGHI_DI_CULTURA_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Luoghi di cultura',
        type: 'circle',
        zIndex: 8,
        showOrder: 10,
      },
      [SERVIZI_BIBLIOTECHE_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Biblioteche',
        type: 'circle',
        zIndex: 7,
        showOrder: 9,
      },
      [SERVIZI_SERVIZI_PER_GIOVANI_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Servizi per giovani',
        type: 'circle',
        zIndex: 6,
        showOrder: 8,
      },
      [SERVIZI_MERCATI_COMUNALI_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Mercati comunali',
        type: 'symbol',
        zIndex: 5,
        showOrder: 7,
      },
      [SERVIZI_SCUOLE_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Scuole',
        type: 'symbol',
        zIndex: 4,
        showOrder: 6,
      },
      [SERVIZI_ECCELLENZA_SANITARIA_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Eccellenza sanitaria',
        type: 'fill',
        zIndex: 3,
        showOrder: 5,
      },
      [SERVIZI_TRASPORTI_LAYER]: {
        visibility: VISIBILITY_NONE,
        name: 'Trasporti',
        type: 'line',
        zIndex: 2,
        showOrder: 4,
      },
      ...FEATURES_STROKE_LAYERS(1, 3),
      ...FEATURES_FILL_LAYERS(0, 2),
    },
  },
}
