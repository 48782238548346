import { CircleLayer, FillLayer, LineLayer } from 'mapbox-gl'
import { min, max, memoize, range as lodashRange } from 'lodash-es'
import {
  FEATURES_FILL_ID_LAYER,
  FEATURES_ID_SOURCE,
  FEATURES_STROKE_ID_LAYER,
  DOTS_ID_LAYER,
  DOTS_ID_SOURCE,
  WHITE,
  DOTS_COLOR,
  DOTS_STROKE_COLOR,
  DOTS_STROKE_WIDTH,
  FEATURES_FILL_COLOR,
  FEATURES_STROKE_COLOR,
  CROWDMAP_BOUNDARY_CIRCLE_ID_SOURCE,
  RED,
  CROWDMAP_BOUNDARY_CIRCLE_STROKE_ID_LAYER,
  CROWDMAP_BOUNDARY_CIRCLE_FILL_ID_LAYER,
} from './constants'

function forgetfulCreateFeatureFillLayer(areaMeasureValue: Record<string, number>): FillLayer {
  const areaValues = Object.values(areaMeasureValue) // this is the same of `featuresGeojsonSource.data.features.map(f => f.properties.measureValue)`
  const minAreaValue = min(areaValues)
  const maxAreaValue = max(areaValues) || 0

  // to avoid interpolate not ascending order error, check if minValue and maxValue are equals
  // in that case do not interpolate but paint the region with the first color
  // -----
  const gradient = [
    'interpolate',
    ['linear'],
    ['get', 'measureValue'],
    minAreaValue,
    ['to-color', WHITE(0.7)],
    maxAreaValue,
    ['to-color', FEATURES_FILL_COLOR(0.7)],
  ]
  const opacity = ['interpolate', ['linear'], ['zoom'], 15, 1, 21, 0]
  const featuresStyle = {
    type: 'fill',
    paint: { 'fill-color': gradient, 'fill-opacity': opacity },
  }

  const featuresLayer = {
    id: FEATURES_FILL_ID_LAYER,
    source: FEATURES_ID_SOURCE,
    ...featuresStyle,
  } as FillLayer
  return featuresLayer
}
export const createFeatureFillLayer = memoize(forgetfulCreateFeatureFillLayer)

export function createFeatureStrokeLayer(): LineLayer {
  const featuresStyle = {
    type: 'line',
    paint: {
      'line-color': FEATURES_STROKE_COLOR,
      'line-width': [
        'case', // if feature-state-hover || feature-state-clicked => border stroke = 2, otherwise border stroke = 0.5
        [
          'any',
          ['boolean', ['feature-state', 'click'], false], // assert that feature-state clicked value is a boolean, otherwise consider it as false
          ['boolean', ['feature-state', 'hover'], false], // assert that feature-state hover value is a boolean, otherwise consider it as false
        ],
        2,
        0.5,
      ],
    },
  }

  const featuresLayer = {
    id: FEATURES_STROKE_ID_LAYER,
    source: FEATURES_ID_SOURCE,
    ...featuresStyle,
  } as LineLayer
  return featuresLayer
}

export function createPointsLayer(): CircleLayer {
  const pointsStyle = {
    type: 'circle',
    paint: {
      'circle-radius': ['interpolate', ['linear'], ['zoom'], 15, 2, 20, 12],
      'circle-color': DOTS_COLOR,
      'circle-stroke-width': DOTS_STROKE_WIDTH,
      'circle-stroke-color': DOTS_STROKE_COLOR,
    },
  }

  const pointsLayer = {
    id: DOTS_ID_LAYER,
    source: DOTS_ID_SOURCE,
    ...pointsStyle,
  } as CircleLayer
  return pointsLayer
}

export function createCrowdmapPointsLayer(): CircleLayer {
  const pointsStyle = {
    type: 'circle',
    paint: {
      'circle-radius': ['interpolate', ['linear'], ['zoom'], 15, 4, 20, 12],
      'circle-color': DOTS_COLOR,
      'circle-stroke-width': DOTS_STROKE_WIDTH,
      'circle-stroke-color': DOTS_STROKE_COLOR,
    },
  }

  const pointsLayer = {
    id: DOTS_ID_LAYER,
    source: DOTS_ID_SOURCE,
    ...pointsStyle,
  } as CircleLayer
  return pointsLayer
}

export function createCrowdmapCircleBoundaryFillLayer(): FillLayer {
  const fillStyle = {
    type: 'fill',
    paint: {
      'fill-color': '#FFFFFF',
      'fill-opacity': 0.35,
    },
  }

  const fillLayer = {
    id: CROWDMAP_BOUNDARY_CIRCLE_FILL_ID_LAYER,
    source: CROWDMAP_BOUNDARY_CIRCLE_ID_SOURCE,
    ...fillStyle,
  } as FillLayer
  return fillLayer
}

export function createCrowdmapCircleBoundaryStrokeLayer(): LineLayer {
  const lineStyle = {
    type: 'line',
    paint: {
      'line-color': RED(0.5),
      'line-width': 0.5,
    },
  }

  const lineLayer = {
    id: CROWDMAP_BOUNDARY_CIRCLE_STROKE_ID_LAYER,
    source: CROWDMAP_BOUNDARY_CIRCLE_ID_SOURCE,
    ...lineStyle,
  } as LineLayer
  return lineLayer
}
