import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import useSize from '@react-hook/size'
import { truncate, noop } from 'lodash-es'
import { useMst } from '../state'
import { useLocalization } from '../lib/hooks/useLocalization'
import { DatasetsSpreadsheetRow } from '../types'
import { MapCover } from './MapCover'
import { HomepageTabs } from './HomepageTabs'
// @ts-ignore
import { ReactComponent as CloseIcon } from '../assets/close.svg'
// @ts-ignore
import { ReactComponent as IIcon } from '../assets/info.svg'
// @ts-ignore
import { ReactComponent as LayersIcon } from '../assets/layers-card.svg'
// @ts-ignore
import { ReactComponent as CrowdIcon } from '../assets/crowd.svg'
// @ts-ignore
import { ReactComponent as PlayIcon } from '../assets/play.svg'

export const Homepage: React.FC<{}> = observer(({}) => {
  const {
    ui: { isMobile },
  } = useMst()

  return isMobile ? <HomepageMobile /> : <HomepageDesktop />
})
interface HomepageDesktopProps {
  className?: string
}
export const HomepageDesktop: React.FC<HomepageDesktopProps> = observer(({ className = '' }) => {
  const {
    data: { datasetRowsToShow },
    ui: { isHomepageModalDatasetOpen, showDatasetSelector, isSelectedHomepageTabCrowdmap },
  } = useMst()

  const intro_guided_1 = useLocalization('intro_guided_1')
  const intro_guided_2 = useLocalization('intro_guided_2')
  const intro_crowd_1 = useLocalization('intro_crowd_1')
  const intro_crowd_2 = useLocalization('intro_crowd_2')

  return (
    <div className={`h-full flex ${className}`}>
      {showDatasetSelector && (
        <div className="overflow-y-scroll" style={{ minWidth: 360 }}>
          <HomepageTabs />
          <div className="p-5 max-w-sm bg-super-grey text-white">
            {isSelectedHomepageTabCrowdmap ? (
              <div>
                <div>{intro_crowd_1}</div>
                <div>{intro_crowd_2}</div>
              </div>
            ) : (
              <div>
                <div>{intro_guided_1}</div>
                <div>{intro_guided_2}</div>
              </div>
            )}
          </div>
          {datasetRowsToShow.map((datasetInfo, i) => (
            <Card key={i} datasetInfo={datasetInfo} />
          ))}
        </div>
      )}
      <div className="relative flex flex-grow justify-center items-center">
        {isHomepageModalDatasetOpen && <Modal />}
        <CoverImage />
      </div>
    </div>
  )
})

interface HomepageMobileProps {
  className?: string
}
export const HomepageMobile: React.FC<HomepageMobileProps> = observer(({ className = '' }) => {
  const {
    data: { datasetRowsToShow },
    ui: {
      isHomepageModalDatasetOpen,
      showDatasetSelector,
      isSelectedHomepageTabCrowdmap,
      setIsSelectedHomepageTabCrowdmap,
      setIsVideoPageOpen,
    },
  } = useMst()

  const guidedAnalysisLabel = useLocalization('guided_analysis')
  const crowdmappingLabel = useLocalization('crowdmapping')
  const introductionLabel = useLocalization('introduction')
  const intro_guided_1 = useLocalization('intro_guided_1')
  const intro_guided_2 = useLocalization('intro_guided_2')
  const intro_crowd_1 = useLocalization('intro_crowd_1')
  const intro_crowd_2 = useLocalization('intro_crowd_2')

  return (
    <div className={`h-full relative ${className}`}>
      {isHomepageModalDatasetOpen && <Modal />}
      <CoverImage />

      <div className="w-full fixed bottom-0 left-0 bg-opacity-0 flex flex-nowrap overflow-x-auto pl-4 py-4">
        {!isHomepageModalDatasetOpen && showDatasetSelector ? (
          <div className="flex">
            <div className="flex">
              {/* first card */}
              <div className="flex flex-col justify-between" style={{ width: 200 }}>
                <MobileButton
                  text={guidedAnalysisLabel}
                  isSelected={!isSelectedHomepageTabCrowdmap}
                  onClick={() => setIsSelectedHomepageTabCrowdmap(false)}
                >
                  <LayersIcon className="w-3 mx-2" />
                </MobileButton>

                <MobileButton
                  text={crowdmappingLabel}
                  isSelected={isSelectedHomepageTabCrowdmap}
                  onClick={() => setIsSelectedHomepageTabCrowdmap(true)}
                >
                  <CrowdIcon className="w-3 mx-2" />
                </MobileButton>

                <MobileButton text={introductionLabel} onClick={() => setIsVideoPageOpen(true)}>
                  <PlayIcon className="w-3 mx-2" />
                </MobileButton>
              </div>

              <div className="items-center text-white justify-center bg-super-grey px-6 rounded-md flex flex-col ml-2 text-sm">
                {isSelectedHomepageTabCrowdmap ? (
                  <div style={{ width: '265px', lineHeight: 1.15 }}>
                    <div>{intro_crowd_1}</div>
                    <div>{intro_crowd_2}</div>
                  </div>
                ) : (
                  <div style={{ width: '295px', lineHeight: 1.15 }}>
                    <div>{intro_guided_1}</div>
                    <div>{intro_guided_2}</div>
                  </div>
                )}
              </div>

              {datasetRowsToShow.map((datasetInfo, i) => (
                <Card className="rounded-md" key={i} datasetInfo={datasetInfo} />
              ))}
            </div>
            {/* do not remove, it necessary to have the right space after the last card */}
            <div className="pr-4" />
          </div>
        ) : null}
      </div>
    </div>
  )
})

interface MobileButtonProps {
  className?: string
  text: string
  children: React.ReactNode
  onClick?: () => void
  isSelected?: boolean
  disabled?: boolean
}
const MobileButton: React.FC<MobileButtonProps> = ({
  className = '',
  text,
  children,
  isSelected = false,
  disabled = false,
  onClick = noop,
}) => {
  return (
    <div
      className={`p-3 flex justify-center align-middle rounded ${
        disabled ? '' : 'cursor-pointer'
      } ${isSelected ? 'bg-red text-white' : 'bg-light-grey text-dark-grey'} ${className}`}
      onClick={disabled ? noop : onClick}
      style={{ height: 44 }}
    >
      {children}
      <div className="uppercase text-sm">{text}</div>
    </div>
  )
}

interface CardContentProps {
  className?: string
  datasetInfo: DatasetsSpreadsheetRow
  bgColor: string
  textColor: string
  iconColor: string
  titleColor: string
  [x: string]: any
}
const CardContent: React.FC<CardContentProps> = observer(
  ({ className = '', datasetInfo, bgColor, iconColor, titleColor, textColor, ...rest }) => {
    const {
      ui: { isMobile },
    } = useMst()

    return (
      <div
        className={`p-4 flex flex-col justify-between cursor-pointer bg-${bgColor} ${className}`}
        {...rest}
      >
        <div className="flex flex-col h-full max-h-32">
          <div className="flex justify-between items-center">
            <div
              className={`items-center ${
                isMobile ? 'text-xs' : 'text-sm'
              } uppercase text-${titleColor} flex flex-grow`}
            >
              {datasetInfo.is_crowdmap === 'TRUE' ? (
                <CrowdIcon className={`w-3 mr-1 pb-1.5 text-${iconColor}`} />
              ) : (
                <LayersIcon className={`w-3 mr-1 pb-1 text-${iconColor}`} />
              )}

              {truncate(datasetInfo.title, { length: 40 })}
            </div>
            <InfoIcon datasetInfo={datasetInfo} />
          </div>

          <div
            className={`pt-2 flex-grow overflow-hidden  ${
              isMobile ? 'text-xl leading-6' : 'text-2xl leading-7'
            } text-${textColor} ${isMobile ? '' : ''}`}
          >
            {truncate(datasetInfo.subtitle, { length: 65 })}
          </div>
        </div>

        <div className={`underline text-red`}>{useLocalization('explore_map')}</div>
      </div>
    )
  }
)

interface CardProps {
  className?: string
  datasetInfo: DatasetsSpreadsheetRow
}
const Card: React.FC<CardProps> = observer(({ className = '', datasetInfo }) => {
  const {
    ui: { isMobile, setInfoDataset, setIsInHomepage },
    data: { exploreThat },
  } = useMst()
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className={`max-w-sm ${className}`}
      onPointerOver={() => setIsHovered(true)}
      onPointerLeave={() => setIsHovered(false)}
      onClick={async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        setInfoDataset(null)
        await exploreThat(datasetInfo)
        setIsInHomepage(false)
      }}
    >
      {isMobile ? (
        <div
          className="flex justify-center items-center flex-none mx-2"
          style={{ borderRadius: 10 }}
        >
          <CardContent
            className={`${className}`}
            datasetInfo={datasetInfo}
            textColor="black"
            bgColor="white"
            iconColor="black"
            titleColor="black"
            style={{ width: 312, height: 140 }}
          />
        </div>
      ) : (
        <div className="h-44 flex border-b border-lighterest-grey">
          <div
            className={`${
              isHovered
                ? datasetInfo.is_crowdmap === 'TRUE'
                  ? 'bg-red'
                  : 'bg-dark-blue'
                : 'bg-transparent'
            } h-full w-2.5`}
          />
          <div className="flex w-full">
            <div className="flex flex-grow">
              <CardContent
                className="w-full"
                datasetInfo={datasetInfo}
                textColor={isHovered ? 'black' : 'super-grey'}
                iconColor={`${
                  isHovered ? (datasetInfo.is_crowdmap === 'TRUE' ? 'red' : 'dark-blue') : 'black'
                }`}
                bgColor={isHovered ? 'lighterest-grey' : 'white'}
                titleColor={`${
                  isHovered ? (datasetInfo.is_crowdmap === 'TRUE' ? 'red' : 'dark-blue') : 'black'
                }`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
})

interface ModalProps {
  className?: string
}
const Modal: React.FC<ModalProps> = observer(({ className = '' }) => {
  const {
    data: { exploreThat },
    ui: { infoDataset, setInfoDataset, isMobile, setIsInHomepage },
  } = useMst()

  const target = useRef(null)
  const [w, h] = useSize(target)

  return (
    <div
      className={`absolute bg-white rounded-lg flex flex-col justify-between z-2 ${
        isMobile ? 'left-0 top-0 bottom-0 right-0 ml-auto mt-auto mb-auto mr-auto' : ''
      } ${className}`}
      style={{ width: '90%', height: '90%' }}
    >
      <div className="h-full flex flex-col px-9 pt-7 pb-3">
        <div className="flex justify-between mb-6">
          <div className="font-semibold text-black text-2xl">{infoDataset?.title}</div>
          <CloseIcon
            className="cursor-pointer w-3 hover:text-red"
            onClick={() => setInfoDataset(null)}
          />
        </div>
        <div className="flex-grow" ref={target}>
          <div
            className="overflow-y-auto"
            style={{ height: h }}
            dangerouslySetInnerHTML={{ __html: infoDataset!.description }}
          />
        </div>
      </div>

      <div className="h-16 flex text-md cursor-pointer border-t border-lightest-grey">
        <div
          className="w-full flex justify-center items-center underline text-red hover:bg-lighterest-grey hover:font-semibold"
          onClick={async () => {
            await exploreThat(infoDataset as DatasetsSpreadsheetRow)
            setIsInHomepage(false)
          }}
        >
          {useLocalization('explore_map')}
        </div>
      </div>
    </div>
  )
})

interface InfoIconProps {
  className?: string
  datasetInfo: DatasetsSpreadsheetRow
}
const InfoIcon: React.FC<InfoIconProps> = observer(({ className = '', datasetInfo }) => {
  const {
    ui: { setInfoDataset, isMobile },
  } = useMst()

  const [hovered, setHovered] = useState<boolean>(false)
  const color = isMobile || hovered ? 'black' : 'super-grey'

  return (
    <div
      className={`w-6 min-w-6 h-6 cursor-pointer flex justify-center items-center rounded-full border hover:border-2 hover:p-0 border-${color} text-${color} font-bold ml-2 ${className}`}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        setInfoDataset(datasetInfo)
      }}
    >
      <IIcon className="w-full h-full" style={{ padding: 5 }} />
    </div>
  )
})

const CoverImage: React.FC<{}> = ({}) => {
  return (
    <div className="w-full h-full">
      <MapCover />
      <div className="absolute top-0 left-0 right-0">
        <Texts />
      </div>
    </div>
  )
}

const Texts: React.FC = observer(({}) => {
  const {
    ui: { isMobile, isHomepageModalDatasetOpen },
  } = useMst()

  const titleLabel = useLocalization('atlante_urbano')
  const exploreLabel = useLocalization('explore_the_areas_of_the_city')

  return (
    <div
      className={`w-50 h-full relative flex mx-3 ${
        isMobile ? 'justify-center pt-12' : 'items-center pl-3'
      }`}
    >
      {!isHomepageModalDatasetOpen && (
        <div
          className={`flex flex-col min-h-screen ${
            isMobile ? 'items-center text-center' : 'items-baseline justify-center'
          }`}
        >
          <div
            className={`bg-white mb-1 pt-3 pb-1 font-semibold px-3 text-red ${
              isMobile ? 'text-2xl' : 'text-4xl'
            }`}
          >
            {titleLabel}
          </div>

          <div
            className={`bg-white mb-4 pt-3 pb-2 px-3 w-70 text-red ${
              isMobile ? 'text-lg' : 'text-15xl'
            }`}
          >
            {`${exploreLabel}.`}
          </div>
        </div>
      )}
    </div>
  )
})
