import React from 'react'
import ReactDOM from 'react-dom'
import { observer } from 'mobx-react-lite'
import { IntlProvider } from 'react-intl'
import { stateInstance, Provider, useMst } from './state'
import { App } from './App'
import 'modern-normalize'
import './main.css'
// @ts-ignore
import messagesIt from './locales/messages-it.json5'
// @ts-ignore
import messagesEn from './locales/messages-en.json5'

const AppContainer: React.FC<{}> = observer(({}) => {
  const {
    ui: { language },
  } = useMst()
  return (
    <IntlProvider locale={language} messages={language === 'en' ? messagesEn : messagesIt}>
      <App />
    </IntlProvider>
  )
})

function renderApp() {
  ReactDOM.render(
    <Provider value={stateInstance}>
      <AppContainer />
    </Provider>,
    document.getElementById('root')
  )
}

// First render
renderApp()

// Hot module reloading
if (module.hot) {
  module.hot.accept('./App', () => {
    console.clear()
    renderApp()
  })
}
