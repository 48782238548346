import React from 'react'
import { observer } from 'mobx-react-lite'
import { LngLat } from 'mapbox-gl'
import { roundToFixedDecimals } from '../lib/format-utils'
import { useMst } from '../state'
import { isPointInCircleBoundary } from '../lib/map-utils'

const PRECISION = 2

interface Props {
  className?: string
  centerCoordinates: LngLat
}

export const Marker: React.FC<Props> = observer(({ className = '', centerCoordinates }) => {
  const {
    ui: { collaborateMarkerPercentage, isMobile },
  } = useMst()
  const size = isMobile ? 16 : 9
  const { lng, lat } = centerCoordinates
  const roundedLng = roundToFixedDecimals(lng, PRECISION)
  const roundedLat = roundToFixedDecimals(lat, PRECISION)

  const xPercentage = `${collaborateMarkerPercentage.x * 100}%`
  const yPercentage = `${collaborateMarkerPercentage.y * 100}%`

  const isInsideCircle = isPointInCircleBoundary([lng, lat])

  return (
    <div className={`${className}`}>
      <svg
        className="absolute z-3"
        width={size}
        height={size}
        x={0}
        y={0}
        style={{
          top: `calc(${yPercentage} - ${size / 2}px)`,
          left: `calc(${xPercentage} - ${size / 2}px)`,
        }}
      >
        <path
          transform={`${isInsideCircle ? '' : `rotate(45, ${size / 2}, ${size / 2})`}`}
          d={dCrossPath(size, 0.2 * size)}
          className={`${isInsideCircle ? 'fill-red' : 'fill-black'}`}
        />
      </svg>
      <div
        className={`absolute z-3 w-full ${
          isInsideCircle ? 'text-red' : 'text-black'
        } text-sm flex justify-center pointer-events-none`}
        style={{
          top: `calc(${yPercentage} - ${size}px + ${isMobile ? 25 : 15}px)`,
        }}
      >{`${isInsideCircle ? `${roundedLng}, ${roundedLat}` : `Fuori dall'area`}`}</div>
    </div>
  )
})

const dCrossPath = (size: number, weight: number) => {
  const b = (size - weight) / 2
  return `M ${0} ${0}
          L ${b} ${0}
          L ${b + weight} ${0}
          L ${b + weight} ${b}
          L ${size} ${b}
          L ${size} ${b + weight}
          L ${b + weight} ${b + weight}
          L ${b + weight} ${size}
          L ${b} ${size}
          L ${b} ${b + weight}
          L ${0} ${b + weight}
          L ${0} ${b}
          L ${b} ${b}
          L ${b} ${0}`
}
