import firebase from 'firebase'

const VALUE_KEY = 'value'

const firebaseConfig = {
  apiKey: 'AIzaSyB-X2vtJ08NAt7zQhH8LCRWeAszAYKjQk8',
  authDomain: 'atlante-91a98.firebaseapp.com',
  databaseURL: 'https://atlante-91a98-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'atlante-91a98',
  storageBucket: 'atlante-91a98.appspot.com',
  messagingSenderId: '536051913535',
  appId: '1:536051913535:web:5078d3363f52ac34cc798a',
  measurementId: 'G-Z5M088KJRJ',
}

const fbDb = firebase.initializeApp(firebaseConfig)

export const createSyncState = (dbName: string) => {
  const ref = fbDb.database().ref(dbName)

  const syncState = {
    save: (hashKey: string, stringifiedState: string) => {
      ref.child(hashKey).child(VALUE_KEY).set(stringifiedState)
    },

    get: async (hashKey: string) => {
      try {
        const snapshot = await ref.child(hashKey).get()
        if (snapshot.exists()) {
          const value = snapshot.val()[VALUE_KEY]
          return value
        } else {
          console.log('No data available')
        }
      } catch (error) {
        console.error(error)
      }
    },
  }
  return syncState
}
