import { reaction, when } from 'mobx'
import crypto from 'crypto'
import { StateInstance } from '../state'
import { createSyncState } from './firebase'

const STATE_KEY = 'state'

export function storeInQuerystring<T>(values: T): void {
  const stringified = JSON.stringify(values)

  // create an hash string from a strongified mobx state
  const hashed = generateHash(stringified)

  // put the hash in the query string
  const queryParams = new URLSearchParams(window.location.search)
  queryParams.set(STATE_KEY, hashed)
  const newPath = `${window.location.pathname}?${queryParams.toString()}`
  history.replaceState(null, document.title, newPath)

  // save in firebase { STATE_KEY: { hash: stringifiedState, hash: stringifiedState, ... } }
  createSyncState(STATE_KEY).save(hashed, stringified)
}

export async function readFromQuerystring(search: string) {
  const queryParams = new URLSearchParams(search)
  const hashState = queryParams.get(STATE_KEY)
  if (!hashState) return
  const stringifiedState = await createSyncState(STATE_KEY).get(hashState)
  return stringifiedState
}

export function syncUrlState(state: StateInstance) {
  when(
    () => {
      return state.ui.isAdmin && !state.ui.isInHomepage
    },
    () => {
      reaction(
        () => state.serializableState, // partial state
        (values) => storeInQuerystring(values)
      )
    }
  )
}

function generateHash(value: string, hashKey = 'fried shrimp') {
  return crypto.createHmac('sha256', hashKey).update(value).digest('hex')
}
