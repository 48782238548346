import { observer } from 'mobx-react-lite'
import React from 'react'
import { useMst } from '../state'
// @ts-ignore
import imgHomepage0 from '../assets/images-tutorial/homepage.png'
// @ts-ignore
import imgCards from '../assets/images-tutorial/cards.png'
// @ts-ignore
import imgStories from '../assets/images-tutorial/stories.png'
// @ts-ignore
import imgLivelliGeo from '../assets/images-tutorial/livelligeo.png'
// @ts-ignore
import imgClassifica from '../assets/images-tutorial/classifica.png'
// @ts-ignore
import imgIndicatori0 from '../assets/images-tutorial/indicatori-0.png'
// @ts-ignore
import imgIndicatori1 from '../assets/images-tutorial/indicatori-1.png'
// @ts-ignore
import imgFiltri0 from '../assets/images-tutorial/filtri-0.png'
// @ts-ignore
import imgFiltri1 from '../assets/images-tutorial/filtri-1.png'
// @ts-ignore
import imgCrowdmap from '../assets/images-tutorial/crowdmap.png'
// @ts-ignore
import imgAltro from '../assets/images-tutorial/altro.png'
import { useLocalization } from '../lib/hooks/useLocalization'
import { EN, IT } from '../lib/constants'
import { Button } from './Header'

export const TutorialPage: React.FC = observer(() => {
  const {
    ui: {
      isMobile,
      setIsAboutPageOpen,
      setIsInHomepage,
      setIsMenuPageOpen,
      setIsTutorialPageOpen,
      setIsVideoPageOpen,
      language,
      setLanguage,
    },
  } = useMst()

  return (
    <div className="flex flex-col justify-between w-full h-full absolute px-6 py-4 top-0 left-0 z-5 bg-white">
      <div className="top-0 sticky z-1">
        <div className="flex">
          <Button
            className={`pr-2 hover:text-red ${language === IT ? 'font-bold underline' : ''}`}
            textToShow={IT}
            onClick={() => setLanguage(IT)}
          />
          <Button
            className={`hover:text-red ${language === EN ? 'font-bold underline' : ''}`}
            textToShow={EN}
            onClick={() => setLanguage(EN)}
          />
        </div>

        <div className="flex flex-col items-center">
          <div className="flex flex-row">
            <div
              className="px-3 pb-2 pt-3 mx-2 text-black text-md uppercase cursor-pointer border border-white hover:border-red"
              onClick={() => {
                setIsInHomepage(true)
                setIsAboutPageOpen(false)
                setIsTutorialPageOpen(false)
                setIsMenuPageOpen(false)
              }}
            >
              {useLocalization('homepage')}
            </div>

            <div
              className="px-3 pb-2 pt-3 mx-2 text-black text-md uppercase cursor-pointer border border-white hover:border-red"
              onClick={() => setIsVideoPageOpen(true)}
            >
              {useLocalization('video')}
            </div>

            <div
              className="underline px-3 pb-2 pt-3 mx-2 text-black text-md uppercase cursor-pointer border border-white hover:border-red"
              onClick={() => setIsTutorialPageOpen(true)}
            >
              {useLocalization('tutorial')}
            </div>

            <div
              className="px-3 pb-2 pt-3 mx-2 text-black uppercase text-md cursor-pointer border border-white hover:border-red"
              onClick={() => {
                setIsAboutPageOpen(true)
                setIsTutorialPageOpen(false)
              }}
            >
              {useLocalization('about')}
            </div>

            <div
              className="px-3 pb-2 pt-3 mx-2 text-black text-md uppercase cursor-pointer border border-white hover:border-red"
              onClick={() => {
                setIsInHomepage(true)
                setIsAboutPageOpen(false)
                setIsTutorialPageOpen(false)
                setIsMenuPageOpen(false)
              }}
            >
              {useLocalization('back')}
            </div>
          </div>

          <div
            className="text-xs underline px-3 pb-2 pt-3 mx-2 text-black text-md cursor-pointer border border-white hover:border-red"
            onClick={() => {
              setIsAboutPageOpen(false)
              setIsTutorialPageOpen(false)
              setIsMenuPageOpen(false)
              setIsInHomepage(true)
            }}
          >
            {useLocalization('close')}
          </div>

          <div className="border-b border-gray-300 my-3 w-full"></div>
        </div>
      </div>
      <div className="flex flex-col items-center pt-10 overflow-y-auto">
        <div className={`${isMobile ? 'w-full' : 'w-3/5'} text-left`}>
          <div className="text-lg items-left text-left px-6">
            {/* progetto */}
            <div className="text-2xl mb-5 mt-4 text-super-grey">
              {useLocalization('how_it_works')}
            </div>
            {useLocalization('better_understanding')}
            <li>
              <a href="#homepage" className="underline capitalize">
                {useLocalization('homepage')}
              </a>
            </li>
            <li>
              <a href="#storie" className="underline capitalize">
                {useLocalization('stories')}
              </a>
            </li>
            <li>
              <a href="#layers" className="underline capitalize">
                {useLocalization('geographic_layers')}
              </a>
            </li>
            <li>
              <a href="#indicatori" className="underline capitalize">
                {useLocalization('indicators')}
              </a>
            </li>
            <li>
              <a href="#filtri" className="underline capitalize">
                {useLocalization('filters')}
              </a>
            </li>
            <li>
              <a href="#classifica" className="underline capitalize">
                {useLocalization('ranking')}
              </a>
            </li>
            <li>
              <a href="#crowdmap" className="underline capitalize">
                {useLocalization('crowdmap')}
              </a>
            </li>
            <li>
              <a href="#altro" className="underline capitalize">
                {useLocalization('altro')}
              </a>
            </li>
            {/* divider */}
            <div className="w-100 my-6 border"></div>
            {/* divider */}
            {/* HOMEPAGE */}
            <div className="mb-3" id="homepage">
              <div className="text-super-grey mb-2 capitalize">{`${useLocalization(
                'homepage'
              )}: `}</div>
              <div className="text-base mb-4">
                {useLocalization('tutorial_homepage_2')}
                <img src={imgHomepage0} className="my-4" />
                {useLocalization('tutorial_homepage_3')}
                <img src={imgCards} className="my-4" />
              </div>
            </div>
            {/* divider */}
            <div className="w-100 my-6 border"></div>
            {/* divider */}
            {/* storie */}
            <div className="mb-3" id="storie">
              <div className="text-super-grey mb-2">{`${useLocalization('stories')}:`}</div>
              <div className="text-base mb-4">{useLocalization('tutorial_storie_2')}</div>
            </div>
            {/* divider */}
            <div className="w-100 my-6 border"></div>
            {/* divider */}
            {/* livelligeo */}
            <div className="mb-3" id="layers">
              <div className="text-super-grey mb-2">{`${useLocalization(
                'geographic_layers'
              )}:`}</div>
              <div className="text-base mb-4">
                {useLocalization('tutorial_layer_2')}
                <img src={imgLivelliGeo} className="my-4" />
              </div>
            </div>
            {/* divider */}
            <div className="w-100 my-6 border"></div>
            {/* divider */}
            {/* indicatori */}
            <div className="mb-3" id="indicatori">
              <div className="text-super-grey mb-2">{`${useLocalization('indicators')}:`}</div>
              <div className="text-base mb-4">
                {useLocalization('tutorial_indicatori_2')}
                <img src={imgIndicatori0} className="my-4" />
                {useLocalization('tutorial_indicatori_3')}
                <img src={imgIndicatori1} className="my-4" />
              </div>
            </div>
            {/* divider */}
            <div className="w-100 my-6 border"></div>
            {/* divider */}
            {/* filtri */}
            <div className="mb-3" id="filtri">
              <div className="text-super-grey mb-2">{`${useLocalization('filters')}:`}</div>
              <div className="text-base mb-4">
                {useLocalization('tutorial_filtri_2')}
                <img src={imgFiltri0} className="my-4" />
                {useLocalization('tutorial_filtri_3')}
                <img src={imgFiltri1} className="my-4" />
              </div>
            </div>
            {/* divider */}
            <div className="w-100 my-6 border"></div>
            {/* divider */}
            {/* classifica */}
            <div className="mb-3" id="classifica">
              <div className="text-super-grey mb-2">{useLocalization('ranking')}: </div>
              <div className="text-base mb-4">
                {useLocalization('tutorial_classifica_2')}
                <img src={imgClassifica} className="my-4" />
              </div>
            </div>
            {/* divider */}
            <div className="w-100 my-6 border"></div>
            {/* divider */}
            {/* crowdmap */}
            <div className="mb-3" id="crowdmap">
              <div className="text-super-grey mb-2">{`${useLocalization('crowdmap')}:`}</div>
              <div className="text-base mb-4">
                {useLocalization('tutorial_crowdmap_2')}
                <p className="underline mt-4">{useLocalization('tutorial_crowdmap_3')}</p>
                <img src={imgCrowdmap} className="my-4" />
              </div>
            </div>
            {/* divider */}
            <div className="w-100 my-6 border"></div>
            {/* divider */}
            {/* altro */}
            <div className="mb-3" id="altro">
              <div className="text-super-grey mb-2">{`${useLocalization('altro')}:`}</div>
              <div className="text-base mb-4">
                {useLocalization('tutorial_altro_2')}
                <img src={imgAltro} className="my-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

interface LogoProps {
  className?: string
}
const Logo: React.FC<LogoProps> = ({ className = '', children }) => {
  return (
    <div
      className={`bg-white p-2 rounded-md border border-light-gray w-28 h-28 flex justify-center items-center ${className}`}
    >
      {children}
    </div>
  )
}
