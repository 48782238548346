import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../state'
import { useLocalization } from '../lib/hooks/useLocalization'

const GUIDED_ANALYSIS = 'guided analysis'
const CROWDMAPPING = 'crowdmapping'

interface HomepageTabsProps {
  className?: string
}

export const HomepageTabs: React.FC<HomepageTabsProps> = observer(({ className = '' }) => {
  const {
    ui: { isSelectedHomepageTabCrowdmap, setIsSelectedHomepageTabCrowdmap },
  } = useMst()

  const [hoveredTab, setHoveredTab] = useState(
    isSelectedHomepageTabCrowdmap ? CROWDMAPPING : GUIDED_ANALYSIS
  )

  const tabsData = [
    { titleKey: CROWDMAPPING, name: useLocalization('crowdmap') },
    { titleKey: GUIDED_ANALYSIS, name: useLocalization('guided_story') },
  ]

  return (
    <div className={`flex pt-3 ${className}`}>
      {tabsData.map(({ titleKey, name }, i) => {
        const isCrowdmapSelected = titleKey === CROWDMAPPING
        const isCurrentTab =
          (isSelectedHomepageTabCrowdmap && titleKey === CROWDMAPPING) ||
          (!isSelectedHomepageTabCrowdmap && titleKey === GUIDED_ANALYSIS)
        const isTabHovered = titleKey === hoveredTab

        return (
          <div
            key={i}
            className={`flex-1 flex justify-center items-center cursor-pointer uppercase text-sm pb-2 border-b-2 hover:border-black hover:text-black hover:font-semibold ${
              isCurrentTab
                ? 'font-semibold text-black border-black'
                : 'font-light text-dark-grey border-light-grey'
            }`}
            style={{ letterSpacing: 1.5 }}
            onClick={() => setIsSelectedHomepageTabCrowdmap(isCrowdmapSelected)}
            onPointerEnter={() => setHoveredTab(titleKey)}
            onPointerLeave={() => setHoveredTab(titleKey)}
          >
            <div>{name}</div>
          </div>
        )
      })}
    </div>
  )
})
