import React, { useState } from 'react'
import { clamp } from 'lodash-es'
import { ScaleBand, scaleLinear } from 'd3-scale'
import { Vector } from '../types'
import { DraggableCircle } from './DraggableCircle'
import { DraggableRect } from './DraggableRect'

const UNDERGROUND_BAR_HEIGHT = 2
const DRAGGABLE_BAR_HEIGHT = 2

interface Props {
  className?: string
  width: number
  outerDomain: Vector
  innerDomainStart: Vector
  updateDomain: (range: Vector) => void
  x?: number
  y?: number
  radius?: number
  draggingRadius?: number
  barsScale: ScaleBand<string>
}

export const RangeSlider: React.FC<Props> = ({
  className = '',
  width,
  outerDomain,
  innerDomainStart,
  updateDomain,
  x = 0,
  y = 0,
  radius = 10,
  draggingRadius = 15,
  barsScale,
}) => {
  const MARGINS = {
    top: draggingRadius,
    right: 0,
    bottom: draggingRadius,
    left: 0,
  }
  const xS = x + MARGINS.left
  const yS = y + MARGINS.top

  const xScale = scaleLinear<number, number>().domain(outerDomain).range(barsScale.range())

  const [minValue, maxValue] = xScale.domain()
  const [minX, maxX] = xScale.range()
  const [minOverrideValue, maxOverrideValue] = innerDomainStart
  const [minOverrideX, maxOverrideX] = innerDomainStart.map(xScale)
  const filteredRangeLenght = maxOverrideValue - minOverrideValue

  const handleAreaDrag = ({ deltaX }: { deltaX: number }) => {
    const [minOverrideX, maxOverrideX] = innerDomainStart.map(xScale)
    const newMinValue = xScale.invert(minOverrideX + deltaX)
    const newMaxValue = xScale.invert(maxOverrideX + deltaX)
    const newMinOverrideValue = clamp(newMinValue, minValue, maxValue - filteredRangeLenght)
    const newMaxOverrideValue = clamp(newMaxValue, minValue + filteredRangeLenght, maxValue)
    const news = [newMinOverrideValue, newMaxOverrideValue] as Vector
    !isDragging && updateDomain(news)
  }

  const handleLowerDrag = ({ deltaX }: { deltaX: number }) => {
    const newLowerDomain = xScale.invert(minOverrideX + deltaX)
    const minDomain = minValue
    const maxDomain = maxOverrideValue
    const clampedNewLowerDomain = clamp(newLowerDomain, minDomain, maxDomain)
    !isDragging && updateDomain([clampedNewLowerDomain, maxOverrideValue])
  }

  const handleUpperDrag = ({ deltaX }: { deltaX: number }) => {
    const newUpperDomain = xScale.invert(maxOverrideX + deltaX)
    const minDomain = minOverrideValue
    const maxDomain = maxValue
    const clampedNewUpperDomain = clamp(newUpperDomain, minDomain, maxDomain)
    !isDragging && updateDomain([minOverrideValue, clampedNewUpperDomain])
  }

  const [isDragging, setIsDragging] = useState(false)
  const handleDragStart = () => setIsDragging(true)
  const handleDragEnd = () => setIsDragging(false)

  return (
    <g className={`${className}`}>
      {/* bar background rect */}
      <rect
        className="fill-grey"
        x={minX}
        y={(yS - UNDERGROUND_BAR_HEIGHT / 2 - DRAGGABLE_BAR_HEIGHT / 2) + 16}
        width={width}
        height={UNDERGROUND_BAR_HEIGHT}
      />

      <g>
        <DraggableRect
          onDrag={handleAreaDrag}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          x={minOverrideX}
          y={(yS - DRAGGABLE_BAR_HEIGHT) + 16}
          width={Math.abs(maxOverrideX - minOverrideX)}
          height={DRAGGABLE_BAR_HEIGHT}
        />
        <DraggableCircle
          onDrag={handleLowerDrag}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          cx={minOverrideX}
          cy={(yS - DRAGGABLE_BAR_HEIGHT) + 16}
          radius={radius}
          draggingRadius={draggingRadius}
        />
        <DraggableCircle
          onDrag={handleUpperDrag}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          cx={maxOverrideX}
          cy={(yS - DRAGGABLE_BAR_HEIGHT / 2) + 16}
          radius={radius}
          draggingRadius={draggingRadius}
        />
      </g>
    </g>
  )
}
