import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { toLower, toPairs } from 'lodash-es'
import { useMst } from '../state'
import { Tab } from '../types'
import { useLocalization } from '../lib/hooks/useLocalization'
import { FeatureFilter } from './FeatureFilter'
import { Divider, Header, Panel } from './Panel'

interface Props {
  className?: string
}

export const FiltersPanel: React.FC<Props> = observer(({ className = '' }) => {
  const {
    data: { metadataByTab },
    filters: { observingTab, isSomethingFiltered, resetActiveFilters },
    ui: { closeFilterPanel },
  } = useMst()

  const resetAllLabel = useLocalization('reset_all')
  const filterDataLabel = useLocalization('filter_data')

  return (
    <Panel className={`${className}`}>
      <Header onClick={() => closeFilterPanel()}>{filterDataLabel}</Header>
      <Tabs />
      <Divider className="mt-2" />

      <div className="flex-grow h-full overflow-y-auto mt-2 mb-1">
        {metadataByTab[observingTab].map((metadatum, i) => (
          <FeatureFilter key={i} metadatum={metadatum} />
        ))}
      </div>

      {isSomethingFiltered && (
        <div
          className="w-full h-16 border-t border-light-grey flex justify-center items-center cursor-pointer text-red hover:bg-lighterest-grey text-sm underline"
          onClick={() => resetActiveFilters()}
        >
          {resetAllLabel}
        </div>
      )}
    </Panel>
  )
})

interface TabsProps {
  className?: string
}
const Tabs: React.FC<TabsProps> = observer(({ className = '' }) => {
  const {
    data: { metadataByTab },
    filters: { observingTab, setObservingTab, isFeatureFiltered },
  } = useMst()
  const [hoveredTab, setHoveredTab] = useState<null | Tab>(null)
  const sortedTabs = toPairs(metadataByTab).reverse()

  return (
    <div className={`flex ${className}`}>
      {sortedTabs.map(([tab, metadata], i) => {
        const isCurrentTab = tab === observingTab
        const isTabHovered = tab === hoveredTab
        const tabFiltersQuestions = metadata.map((m) => m.question)
        const hasTabSomeActiveFilters = tabFiltersQuestions.some((c) => isFeatureFiltered(c))
        const showBadge = !isCurrentTab && hasTabSomeActiveFilters
        const tabLabel = useLocalization(toLower(tab))

        return (
          <div
            key={i}
            className={`flex-1 flex justify-center items-center cursor-pointer uppercase text-sm pb-2 border-b-2  ${
              isCurrentTab || isTabHovered
                ? 'font-semibold text-black border-black'
                : 'font-light text-dark-grey border-light-grey'
            }`}
            style={{ letterSpacing: 1.5 }}
            onClick={() => setObservingTab(tab as Tab)}
            onPointerEnter={() => setHoveredTab(tab as Tab)}
            onPointerLeave={() => setHoveredTab(null)}
          >
            <div>{tabLabel}</div>
            <div
              className={`w-1 h-1 rounded-full ml-2 ${
                showBadge ? 'bg-dark-blue' : 'bg-transparent'
              }`}
            />
          </div>
        )
      })}
    </div>
  )
})
