import {
  DatasetSpreadsheetRow,
  DatasetsSpreadsheetRow,
  MetadataSpreadsheetRow,
  CrowdmappingSpreadsheetRow,
  StoriesSpreadsheetRow,
  StoryData,
} from './types'
import { getGoogleDocumentId } from './lib/google-utils'
import { matrix2json } from './lib/array-utils'
import { readFromQuerystring } from './lib/sync-url'

const DATASETS_FILE_URL =
  'https://docs.google.com/spreadsheets/d/1XkuFZZkLY_20Lt3VRXI7BFJxu4JyEyo9ZJUIZd9FI2E/edit#gid=0'

const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY
const BIGGEST_GOOGLE_SPREADSHEET_COLUMNS_RANGE = 'A:ZZZ'

const GoogleAPI = {
  async fetchSheetsId(documentId: string): Promise<string[]> {
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${documentId}?key=${GOOGLE_API_KEY}`
    const json = await fetch(url).then((r) => r.json())
    return json.sheets.map((sheet: any) => sheet.properties.title) as string[]
  },

  async fetchSheetContent<T extends object>(
    documentId: string,
    sheetId: string,
    range: string = BIGGEST_GOOGLE_SPREADSHEET_COLUMNS_RANGE
  ): Promise<T[]> {
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${documentId}/values/${sheetId}!${range}?key=${GOOGLE_API_KEY}`
    const json = await fetch(url)
      .then((result) => result.json())
      .then((result) => matrix2json<T>(result.values))
    return json
  },

  async fetchStorySheetContent(documentId: string, sheetId: string): Promise<StoryData> {
    const json = await this.fetchSheetContent<StoriesSpreadsheetRow>(documentId, sheetId)

    const parsedPartialState = Promise.all(
      json.map(async (row) => {
        const stringifiedState = await readFromQuerystring(new URL(row.URL).search)
        return { row, parsed: JSON.parse(stringifiedState) }
      })
    )

    const stepsPromise = parsedPartialState.then((res) =>
      res
        .filter(({ parsed }) => parsed !== undefined)
        .map(({ parsed, row }) => {
          return {
            title: row.Titolo,
            description: row.Descrizione,
            center: parsed!.center,
            feature: parsed!.observingFeature,
            zoom: parsed!.zoom,
            activeFilters: parsed!.activeFilters,
            mapStyles: parsed!.mapStyles,
            mapStyleKey: parsed!.mapStyleKey,
          }
        })
    )
    const steps = await stepsPromise.then((r) => r)

    return { id: sheetId, steps }
  },
}

export const API = {
  async fetchDatasetsSpreadsheet(): Promise<DatasetsSpreadsheetRow[]> {
    const documentId = getGoogleDocumentId(DATASETS_FILE_URL)
    if (!documentId) return []
    const sheetsIds = await GoogleAPI.fetchSheetsId(documentId)
    // datasets spreadsheet should have only one sheet so I ignore the others one
    // TODO: same check also in other functions?
    if (sheetsIds.length > 1) {
      console.warn(
        `There are more than one sheet in your datasets spreadsheet. I read only the first one.`
      )
    }
    return GoogleAPI.fetchSheetContent<DatasetsSpreadsheetRow>(documentId, sheetsIds[0])
  },

  async fetchDataset(url: string): Promise<DatasetSpreadsheetRow[]> {
    const documentId = getGoogleDocumentId(url)
    if (!documentId) return []
    const sheetsIds = await GoogleAPI.fetchSheetsId(documentId)
    return GoogleAPI.fetchSheetContent<DatasetSpreadsheetRow>(documentId, sheetsIds[0])
  },

  async fetchMetadata(url: string): Promise<MetadataSpreadsheetRow[]> {
    const documentId = getGoogleDocumentId(url)
    if (!documentId) return []
    const sheetsIds = await GoogleAPI.fetchSheetsId(documentId)
    return GoogleAPI.fetchSheetContent<MetadataSpreadsheetRow>(documentId, sheetsIds[0])
  },

  async fetchStory(url: string): Promise<StoryData[]> {
    const documentId = getGoogleDocumentId(url)
    if (!documentId) return []
    const storyIds = await GoogleAPI.fetchSheetsId(documentId)
    return Promise.all(storyIds.map((id) => GoogleAPI.fetchStorySheetContent(documentId, id)))
  },

  async fetchCrowdmap(url: string): Promise<CrowdmappingSpreadsheetRow[]> {
    const documentId = getGoogleDocumentId(url)
    if (!documentId) return []
    const sheetsIds = await GoogleAPI.fetchSheetsId(documentId)
    return GoogleAPI.fetchSheetContent<CrowdmappingSpreadsheetRow>(documentId, sheetsIds[0])
  },
}
