export class EventSystem {
  private events: Record<string, Function[]> = Object.create(null)

  subscribe(eventname: string, listener: Function) {
    if (!(eventname in this.events)) this.events[eventname] = []
    this.events[eventname].push(listener)
  }

  unsubscribe(eventname: string, listener: Function) {
    if (!(eventname in this.events)) return
    this.events[eventname] = this.events[eventname].filter((l) => l !== listener)
  }

  fire(eventname: string, event: any) {
    if (!(eventname in this.events)) return
    this.events[eventname].forEach((listener) => listener(event))
  }
}
