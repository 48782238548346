import React from 'react'
import { observer } from 'mobx-react'
import { useMst } from '../state'
import { Divider, Header, Panel } from './Panel'

interface Props {
  className?: string
}

export const InfoPanel: React.FC<Props> = observer(({ className = '' }) => {
  const {
    data: { activeDatasetInfo },
    ui: { setIsInfoPanelOpen },
  } = useMst()
  const { title, subtitle, description } = activeDatasetInfo

  const headerTitle = `${subtitle}`
  const headerSubtitle = `${title}`

  return (
    <Panel className={`${className}`} position="right">
      <div className="flex flex-col justify-between h-full">
        <div className="">
          <Header
            className="pb-0"
            onClick={() => setIsInfoPanelOpen(false)}
            subtitle={headerSubtitle}
          >
            {headerTitle}
          </Header>
          <Divider />
        </div>

        <div className="flex-grow overflow-y-auto px-6 my-4" dangerouslySetInnerHTML={{ __html: description }}></div>
      </div>
    </Panel>
  )
})
