import React from 'react'

interface Props {
  className?: string
  [x: string]: any
}

export const LegendIconArea: React.FC<Props> = ({ className = '', w, ...props }) => {
  const x = 0
  const y = 0
  const h = w
  const strokeWidth = 1

  return (
    <svg width={w} height={h} x={x} y={y}>
      <path
        d={`M${0} ${0}, L${w} ${h * 0.2}, L${w * 0.9} ${h * 0.7}, L${w * 0.25} ${h} Z`}
        strokeWidth={strokeWidth}
        fillOpacity={0.4}
        {...props}
      />
    </svg>
  )
}
