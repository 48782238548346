import React from 'react'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Tooltip, Zoom } from '@material-ui/core'
import { noop } from 'lodash-es'
import { useMst } from '../state'
import { useLocalization } from '../lib/hooks/useLocalization'
// @ts-ignore
import { ReactComponent as CloseIcon } from '../assets/close.svg'

const MAX_VISIBLE_FILTERS = 3

interface Props {
  className?: string
}

export const FiltersSummary: React.FC<Props> = observer(({ className = '' }) => {
  const {
    data: { isFeatureRange, story },
    filters: {
      activeFilters,
      isFeatureFiltered,
      activeFeatureFiltersCounter,
      resetFeatureActiveFilters,
    },
    ui: { openFilterPanel },
  } = useMst()

  const elementsLabel = useLocalization('elements')
  const filters = Object.entries(activeFilters)
    .filter((f) => isFeatureFiltered(f[0]))
    .slice(0, MAX_VISIBLE_FILTERS)

  return (
    <div className='absolute left-0 right-0 flex justify-center top-4'>
      <div
      className={`z-2 p-3 flex justify-center flex-wrap items-center rounded-lg bg-light-grey opacity-80 text-super-grey ${className}`}
      style={{marginLeft:'auto', marginRight:'auto', left:0, right:0}}
    >
      {filters.map(([featureId, values]) => {
        const isRange = isFeatureRange(featureId)

        const count = values.length
        const notRangeLabel = count > 1 ? `${featureId}: ${count} ${elementsLabel}` : featureId
        const rangeLabel = featureId
        const label = isRange ? rangeLabel : notRangeLabel

        return (
          <FiltersSummaryPill
            key={featureId}
            className="mx-1 my-px"
            label={label}
            onClickContent={() => openFilterPanel()}
            onClickIcon={() => {
              story?.quitStoryModality()
              resetFeatureActiveFilters(featureId)
            }}
          />
        )
      })}

      {activeFeatureFiltersCounter > MAX_VISIBLE_FILTERS && (
        <FiltersSummaryPill
          label={`+${activeFeatureFiltersCounter - MAX_VISIBLE_FILTERS}`}
          onClickContent={() => openFilterPanel()}
          withIcon={false}
        />
      )}
    </div>
    </div>
  )
})

interface FiltersSummaryPillProps {
  className?: string
  label: string | number
  withIcon?: boolean
  onClickContent: () => void
  onClickIcon?: () => void
}
export const FiltersSummaryPill: React.FC<FiltersSummaryPillProps> = observer(
  ({ className = '', label, withIcon = true, onClickContent, onClickIcon = noop }) => {
    const editFiltersLabel = useLocalization('edit_filters')
    const removeFilterLabel = useLocalization('remove_filter')

    return (
      <div
        className={`cursor-pointer flex hover:text-white hover:bg-dark-grey items-center rounded-md bg-grey text-black text-sm px-2 py-1 ${className}`}
      >
        <Tooltip title={editFiltersLabel} arrow placement="top" TransitionComponent={Zoom}>
          <div className="hover:text-dark-grey" onClick={onClickContent}>
            {label}
          </div>
        </Tooltip>

        {withIcon && (
          <Tooltip title={removeFilterLabel} arrow placement="top" TransitionComponent={Zoom}>
            <div>
              {/* div wrapper is necessary only to display the tooltip */}
              <CloseIcon
                className="hover:text-black hover:bg-white rounded-full p-px hover:p-0 mx-1"
                style={{ width: 10}}
                onClick={onClickIcon}
              />
            </div>
          </Tooltip>
        )}
      </div>
    )
  }
)
