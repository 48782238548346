import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useMst } from '../state'
import { mapCoverDefaultConfig } from '../lib/map-config'

// bind the MapboxGL access token
mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN as string

interface Props {
  className?: string
  children?: React.ReactNode
}

export const MapCover: React.FC<Props> = observer(({ className = '', children }) => {
  const stateInstance = useMst()
  const {
    ui: { setIsLoading },
  } = stateInstance

  const [mapboxMap, setMapboxMap] = useState<mapboxgl.Map | undefined>(undefined)
  const mapContainerRef = useRef<HTMLDivElement | null>(null)
  const [loaded, setLoaded] = useState<boolean>(false)

  // map initialization
  function initialize() {
    if (!mapContainerRef.current) return

    let isSubscribed = true
    setIsLoading(true)

    const mapInstance = new mapboxgl.Map({
      container: mapContainerRef.current,
      ...mapCoverDefaultConfig,
    })

    mapInstance.on('load', () => {
      if (isSubscribed) {
        setLoaded(true)
        setIsLoading(false)
      }
    })

    setMapboxMap(mapInstance)
    return () => {
      setMapboxMap(undefined)
      isSubscribed = false
    }
  }

  function rotateCamera(timestamp: number) {
    if (!mapboxMap || !loaded) return
    // clamp the rotation between 0-360 degrees and
    // divide timestamp by 150 to slow rotation to ~10 degrees/sec
    mapboxMap.rotateTo((timestamp / 150) % 360, { duration: 0 })
    // request the next frame of the animation
    requestAnimationFrame(rotateCamera)
  }

  function startAnimation() {
    if (!mapboxMap || !loaded) return
    rotateCamera(0)
  }

  useEffect(initialize, [])
  useEffect(startAnimation, [loaded])

  return (
    <div className={`w-full h-full overflow-hidden pointer-events-none ${className}`}>
      <div className="w-full h-full relative" ref={mapContainerRef}>
        {children}
      </div>
    </div>
  )
})
