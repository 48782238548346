import React from 'react'
import { observer } from 'mobx-react-lite'
import { Pill } from './Pill'

interface Props {
  className?: string
  value: string | number
  onClick: () => void
  [x: string]: any
}

export const FeatureFiltersBadge: React.FC<Props> = observer(
  ({ className = '', value, onClick, ...rest }) => {
    return (
      <Pill
        className="bg-transparent border border-dark-blue hover:bg-dark-blue text-dark-blue hover:text-white mr-4"
        onClick={onClick}
        value={value}
        {...rest}
      />
    )
  }
)
