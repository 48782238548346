import React from 'react'
import { useDrag, DragEvent } from '../lib/hooks/useDrag'

interface Props {
  className?: string
  cx: number
  cy: number
  onDrag: (e: DragEvent) => void
  onDragStart: () => void
  onDragEnd: () => void
  radius?: number
  draggingRadius?: number
  color?: string
  border?: string
  strokeWidth?: number
  fillOpacity?: number
  [key: string]: any
}

export const DraggableCircle: React.FC<Props> = ({
  className = '',
  cx,
  cy,
  onDrag,
  onDragStart,
  onDragEnd,
  radius = 5,
  draggingRadius = 10,
  color = '#0A5FA5',
  border = '#ffffff',
  strokeWidth = 1,
  fillOpacity = 1,
  ...props
}) => {
  const [ref, isDragging] = useDrag(onDrag, onDragStart, onDragEnd)

  return (
    <circle
      className={`cursor-pointer select-none ${className}`}
      ref={ref}
      transform={`translate(${cx}, ${cy})`}
      cx={0}
      cy={0}
      r={isDragging ? draggingRadius : radius}
      fill={color}
      stroke={border}
      strokeWidth={strokeWidth}
      fillOpacity={fillOpacity}
      {...props}
    />
  )
}
