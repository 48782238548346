import { types as t, getRoot, flow, Instance } from 'mobx-state-tree'
import { when } from 'mobx'
import { GeoJSONSourceRaw, LngLat } from 'mapbox-gl'
import { groupBy, keyBy, maxBy, omit, omitBy, uniq } from 'lodash-es'
import geo from '../data/geo.json'
import { StateInstance } from './index'
import {
  FORMTYPE_CHECKBOX,
  FORMTYPE_DROPDOWN,
  FORMTYPE_MULTIPLE_CHOICE,
  FORMTYPE_PARAGRAPH,
  FORMTYPE_SCALE,
  FORMTYPE_TEXT,
  GeoJSON,
  GeoJSONFeature,
  STATUS_ACTIVE,
  STATUS_DRAFT,
  StoryData,
  Vector,
  CrowdmappingSpreadsheetRow,
  DatasetSpreadsheetRow,
  DatasetsSpreadsheetRow,
  MetadataSpreadsheetRow,
  Tab,
  TAB_DEMOGRAFICI,
  CollaborateDatum,
} from '../types'
import { API } from '../API'
import {
  bucketsFromRange,
  isBetweenIncluded,
  measureFunctionByType,
  summarize,
} from '../lib/array-utils'
import {
  buildGeojsonSource,
  getNomeAreaStatistica,
  getCodiceAreaStatistica,
  getAreaValue,
} from '../lib/map-utils'
import { StoryModel } from './Story'
import {
  AREA_STATISTICA,
  COLUMNS_TO_AVOID,
  LATITUDE,
  LONGITUDE,
  NOT_VALID_PAYLOAD,
  NOT_VALID_REQUEST,
  QUARTIERE,
  TRUE,
} from '../lib/constants'
import { toRigthType, parseMetadata, DEFAULT_HEATMAP_VALUE } from '../lib/rules'
import { numericExtent, parseScaleTypeDefinition } from '../lib/data-utils'
import { post } from '../lib/http-utils'

export type Value = string | number
export const DataModel = t
  .model('DataModel', {
    datasetsSpreadsheetContent: t.array(t.frozen<DatasetsSpreadsheetRow>()),
    activeDatasetInfo: t.frozen<DatasetsSpreadsheetRow>(),
    dataset: t.array(t.frozen<DatasetSpreadsheetRow>()),
    metadata: t.array(t.frozen<MetadataSpreadsheetRow>()),
    storiesData: t.frozen<StoryData[]>(),
    story: t.maybeNull(StoryModel),
    collaborateCoordinates: t.maybeNull(t.frozen<LngLat>()),
  })
  .views((self) => ({
    get root(): StateInstance {
      return getRoot<StateInstance>(self)
    },
  }))
  .views((self) => ({
    get datasetLenght(): number {
      return self.dataset.length
    },

    isFeatureRange(question: string): boolean {
      const metadatum = self.metadata.find((m) => m.question === question)
      if (!metadatum) throw new Error(`'${question}' not found.`)
      return metadatum.type === FORMTYPE_SCALE || metadatum.type === FORMTYPE_TEXT
    },

    get isCrowdmapActive() {
      return self.activeDatasetInfo && self.activeDatasetInfo.is_crowdmap === TRUE
    },
    isCrowdmapActiveThat(activeDatasetInfo: DatasetsSpreadsheetRow) {
      return activeDatasetInfo && activeDatasetInfo.is_crowdmap === TRUE
    },
  }))
  .views((self) => ({
    // TODO: split in different functions
    // Returns the original metadata array removing `answer-N` fields and adding:
    //  - isRange: true/false. true if type === SCALE | TEXT
    //  - uniqValues:
    //      - type = TEXT | SCALE -> [minValue, maxValue] // based on range values (SCALE) or data values (TEXT)
    //      - type = others -> ['cat1', 'cat2', ...] categorical uniq values
    //  - buckets: array of ranges
    get metadataInfo(): MetadataSpreadsheetRow[] | null {
      if (self.isCrowdmapActive) return null
      if (!self.metadata[0]) return null

      const regex = /^answer-[0-9]*$/g
      const answerColumnNames = Object.keys(self.metadata[0]).filter((m) => m.match(regex))

      const result = self.metadata.map((metadatum) => {
        const { question, type, text_range_min, text_range_max, bars_numbers } = metadatum
        const isRange = self.isFeatureRange(question)
        let uniqValues: Value[] | Vector = []
        let buckets: Vector[] = []

        switch (type) {
          // uniqValues are answers from answer-1, answer-2... columns
          case FORMTYPE_CHECKBOX:
          case FORMTYPE_DROPDOWN:
          case FORMTYPE_MULTIPLE_CHOICE: {
            uniqValues = answerColumnNames.map((c) => metadatum[c]).filter(Boolean)
            break
          }

          // uniqValues are answers from dataset
          case FORMTYPE_PARAGRAPH:
            uniqValues = uniq(self.dataset.map((d) => d[question])).filter(Boolean)
            break

          // uniqValues is a vector [min, max] with:
          //  - min is the min value between the min value in dataset and text_range_min (if exists)
          //  - max is the max value between the max value in dataset and text_range_max (if exists)
          case FORMTYPE_TEXT: {
            const dataValues = uniq(self.dataset.map((d) => d[question]))
            uniqValues = numericExtent(dataValues, [text_range_min, text_range_max])
            buckets = bucketsFromRange(uniqValues as Vector, bars_numbers)
            break
          }

          //
          case FORMTYPE_SCALE: {
            const definition = metadatum[answerColumnNames[0]] // string like 'number, number, label, label'
            if (!definition)
              throw new Error(
                `There's something wrong in the scale type ('definition'). It must be defined in col '${answerColumnNames[0]}' by something like 'number, number, label, label'.`
              )
            uniqValues = parseScaleTypeDefinition(definition).range
            const [minValue, maxValue] = uniqValues as Vector
            const bucketsNumber = maxValue - minValue + 1
            buckets = bucketsFromRange(uniqValues as Vector, bucketsNumber)
            break
          }

          default:
            throw new Error(`'${metadatum.type}' is not a valid type.`)
        }

        // return the same object removing the `answer-N` columns and adding the new fields
        return {
          ...omitBy(metadatum, (value, key) => answerColumnNames.includes(key)),
          isRange,
          uniqValues,
          buckets,
        }
      })
      return result
    },
  }))
  .views((self) => ({
    // features on top
    get observableFeatures(): MetadataSpreadsheetRow[] | null {
      if (self.isCrowdmapActive) return null
      if (!self.metadataInfo) return null
      return self.metadataInfo!.filter(
        ({ exclude_from_site, is_observable, type }) =>
          !exclude_from_site && is_observable && (type === FORMTYPE_TEXT || type === FORMTYPE_SCALE)
      )
    },

    // features in filters panel
    get filterableFeatures(): MetadataSpreadsheetRow[] | null {
      if (self.isCrowdmapActive) return null
      if (!self.metadataInfo) return []
      return self.metadataInfo!.filter((metadatum) => !metadatum.exclude_from_site)
    },

    get metadataByQuestion(): Record<string, MetadataSpreadsheetRow> {
      return keyBy(self.metadataInfo, 'question')
    },

    get metadataByTab(): Record<Tab, MetadataSpreadsheetRow[]> {
      const metadataWithoutExcludeFromSite = self.metadataInfo?.filter((m) => !m.exclude_from_site)
      return groupBy(metadataWithoutExcludeFromSite, 'tab') as Record<Tab, MetadataSpreadsheetRow[]>
    },

    get filteredDataset(): DatasetSpreadsheetRow[] {
      const {
        filters: { activeFilters },
      } = self.root
      const activeFiltersTuple = Object.entries(activeFilters as Record<string, Value[] | Vector>)
      const filteredDataset = self.dataset.filter((datum) => {
        const isDatumIncluded = activeFiltersTuple.every(([question, values]) => {
          const value = datum[question]
          if (!self.isFeatureRange(question)) {
            // if there are no selected feature values, consider them all selected
            if (!values.length) return true
            else return values.includes(value)
          } else {
            return isBetweenIncluded(value, values as Vector)
          }
        })
        return isDatumIncluded
      })
      return filteredDataset
    },

    get hasStory(): boolean {
      return self.story !== null && self.storiesData.length > 0
    },
  }))
  .views((self) => ({
    get filteredDatasetLenght(): number {
      return self.filteredDataset.length
    },

    get statisticAreaMeasureValue(): Record<string, number> {
      if (self.isCrowdmapActive) return { collaborate: DEFAULT_HEATMAP_VALUE } // because observingFeature is undefined if isCrowdmapActive = true. I know, not a very elegant fix
      if (!self.root.filters.observingFeature) return { collaborate: DEFAULT_HEATMAP_VALUE }
      const {
        filters: {
          observingFeature: { measure, question },
        },
      } = self.root
      const measureFn = measureFunctionByType<DatasetSpreadsheetRow>(measure, question)
      const groupedByStatisticAreaMeasure = summarize<DatasetSpreadsheetRow, number>(
        self.filteredDataset,
        AREA_STATISTICA,
        measureFn
      )
      return groupedByStatisticAreaMeasure
    },
  }))
  .views((self) => ({
    get featuresWithId(): GeoJSONFeature[] {
      // add a uniq id (the area code) to each area feature
      // and the heatmap value to the feature properties object
      const featuresWithId = (geo as GeoJSON).features.map<GeoJSONFeature>((f: GeoJSONFeature) => {
        const statisticAreaName = getNomeAreaStatistica(f)
        const statisticAreaCode = getCodiceAreaStatistica(f)
        // || DEFAULT_HEATMAP_VALUE (= 0) because the dataset has less statistic areas than the geojson
        const measureValue =
          self.statisticAreaMeasureValue[statisticAreaName] || DEFAULT_HEATMAP_VALUE
        return {
          ...f,
          id: statisticAreaCode,
          properties: {
            ...f.properties,
            measureValue,
          },
        }
      })
      return featuresWithId
    },
  }))
  .views((self) => ({
    get featuresGeojsonSource(): GeoJSONSourceRaw {
      return buildGeojsonSource<any>(self.featuresWithId) as GeoJSONSourceRaw
    },

    get pointsGeojsonSource(): GeoJSONSourceRaw {
      const pointsFeatures = self.dataset.map((datum) => {
        return {
          type: 'Feature',
          id: datum.id,
          properties: { ...datum },
          geometry: { type: 'Point', coordinates: [datum[LONGITUDE], datum[LATITUDE]] },
        }
      })
      return buildGeojsonSource<any>(pointsFeatures) as GeoJSONSourceRaw
    },
  }))
  .views((self) => ({
    get filteredNeighborhoods() {
      const { neighborhoodSearch } = self.root.ui
      const filtered: GeoJSONFeature[] = self.featuresWithId.filter((f) => {
        const statisticAreaName = getNomeAreaStatistica(f)
        return statisticAreaName.toLowerCase().includes(neighborhoodSearch)
      })
      return filtered
    },

    get featuresMeasureValuesById(): Record<string, number> {
      const data = self.featuresGeojsonSource!.data as GeoJSON.FeatureCollection<GeoJSON.Geometry>
      const values = data.features.map((f) => {
        const statisticAreaCode = getCodiceAreaStatistica(f as GeoJSONFeature)
        const value = getAreaValue(f as GeoJSONFeature)
        return [statisticAreaCode, value]
      })
      return Object.fromEntries(values)
    },
  }))
  .views((self) => ({
    get sortedFilteredNeighborhoods() {
      const sortedFiltered = self.filteredNeighborhoods.sort((a, b) => {
        const aValue = getAreaValue(a)
        const bValue = getAreaValue(b)
        return bValue - aValue
      })
      return sortedFiltered
    },

    get activeDatasetsRows() {
      return self.datasetsSpreadsheetContent.filter((d) => d.status === STATUS_ACTIVE)
    },

    get draftDatasetsRows() {
      return self.datasetsSpreadsheetContent.filter((d) => d.status === STATUS_DRAFT)
    },
  }))
  .views((self) => ({
    get datasetRowsToShow(): Array<DatasetsSpreadsheetRow> {
      const {
        ui: { isAdmin, isSelectedHomepageTabCrowdmap },
      } = self.root
      const booleanValue = isSelectedHomepageTabCrowdmap ? 'TRUE' : 'FALSE'

      const rowsPool = isAdmin
        ? [...self.activeDatasetsRows, ...self.draftDatasetsRows]
        : self.activeDatasetsRows

      return rowsPool.filter((r) => r.is_crowdmap === booleanValue)
    },

    get neighborhoodsMaxValue(): number {
      const { featuresWithId } = self
      const maxValuedNeighborhood = maxBy(featuresWithId, (f) => {
        const value = getAreaValue(f)
        return value
      })
      const maxValue = maxValuedNeighborhood ? getAreaValue(maxValuedNeighborhood) : 1
      return maxValue
    },

    get questionsToExclude() {
      return self.metadata.filter((m) => m.exclude_from_site).map((d) => d.question)
    },
  }))
  .actions((self) => ({
    setStory(storyId: string) {
      const storyData = self.storiesData.find((datum) => datum.id === storyId)
      if (storyData) {
        self.story = StoryModel.create(storyData)
      }
    },
  }))
  .actions((self) => ({
    fetchDatasetsSpreadsheetContent: flow(function* fetchDatasetsSpreadsheet() {
      const datasetsSpreadsheetContent: DatasetsSpreadsheetRow[] = yield API.fetchDatasetsSpreadsheet()
      const withoutInactiveDatasets = datasetsSpreadsheetContent.filter(
        (d) => d.status !== 'inactive'
      )
      self.datasetsSpreadsheetContent.replace(withoutInactiveDatasets)
    }),

    fetchDataset: flow(function* fetchDataset(url: string) {
      const rawDataset = yield API.fetchDataset(url)
      self.dataset.replace(rawDataset)
    }),

    fetchMetadata: flow(function* fetchMetadata(url: string) {
      const rawMetadata: MetadataSpreadsheetRow[] = yield API.fetchMetadata(url)
      const metadata = parseMetadata(rawMetadata)
      self.metadata.replace(metadata)
    }),

    fetchStory: flow(function* fetchStory(url: string) {
      const storiesData = yield API.fetchStory(url)
      self.storiesData = storiesData
      if (storiesData.length > 0) self.setStory(storiesData[0].id)
    }),

    fetchCrowdmap: flow(function* fetchCrowdmap(url: string) {
      const dataset: CrowdmappingSpreadsheetRow[] = yield API.fetchCrowdmap(url)
      const datasetWithId = dataset
        .filter((d) => d.exclude !== 'x' && d.category === self.activeDatasetInfo.title)
        .map((d, i) => ({
          ...d,
          id: i,
          Longitude: Number(d.Longitude),
          Latitude: Number(d.Latitude),
        }))
      // @ts-ignore
      self.dataset.replace(datasetWithId)
    }),
    fetchCrowdmapThat: flow(function* fetchCrowdmap(info: DatasetsSpreadsheetRow) {
      const dataset: CrowdmappingSpreadsheetRow[] = yield API.fetchCrowdmap(info.dataset_url)
      const datasetWithId = dataset
        .filter((d) => d.exclude !== 'x' && d.category === info.title)
        .map((d, i) => ({
          ...d,
          id: i,
          Longitude: Number(d.Longitude),
          Latitude: Number(d.Latitude),
        }))
      // @ts-ignore
      self.dataset.replace(datasetWithId)
    }),

    // transform dataset editing string to number, string or boolean
    async parseDataset() {
      const result = self.dataset.map((datum, i) => {
        const parsedDatum = Object.entries(datum).reduce((acc, curr) => {
          const [question, response] = curr
          if (!COLUMNS_TO_AVOID.includes(question)) {
            const metadatum = self.metadataByQuestion[question]
            let type
            if (!metadatum) {
              if ([LATITUDE, LONGITUDE].includes(question)) {
                type = FORMTYPE_TEXT
              } else {
                type = FORMTYPE_PARAGRAPH
              }
            } else {
              if ([AREA_STATISTICA, QUARTIERE].includes(question)) {
                // horrible fix. I need AREA_STATISTICA value in questions data but it hasn't a metadatum so I assign a string type to it
                type = FORMTYPE_PARAGRAPH
              } else {
                type = metadatum.type
              }
            }
            const convertedResponse = toRigthType(response, type)
            acc[question] = convertedResponse
          }
          return acc
        }, {})
        // modify datum adding an id and remove questions to exclude_from_site
        return { ...omit(parsedDatum, self.questionsToExclude), id: i }
      })
      self.dataset.replace(result as DatasetSpreadsheetRow[])
    },
  }))
  .actions((self) => ({
    setActiveDatasetInfo(info: DatasetsSpreadsheetRow) {
      self.activeDatasetInfo = info
    },

    setCollaborateCoordinates(collaborateCoordinates: LngLat) {
      self.collaborateCoordinates = collaborateCoordinates
    },

    async collaborate(data: CollaborateDatum) {
      const result = await (
        await post('https://europe-west1-atlante-91a98.cloudfunctions.net/append', data)
      ).text()
      if (result === NOT_VALID_REQUEST) {
        return false
      }
      if (result === NOT_VALID_PAYLOAD) {
        return false
      }
      return true
    },
  }))
  .actions((self) => ({
    async exploreThat(datasetRow: DatasetsSpreadsheetRow) {
      const {
        ui: { isMobile, resetFeatureOpenFilters, setIsLoading, setIsStoryPanelCollapsed },
        filters: { setObservingFeature, resetActiveFilters, setObservingTab },
      } = self.root

      setIsLoading(true)

      if (!datasetRow) return
      if (self.isCrowdmapActiveThat(datasetRow)) {
        await self.fetchCrowdmapThat(datasetRow)
        self.setActiveDatasetInfo(datasetRow)
      } else {
        await self.fetchDataset(datasetRow.dataset_url)
        await self.fetchMetadata(datasetRow.metadata_url)
        await self.parseDataset()
        await self.fetchStory(datasetRow.stories_url)
        self.setActiveDatasetInfo(datasetRow)

        if (isMobile) setIsStoryPanelCollapsed(true)

        if (self.observableFeatures?.length === 0)
          throw new Error(`There are no observable features. I can't create the map.`)

        // by default is selected the first feature
        self.observableFeatures?.length && setObservingFeature(self.observableFeatures[0])
      }

      // set DEMOGRAFICI ad default tab if it exists, otherwise set the first Tab (that should be ALTRO)
      const metadataTabs = Object.keys(self.metadataByTab)
      const defaultTab = metadataTabs.includes(TAB_DEMOGRAFICI) ? TAB_DEMOGRAFICI : metadataTabs[0]
      setObservingTab(defaultTab as Tab)

      resetActiveFilters()
      resetFeatureOpenFilters()
      setIsLoading(false)
    },
  }))
  .actions((self) => ({
    async afterCreate() {
      const {
        ui: { setIsLoading },
        retrievingFromSerialized,
        retrievedFromSerialized,
      } = self.root

      // the following `when` callback may never be called, but should if the application of a
      // serialized state fails for some reason
      when(
        () => !retrievingFromSerialized && !retrievedFromSerialized,
        async () => {
          setIsLoading(true)
          await self.fetchDatasetsSpreadsheetContent()
          setIsLoading(false)
        }
      )
    },
  }))

export interface DataModelInstance extends Instance<typeof DataModel> {}
