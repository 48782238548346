import { createContext, useContext } from 'react'
import { applyPatch, IJsonPatch, Instance, types as t } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { syncUrlState } from '../lib/sync-url'
import { Tab } from '../types'
import { DataModel, DataModelInstance } from './Data'
import { UIModel } from './UI'
import { FiltersModel, FiltersModelInstance } from './Filters'
import { MapModel } from './Map'

export const StateModel = t
  .model('StateModel', {
    data: t.optional(DataModel, {} as DataModelInstance),
    filters: t.optional(FiltersModel, {} as FiltersModelInstance),
    ui: t.optional(UIModel, {}),
    map: t.optional(MapModel, {}),
    retrievingFromSerialized: t.optional(t.boolean, false),
    retrievedFromSerialized: t.optional(t.boolean, false),
  })
  .views((self) => ({
    get serializableState() {
      return {
        activeDatasetInfo: toJS(self.data.activeDatasetInfo),
        activeFilters: toJS(self.filters.activeFilters),
        zoom: toJS(self.map.zoom),
        center: toJS(self.map.center),
        mapStyles: toJS(self.map.mapStyles),
        mapStyleKey: toJS(self.map.mapStyleKey),
        observingTab: toJS(self.filters.observingTab) as Tab,
        observingFeature: toJS(self.filters.observingFeature),
      }
    },
    get applyRemotePatch() {
      return function (patch: IJsonPatch) {
        if (patch.path === '/map/zoom') {
          self.map.flyTo({ zoom: patch.value })
        }
        if (patch.path === '/map/center') {
          self.map.flyTo({ center: patch.value })
        }
        applyPatch(self, patch)
      }
    },
  }))
  .actions((self) => ({
    afterCreate() {
      syncUrlState(self as StateInstance)
    },
  }))

export const stateInstance = StateModel.create()
export interface StateInstance extends Instance<typeof StateModel> {}

const RootStateContext = createContext<StateInstance | null>(null)
export const Provider = RootStateContext.Provider

export function useMst() {
  const state = useContext(RootStateContext)
  if (state === null) throw new Error('State cannot be null, please add a context provider')
  return state
}
