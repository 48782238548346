import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import useSize from '@react-hook/size'
import { useMst } from '../state'
import { MetadataSpreadsheetRow } from '../types'
import { HORIZONTAL_BARCHART, RANGE_BARCHART, VERTICAL_BARCHART } from '../lib/constants'
import { TextButton } from './TextButton'
import { FeatureFiltersBadge } from './FeatureFiltersBadge'
import { BarchartHorizontal } from './BarchartHorizontal'
import { BarchartVertical } from './BarchartVertical'
import { BarchartRange } from './BarchartRange'
import { useLocalization } from '../lib/hooks/useLocalization'

export const numericLabelWidth = 90

interface Props {
  className?: string
  metadatum: MetadataSpreadsheetRow
}

export const FeatureFilter: React.FC<Props> = observer(({ className = '', metadatum }) => {
  const {
    ui: { isFeatureFilterOpen },
    filters: { chartType },
  } = useMst()
  const target = useRef(null)
  const [containerWidth, containerHeight] = useSize(target)

  const { question } = metadatum
  const isOpen = isFeatureFilterOpen(question)

  const Chart = () => {
    const viz = chartType(question)
    if (viz === HORIZONTAL_BARCHART) {
      return <BarchartHorizontal question={question} width={containerWidth} />
    } else if (viz === VERTICAL_BARCHART) {
      return <BarchartVertical question={question} width={containerWidth} height={220} />
    } else if (viz === RANGE_BARCHART) {
      return <BarchartRange question={question} width={containerWidth} height={220} />
    } else {
      console.error(`${chartType} is not a valid chart type.`)
      return null
    }
  }

  return (
    <div className={`border-b border-grey pt-4 pb-3 select-none ${className}`}>
      <FeatureFilterHeader question={question} />
      {isOpen && <SelectAllAndResetSelection question={question} />}

      <div className="overflow-y-auto overflow-x-hidden" style={{ maxHeight: 220 }} ref={target}>
        {isOpen && Chart()}
      </div>
    </div>
  )
})

interface FeatureFilterHeaderProps {
  className?: string
  question: string
}
const FeatureFilterHeader: React.FC<FeatureFilterHeaderProps> = observer(
  ({ className = '', question }) => {
    const {
      ui: { toggleFeatureOpenFilters, isFeatureFilterOpen },
      data: { metadataByQuestion },
      filters: { isFeatureFiltered },
    } = useMst()
    const isOpen = isFeatureFilterOpen(question)
    const isActive = isFeatureFiltered(question)

    return (
      <div className={`flex justify-between pl-4 items-center ${className}`}>
        <div
          className={`cursor-pointer py-px ${
            isOpen || isActive ? 'font-semibold text-black' : 'text-dark-grey'
          } hover:text-black`}
          onClick={() => toggleFeatureOpenFilters(question)}
        >
          {(metadataByQuestion[question] as MetadataSpreadsheetRow).question}
        </div>

        {isActive && <Badge question={question} />}
      </div>
    )
  }
)

interface BadgeProps {
  question: string
}
const Badge: React.FC<BadgeProps> = observer(({ question }) => {
  const {
    filters: { resetFeatureActiveFilters, activeFeaturesValuesCount },
    data: { metadataByQuestion },
  } = useMst()

  return (
    <FeatureFiltersBadge
      value={metadataByQuestion[question].isRange ? '1' : activeFeaturesValuesCount(question)}
      onClick={() => resetFeatureActiveFilters(question)}
    />
  )
})

interface SelectAllAndResetSelectionProps {
  className?: string
  question: string
  [x: string]: any
}
const SelectAllAndResetSelection: React.FC<SelectAllAndResetSelectionProps> = observer(
  ({ className = '', question, ...rest }) => {
    const {
      filters: { selectAllFeatureValuesActiveFilters },
    } = useMst()

    return (
      <div
        className={`flex justify-center items-center text-dark-grey text-sm mt-3 ${className}`}
        {...rest}
      >
        <TextButton onClick={() => selectAllFeatureValuesActiveFilters(question)}>
          {useLocalization('select_all')}
        </TextButton>
      </div>
    )
  }
)
