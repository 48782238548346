import { types as t, getRoot, Instance } from 'mobx-state-tree'
import { fromPairs, isEqual } from 'lodash-es'
import { StateInstance } from './index'
import {
  Vector,
  Viz,
  Value,
  TAB_DEMOGRAFICI,
  DatasetSpreadsheetRow,
  MetadataSpreadsheetRow,
  Tab,
} from '../types'
import { isBetween, addOrRemove, summarize } from '../lib/array-utils'
import { HORIZONTAL_BARCHART, RANGE_BARCHART } from '../lib/constants'

export interface FilterValueInfo {
  filteredDatasetCount: number
  filteredSDataCount: number
  percentage: number
  filteredPercentage: number
}
export type FiltersResult = Record<string, Record<Value, FilterValueInfo>>

export const FiltersModel = t
  .model('FiltersModel', {
    activeFilters: t.frozen<Record<string, Value[] | Vector>>({}),
    observingFeature: t.frozen<MetadataSpreadsheetRow>(),
    observingTab: t.optional(t.frozen<Tab>(), TAB_DEMOGRAFICI),
  })
  .views((self) => ({
    get root(): StateInstance {
      return getRoot<StateInstance>(self)
    },
  }))
  .views((self) => ({
    activeFeaturesValuesCount(question: string) {
      return self.activeFilters[question].length
    },

    isFeatureFiltered(question: string) {
      const {
        data: { isFeatureRange, metadataByQuestion },
      } = self.root
      const values = self.activeFilters[question]
      if (isFeatureRange(question)) {
        const metadatum = metadataByQuestion[question] as MetadataSpreadsheetRow
        const defaultRange = metadatum.uniqValues as Vector
        return !isEqual(defaultRange, values)
      } else {
        return values.length > 0
      }
    },
  }))
  .views((self) => ({
    get activeFeatureFiltersCounter() {
      const filteredFeatures = Object.keys(self.activeFilters).filter((question) =>
        self.isFeatureFiltered(question)
      )
      return filteredFeatures.length
    },
  }))
  .views((self) => ({
    get isSomethingFiltered() {
      return self.activeFeatureFiltersCounter > 0
    },
  }))
  .views((self) => ({
    get showFiltersSummary(): boolean {
      const {
        ui: { isFilterPanelOpen, userCanInteract, isStoryModality },
      } = self.root
      return (
        self.isSomethingFiltered && (!isFilterPanelOpen || !userCanInteract) && !isStoryModality
      )
    },
  }))
  .views((self) => ({
    barchartFilterResult(question: string): Record<Value, FilterValueInfo> {
      const {
        data: { dataset, datasetLenght, filteredDataset, metadataByQuestion },
      } = self.root
      const metadatum = metadataByQuestion[question]

      const uniqValues = metadatum.uniqValues as Value[]
      const dataCountByValue = summarize<DatasetSpreadsheetRow, number>(
        dataset,
        question,
        (group) => group.length,
        uniqValues as string[]
      )

      const filteredDatasetCountByValue = summarize<DatasetSpreadsheetRow, number>(
        filteredDataset,
        question,
        (group) => group.length,
        uniqValues as string[]
      )
      const info = uniqValues.reduce((acc, value) => {
        const dataCount = dataCountByValue[value]
        const filteredDatasetCount = filteredDatasetCountByValue[value]
        const percentage = (dataCount * 100) / datasetLenght
        const filteredPercentage = (filteredDatasetCount * 100) / datasetLenght
        acc[value] = {
          dataCount,
          filteredDatasetCount,
          percentage,
          filteredPercentage,
        }
        return acc
      }, {})
      return info
    },

    rangeFilterResult(question: string): Record<string, FilterValueInfo> {
      const {
        data: { dataset, datasetLenght, filteredDataset, metadataByQuestion },
      } = self.root
      const metadatum = metadataByQuestion[question] as MetadataSpreadsheetRow
      const info = (metadatum.buckets as Vector[]).reduce((acc, bucket) => {
        const keyBucket = `${bucket[0]}-${bucket[1]}`
        const dataInThatBucket = dataset.filter((s) => isBetween(s[question], bucket))
        const filteredDataInThatBucket = filteredDataset.filter((s) =>
          isBetween(s[question], bucket)
        )
        const dataCount = dataInThatBucket.length
        const filteredDataCount = filteredDataInThatBucket.length
        const percentage = (dataCount * 100) / datasetLenght
        const filteredPercentage = (filteredDataCount * 100) / datasetLenght
        acc[keyBucket] = {
          dataCount,
          filteredDataCount,
          percentage,
          filteredPercentage,
        }
        return acc
      }, {})
      return info
    },
  }))
  .views((self) => ({
    chartType(question: string): Viz {
      const {
        data: { metadataByQuestion },
      } = self.root
      const metadatum = metadataByQuestion[question] as MetadataSpreadsheetRow
      if (metadatum.isRange) {
        return RANGE_BARCHART
      } else {
        return HORIZONTAL_BARCHART
      }
    },

    get defaultEmptyFilters(): Record<string, Value[] | Vector> {
      const {
        data: { filterableFeatures },
      } = self.root
      const featureEmptyArrayTuple = (filterableFeatures as MetadataSpreadsheetRow[])?.map(
        ({ question, isRange, uniqValues }) => {
          if (isRange) {
            return [question, uniqValues]
          } else {
            return [question, []]
          }
        }
      )
      return fromPairs(featureEmptyArrayTuple)
    },
  }))
  .actions((self) => ({
    resetActiveFilters() {
      self.activeFilters = self.defaultEmptyFilters
    },

    setActiveFilters(filters: typeof self.activeFilters) {
      self.activeFilters = filters
    },

    addFilter(feature: string, value: Value) {
      const currentActiveFilters = self.activeFilters
      const newValues = addOrRemove(currentActiveFilters[feature] as Value[], value)
      const newActiveFilters = { ...currentActiveFilters, [feature]: newValues }
      self.activeFilters = newActiveFilters
    },

    addRangeFilter(question: string, range: Vector) {
      const {
        data: { metadataByQuestion },
      } = self.root
      const metadatum = metadataByQuestion[question] as MetadataSpreadsheetRow
      const currentActiveFilters = self.activeFilters
      let newRange: Vector
      if (range === currentActiveFilters[question]) {
        newRange = metadatum.uniqValues
      } else {
        newRange = range
      }
      const newActiveFilters = { ...currentActiveFilters, [question]: newRange }
      self.activeFilters = newActiveFilters
    },

    resetFeatureActiveFilters(question: string) {
      const {
        data: { metadataByQuestion },
      } = self.root
      const currentActiveFilters = self.activeFilters
      const metadatum = metadataByQuestion[question] as MetadataSpreadsheetRow
      const resetValues = metadatum.isRange ? metadatum.uniqValues : []
      const newActiveFilters = { ...currentActiveFilters, [question]: resetValues }
      self.activeFilters = newActiveFilters
    },

    selectAllFeatureValuesActiveFilters(question: string) {
      const {
        data: { metadataByQuestion },
      } = self.root
      const values = metadataByQuestion[question].uniqValues
      const newActiveFilters = { ...self.activeFilters, [question]: values }
      self.activeFilters = newActiveFilters
    },

    setObservingFeature(observingFeature: MetadataSpreadsheetRow) {
      self.observingFeature = observingFeature
    },

    setObservingTab(tab: Tab) {
      self.observingTab = tab
    },

    resetObservingTab() {
      self.observingTab = TAB_DEMOGRAFICI
    },

    setStoryFilters(
      feature: MetadataSpreadsheetRow,
      partialFilters: Record<string, Value[] | Vector>
    ) {
      self.observingFeature = feature
      self.activeFilters = { ...self.defaultEmptyFilters, ...partialFilters }
    },
  }))

export interface FiltersModelInstance extends Instance<typeof FiltersModel> {}
