import React from 'react'

interface Props {
  className?: string
  w: number
  strokeWidth?: number
  [x: string]: any
}

export const LegendIconCircle: React.FC<Props> = ({
  className = '',
  w,
  strokeWidth = 1,
  ...props
}) => {
  const x = 0
  const y = 0
  const h = w
  const r = (w - strokeWidth) / 2

  return (
    <svg width={w} height={h} x={x} y={y}>
      <circle
        cx={x + w / 2}
        cy={y + h / 2}
        r={r}
        fillOpacity={0.4}
        strokeWidth={strokeWidth}
        {...props}
      />
    </svg>
  )
}
