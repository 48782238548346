import { Visibility } from 'mapbox-gl'
import {
  HORIZONTAL_BARCHART,
  VERTICAL_BARCHART,
  RANGE_BARCHART,
  TRUE,
  FALSE,
  EN,
  IT,
} from './lib/constants'

export type Vector = [number, number]

export type Viz = typeof HORIZONTAL_BARCHART | typeof VERTICAL_BARCHART | typeof RANGE_BARCHART
export type Value = string | number

export interface GeoJSON {
  type: 'FeatureCollection'
  features: GeoJSONFeature[]
}
export interface GeoFeatureProperties {
  geo_point_2d: Vector
  codice_area_statistica: number
  nome_area_statistica: string
  codice_quartiere: string
  nome_quartiere: string
  codice_zona: string
  nome_zona: string
}

export type GeoJSONFeature = GeoJSON.Feature<GeoJSON.Geometry, GeoFeatureProperties>

//------------------
// COMMONS
//------------------
export interface Coordinates {
  Latitude: string
  Longitude: string
}
export interface Geocode extends Coordinates {
  Quartiere: string
  AreaStatistica: string
}

export interface Timestamp {
  Timestamp: string
}

//------------------
// SPREADSHEETS
//------------------

// datasets list
//---------------------------------------------------------
export const STATUS_ACTIVE = 'active'
export const STATUS_INACTIVE = 'inactive'
export const STATUS_DRAFT = 'draft'
export type Status = typeof STATUS_ACTIVE | typeof STATUS_INACTIVE | typeof STATUS_DRAFT

export interface DatasetsSpreadsheetRow {
  title: string
  subtitle: string
  description: string
  dataset_url: string
  metadata_url: string
  stories_url: string
  status: Status
  is_crowdmap: typeof TRUE | typeof FALSE
}

// results
//---------------------------------------------------------
export type DatasetDatum = Record<string, any>
export type DatasetSpreadsheetRow = DatasetDatum & Geocode & Timestamp & { id: number }

// metadata
//---------------------------------------------------------
export const TAB_DEMOGRAFICI = 'DEMOGRAFICI'
export const TAB_ALTRO = 'ALTRO'
export type Tab = typeof TAB_DEMOGRAFICI | typeof TAB_ALTRO

export const FORMTYPE_TEXT = 'TEXT'
export const FORMTYPE_PARAGRAPH = 'PARAGRAPH'
export const FORMTYPE_CHECKBOX = 'CHECKBOX'
export const FORMTYPE_DROPDOWN = 'DROPDOWN'
export const FORMTYPE_MULTIPLE_CHOICE = 'MULTIPLE CHOICE'
export const FORMTYPE_SCALE = 'SCALE'
export type FormType =
  | typeof FORMTYPE_TEXT
  | typeof FORMTYPE_PARAGRAPH
  | typeof FORMTYPE_CHECKBOX
  | typeof FORMTYPE_DROPDOWN
  | typeof FORMTYPE_MULTIPLE_CHOICE
  | typeof FORMTYPE_SCALE

export const MEASURE_AVERAGE = 'AVERAGE'
export const MEASURE_MODE = 'MODE'
export const MEASURE_MIN = 'MIN'
export const MEASURE_MAX = 'MAX'
export const MEASURE_SUM = 'SUM'
export const MEASURE_COUNT = 'COUNT'
export type Measure =
  | typeof MEASURE_AVERAGE
  | typeof MEASURE_MODE
  | typeof MEASURE_MIN
  | typeof MEASURE_MAX
  | typeof MEASURE_SUM
  | typeof MEASURE_COUNT

export interface MetadataDatum {
  question_check: boolean
  question: string // used as id to match metadata and data
  'question-label': string
  hint: string
  is_required: boolean
  tab: Tab
  type: FormType
  is_observable: boolean
  measure: Measure
  text_range_min: number
  text_range_max: number
  bars_numbers: number
  exclude_from_site: boolean
}
export type MetadataSpreadsheetRow = DatasetDatum & Record<string, any>

// stories
//---------------------------------------------------------
export interface StoriesSpreadsheetRow {
  Descrizione: string
  Titolo: string
  URL: string
}

export interface StoryStep {
  center: Vector
  zoom: number
  feature: MetadataSpreadsheetRow
  title?: string
  description?: string
  activeFilters?: Record<string, Value[] | Vector>
  mapStyleKey: string
  mapStyles: MapStyles
}

export interface StoryData {
  id: string
  steps: StoryStep[]
}

// crowdmapping
//---------------------------------------------------------
export interface CrowdmappingSpreadsheetRow extends Coordinates {
  description: string
  exclude: 'x' | ''
  category: string
}

export interface CollaborateDatum {
  description: string
  Latitude: number
  Longitude: number
  category: string
}

//---------------------------------------------------------
// MAP LAYERS
//---------------------------------------------------------
export type LayerType =
  | 'fill'
  | 'line'
  | 'symbol'
  | 'circle'
  | 'heatmap'
  | 'fill-extrusion'
  | 'raster'
  | 'hillshade'
  | 'background'
  | 'sky'
export interface LayerInfo {
  visibility: Visibility
  name: string
  type: LayerType
  // zIndex: - bottom, + top
  // order should depend by layer type:
  //   ↑ symbol, circle and similar
  //   ↑ line
  //   ↑ layers with area type like fill, fill-extsrusion, heatmap...,
  //   ↑ feature stroke
  //   ↑ feature fill
  zIndex: number
  // showOrder: + bottom, - top
  // row order shown to frontend
  showOrder: number
}
export type LayersVisibility = Record<string, LayerInfo>
export interface MapStyle {
  name: string
  url: string
  layers: LayersVisibility
}
export type MapStyles = Record<string, MapStyle>

export type Language = typeof IT | typeof EN
