import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../state'
import { VISIBILITY_VISIBLE } from '../lib/constants'
import { Tooltip, Zoom } from '@material-ui/core'
import { Divider, Header } from './Panel'
// @ts-ignore
import { ReactComponent as LayersIcon } from '../assets/layers.svg'
// @ts-ignore
import { ReactComponent as EyeIcon } from '../assets/eye-icon.svg'
import { LegendIconAssociation } from './LegendIconAssociation'
import { Select } from './Select'

interface Props {
  className?: string
}

export const LayersButton: React.FC<Props> = observer(({ className = '' }) => {
  const {
    ui: { isMobile, isLayersPanelOpen, toggleIsLayersPanelOpen },
  } = useMst()

  return (
    <>
      <div
        className={`absolute ${
          isMobile ? 'top-4' : 'top-28'
        } right-4 z-2 ba bg-white p-3 rounded-full cursor-pointer shadow ${className}`}
        onClick={() => toggleIsLayersPanelOpen()}
      >
        <Tooltip title="Mappa libera" arrow placement="left" TransitionComponent={Zoom}>
          <div>
            <LayersIcon
              className={`w-4 hover:text-red ${isLayersPanelOpen ? 'text-red' : 'text-dark-grey'}`}
            />
          </div>
          {/* div wrapper is necessary only to display the tooltip */}
        </Tooltip>
      </div>

      {isLayersPanelOpen && <LayersVisibilityPanel />}
    </>
  )
})

const LayersVisibilityPanel: React.FC<Props> = observer(({ className = '' }) => {
  const {
    ui: { isMobile, setIsLayersPanelOpen },
    map: { mapStyles, toggleLayerVisibility, setMapStyleKey, sortedLayersByShowOrder },
  } = useMst()

  const [hoveredLayerId, setHoveredLayerId] = useState<string | null>(null)
  const options = Object.entries(mapStyles).map((d) => ({ label: d[1].name, value: d[0] }))

  return (
    <div
      className={`absolute ${
        isMobile ? 'top-16 left-4' : 'top-40 w-80'
      } right-4 z-3 bg-white rounded shadow ${className}`}
    >
      <Header onClick={() => setIsLayersPanelOpen(false)}>Livelli</Header>
      <Divider />

      <div className="pt-4 text-dark-grey">
        <Section className="pb-4" title="Basemap">
          <Select
            className="px-6"
            options={options}
            onChange={(e: any) => setMapStyleKey(e.value)}
            defaultValue={options[0]}
            isClearable={false}
            isSearchable={false}
          />
        </Section>

        <Section className="" title="Livelli">
          <div className="overflow-y-auto" style={{ height: 250 }}>
            {sortedLayersByShowOrder.map((item) => {
              const [key, { name, visibility }] = item
              const isVisible = visibility === VISIBILITY_VISIBLE
              const isHovered = hoveredLayerId === key

              return (
                <div>
                  <div
                    key={key}
                    className="px-6 flex cursor-pointer select-none hover:bg-lighterest-grey"
                    onClick={() => toggleLayerVisibility(key)}
                    onPointerMove={() => setHoveredLayerId(key)}
                    onPointerOut={() => setHoveredLayerId(null)}
                  >
                    <div className="flex  w-full border-b border-gray-300 py-3">
                      <div className="flex mr-2">
                        <EyeIcon
                          className={`mr-1 w-4 h-4 ${
                            isHovered
                              ? 'text-black'
                              : isVisible
                              ? 'text-black'
                              : 'text-lighter-grey'
                          }`}
                        />
                        <div className="pl-1 pr-2 text-gray-300">|</div>
                        <div
                          className={`w-4 h-4 flex justify-center items-center ${
                            isHovered ? 'opacity-100' : isVisible ? 'opacity-100' : 'opacity-20'
                          }`}
                        >
                          <LegendIconAssociation layerId={key} />
                        </div>
                      </div>

                      <div
                        className={`
                    ${
                      isHovered
                        ? 'text-super-grey'
                        : isVisible
                        ? 'text-mid-grey'
                        : 'text-lighter-grey'
                    } 
                    overflow-x-hidden`}
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {name}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </Section>
      </div>
    </div>
  )
})

interface SectionProps {
  title: string
  className?: string
}
const Section: React.FC<SectionProps> = ({ className = '', title, children }) => {
  return (
    <div className={`${className}`}>
      <div className="text-xs text-dark-grey pb-1 ml-6">{title}</div>
      {children}
    </div>
  )
}
