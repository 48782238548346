import React from 'react'
import {
  DOTS_COLOR,
  DOTS_ID_LAYER,
  FEATURES_FILL_COLOR,
  FEATURES_FILL_ID_LAYER,
  FEATURES_STROKE_COLOR,
  FEATURES_STROKE_ID_LAYER,
  AREE_URBANE_CENTRI_INNOVAZIONE_LAYER,
  TRASPORTI_AREE_PEDONALI_LAYER,
  AREE_URBANE_INCIDENTI_RILEVANTI_LAYER,
  AREE_URBANE_MARGINALITA_SOCIALE_LAYER,
  AREE_URBANE_RISCHIO_INDUSTRIALE_LAYER,
  AREE_URBANE_SPECIALIZZATE_RESIDENZIALI_LAYER,
  TRASPORTI_PISTE_CICLOPEDONALI_LAYER,
  TRASPORTI_BUS_LAYER,
  TRASPORTI_TRAM_LAYER,
  STORICA_EDIFICI_STORICI_LAYER,
  STORICA_PLESSI_ALMA_MATER_LAYER,
  STORICA_PORTICI_LAYER,
  SPAZI_PARCHI_E_AREE_VERDI_LAYER,
  SPAZI_PUBBLICI_PIAZZE_LAYER,
  SPAZI_PUBBLICI_SPAZI_PUBBLICI_LAYER,
  SPAZI_UFFICIO_RELAZIONI_PUBBLICHE_LAYER,
  SERVIZI_BIBLIOTECHE_LAYER,
  SERVIZI_ECCELLENZA_SANITARIA_LAYER,
  SERVIZI_LUOGHI_DI_CULTURA_LAYER,
  SERVIZI_MERCATI_COMUNALI_LAYER,
  SERVIZI_SCUOLE_LAYER,
  SERVIZI_SERVIZI_PER_GIOVANI_LAYER,
  SERVIZI_TRASPORTI_LAYER,
} from '../lib/constants'
import { LegendIconArea } from './LegendIconArea'
import { LegendIconCircle } from './LegendIconCircle'
import { LegendIconLine } from './LegendIconLine'
// @ts-ignore
import { ReactComponent as UrpIcon } from '../assets/icon-urp.colors.svg'
// @ts-ignore
import { ReactComponent as ScuoleIcon } from '../assets/icon-scuole.colors.svg'
// @ts-ignore
import { ReactComponent as MercatoIcon } from '../assets/icon-mercato.colors.svg'

const W_AREA = 10
const W_LINE = 14
const w_SYMBOL = 10
const W_CIRCLE = 8

interface Props {
  layerId: string
}

export const LegendIconAssociation: React.FC<Props> = ({ layerId }) => {
  switch (layerId) {
    // aree urbane
    case AREE_URBANE_CENTRI_INNOVAZIONE_LAYER: {
      return (
        <LegendIconArea
          w={W_AREA}
          fill="#00ff2b"
          fillOpacity={0.25}
          stroke="black"
          strokeWidth={1}
        />
      )
    }
    case AREE_URBANE_MARGINALITA_SOCIALE_LAYER: {
      return <LegendIconArea w={W_AREA} fill="#00b4eb" fillOpacity={0.3} stroke="none" />
    }
    case AREE_URBANE_SPECIALIZZATE_RESIDENZIALI_LAYER: {
      return <LegendIconArea w={W_AREA} fill="#f1e888" fillOpacity={0.52} stroke="none" />
    }
    case AREE_URBANE_RISCHIO_INDUSTRIALE_LAYER: {
      return (
        <LegendIconArea
          w={W_AREA}
          fill="#db7c00"
          fillOpacity={0.36}
          stroke="#000000"
          strokeOpacity={0.35}
        />
      )
    }
    case AREE_URBANE_INCIDENTI_RILEVANTI_LAYER: {
      return <LegendIconArea w={W_AREA} fill="#9500c2" fillOpacity={0.3} stroke="none" />
    }

    // trasporti
    case TRASPORTI_AREE_PEDONALI_LAYER: {
      return (
        <LegendIconArea
          w={W_AREA}
          fill="#f8bc16"
          fillOpacity={0.5}
          stroke="black"
          strokeWidth={1}
        />
      )
    }
    case TRASPORTI_PISTE_CICLOPEDONALI_LAYER: {
      return <LegendIconLine w={W_LINE} stroke="#00c0c7" />
    }
    case TRASPORTI_TRAM_LAYER: {
      return <LegendIconLine w={W_LINE} stroke="#ff9ef4" />
    }
    case TRASPORTI_BUS_LAYER: {
      return <LegendIconLine w={W_LINE} stroke="#8f00cc" />
    }

    // storica
    case STORICA_PORTICI_LAYER: {
      return (
        <LegendIconArea w={W_AREA} fill="#f0be60" fillOpacity={1} stroke="black" strokeWidth={1} />
      )
    }
    case STORICA_PLESSI_ALMA_MATER_LAYER: {
      return (
        <LegendIconArea w={W_AREA} fill="#8742cd" fillOpacity={1} stroke="black" strokeWidth={1} />
      )
    }
    case STORICA_EDIFICI_STORICI_LAYER: {
      return <LegendIconCircle w={W_CIRCLE} fill="#e515e5" fillOpacity={1} />
    }

    // spazi pubblici
    case SPAZI_UFFICIO_RELAZIONI_PUBBLICHE_LAYER: {
      return <UrpIcon />
    }
    case SPAZI_PARCHI_E_AREE_VERDI_LAYER: {
      return (
        <LegendIconArea w={W_AREA} fill="#1bc021" fillOpacity={1} stroke="black" strokeWidth={1} />
      )
    }
    case SPAZI_PUBBLICI_SPAZI_PUBBLICI_LAYER: {
      return (
        <LegendIconArea w={W_AREA} fill="#a8d4ff" fillOpacity={1} stroke="017af4" strokeWidth={1} />
      )
    }
    case SPAZI_PUBBLICI_PIAZZE_LAYER: {
      return (
        <LegendIconArea w={W_AREA} fill="#a8ecff" fillOpacity={1} stroke="black" strokeWidth={1} />
      )
    }

    // servizi
    case SERVIZI_LUOGHI_DI_CULTURA_LAYER: {
      return <LegendIconCircle w={W_CIRCLE} fill="#7806ac" fillOpacity={1} />
    }
    case SERVIZI_BIBLIOTECHE_LAYER: {
      return <LegendIconCircle w={W_CIRCLE} fill="#07cf82" fillOpacity={1} stroke="none" />
    }
    case SERVIZI_SERVIZI_PER_GIOVANI_LAYER: {
      return <LegendIconCircle w={W_CIRCLE} fill="#e3c102" fillOpacity={1} stroke="none" />
    }
    case SERVIZI_MERCATI_COMUNALI_LAYER: {
      return <MercatoIcon />
    }
    case SERVIZI_TRASPORTI_LAYER: {
      return <LegendIconLine w={W_LINE} stroke="#ff9ef4" />
    }
    case SERVIZI_SCUOLE_LAYER: {
      return <ScuoleIcon />
    }
    case SERVIZI_ECCELLENZA_SANITARIA_LAYER: {
      return <LegendIconArea w={W_AREA} fill="#dfbafc" fillOpacity={1} stroke="none" />
    }

    // our levels on both maps
    case FEATURES_FILL_ID_LAYER: {
      return (
        <LegendIconArea w={W_AREA} fill={FEATURES_FILL_COLOR()} stroke={FEATURES_FILL_COLOR()} />
      )
    }
    case FEATURES_STROKE_ID_LAYER: {
      return <LegendIconLine w={W_LINE} stroke={FEATURES_STROKE_COLOR} />
    }
    case DOTS_ID_LAYER: {
      return <LegendIconCircle w={W_CIRCLE} fill={DOTS_COLOR} fillOpacity={1} stroke="none" />
    }

    default:
      throw new Error(`${layerId} not found.`)
  }
}
