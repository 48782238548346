import { observer } from 'mobx-react-lite'
import React from 'react'
import { EN, IT } from '../lib/constants'
import { useLocalization } from '../lib/hooks/useLocalization'
import { useMst } from '../state'
import { Button } from './Header'

export const MenuPage: React.FC = observer(() => {
  const {
    data: { datasetsSpreadsheetContent, exploreThat },
    ui: {
      isMobile,
      setIsMenuPageOpen,
      setIsInHomepage,
      setIsAboutPageOpen,
      setIsTutorialPageOpen,
      setIsVideoPageOpen,
      language,
      setLanguage,
    },
  } = useMst()

  return (
    <div className="flex flex-col w-full h-full absolute top-0 left-0 z-5 px-6 py-4 bg-red text-white">
      <div className="flex">
        <Button
          className={`pr-2 text-white ${language === IT ? 'font-bold' : ''}`}
          textToShow={IT}
          onClick={() => setLanguage(IT)}
        />
        <Button
          className={`pr-2 text-white ${language === EN ? 'font-bold' : ''}`}
          textToShow={EN}
          onClick={() => setLanguage(EN)}
        />
      </div>

      <div className="top-0 sticky z-1">
        <div className="flex flex-col items-center pt-5">
          <div className="flex flex-row">
            <div
              className="px-3 pb-2 pt-3 mx-2 text-white text-md uppercase cursor-pointer border border-red hover:border-white"
              onClick={() => {
                setIsVideoPageOpen(true)
                setIsMenuPageOpen(false)
              }}
            >
              {useLocalization('video')}
            </div>

            <div
              className="px-3 pb-2 pt-3 mx-2 text-white text-md uppercase cursor-pointer border border-red hover:border-white"
              onClick={() => {
                setIsTutorialPageOpen(true)
                setIsMenuPageOpen(false)
              }}
            >
              {useLocalization('tutorial')}
            </div>

            <div
              className="px-3 pb-2 pt-3 mx-2 text-white text-md uppercase cursor-pointer border border-red hover:border-white"
              onClick={() => {
                setIsAboutPageOpen(true)
                setIsMenuPageOpen(false)
              }}
            >
              {useLocalization('about')}
            </div>
          </div>

          <div
            className="text-xs underline px-3 pb-2 pt-3 mx-2 text-white text-md uppercase cursor-pointer border border-red hover:border-white"
            onClick={() => {
              setIsAboutPageOpen(false)
              setIsTutorialPageOpen(false)
              setIsVideoPageOpen(false)
              setIsMenuPageOpen(false)
              setIsInHomepage(true)
            }}
          >
            {useLocalization('close')}
          </div>

          <div className="border-b border-white my-3 w-full"></div>
        </div>
      </div>
      <div className="flex flex-col items-center pt-10 overflow-y-auto">
        <div className={`${isMobile ? 'w-full' : 'w-3/5'} text-center`}>
          {datasetsSpreadsheetContent.map((row, i) => (
            <div
              key={i}
              className="mb-4 text-xl cursor-pointer hover:text-black"
              onClick={async () => {
                await exploreThat(row)
                setIsMenuPageOpen(false)
                setIsAboutPageOpen(false)
                setIsTutorialPageOpen(false)
                setIsVideoPageOpen(false)
              }}
            >
              {row.subtitle}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
})

{
  /* <div className="flex">
  <Button
    className={`pr-2 text-white ${language === IT ? 'font-bold' : ''}`}
    textToShow={IT}
    onClick={() => setLanguage(IT)}
  />
  <Button
    className={`pr-2 text-white ${language === EN ? 'font-bold' : ''}`}
    textToShow={EN}
    onClick={() => setLanguage(EN)}
  />
</div> */
}
