import React from 'react'
import { observer } from 'mobx-react-lite'
import { noop } from 'lodash-es'
import { Tooltip, Zoom } from '@material-ui/core'
import { useMst } from '../state'
// @ts-ignore
import { ReactComponent as MapIcon } from '../assets/map.svg'
// @ts-ignore
import { ReactComponent as StoryIcon } from '../assets/story.svg'
// @ts-ignore
import { ReactComponent as FiltersPanelIcon } from '../assets/filters-panel.svg'
// @ts-ignore
import { ReactComponent as StatisticAreaPanelIcon } from '../assets/statistic-area-panel.svg'
// @ts-ignore
import { ReactComponent as OtherPanelIcon } from '../assets/other-panel.svg'
// @ts-ignore
import { ReactComponent as StoryPanelIcon } from '../assets/story-panel.svg'
import { useLocalization } from '../lib/hooks/useLocalization'

export const MAP = 'map'
export const STORY = 'story'
export const FILTERS_PANEL = 'filters panel'
export const STATISTIC_AREA_PANEL = 'statisticarea panel'
export const OTHER_PANEL = 'other panel'
export const STORY_PANEL = 'story panel'
export type Modality = typeof MAP | typeof STORY
export type Panel =
  | typeof FILTERS_PANEL
  | typeof STATISTIC_AREA_PANEL
  | typeof OTHER_PANEL
  | typeof STORY_PANEL
export type ButtonType = Modality | Panel

interface Props {
  className?: string
}

export const FloatingButtons: React.FC<Props> = observer(({ className = '' }) => {
  const {
    ui: { isMobile },
  } = useMst()

  return (
    <div
      className={`z-3 absolute bg-transparent bottom-4 ${
        isMobile
          ? 'left-4 right-4 flex justify-between'
          : 'left-0 right-0 margin-auto flex justify-center'
      } ${className}`}
    >
      <Modality className={`${isMobile ? '' : 'mr-9'}`} />
      <Panel />
    </div>
  )
})

interface ModalityProp {
  className?: string
  disabled?: boolean
}
const Modality: React.FC<ModalityProp> = observer(({ className = '', disabled = false }) => {
  const {
    ui: { toggleModality, isMapModality, isStoryModality },
  } = useMst()

  return (
    <div className={`relative flex bg-light-grey rounded p-1 ${className}`}>
      <div
        className={`absolute bg-white w-11 h-11 rounded top-1 ${
          isMapModality ? 'left-1' : 'left-12'
        } transition-all duration-300`}
      />
      <Tooltip
        title={useLocalization('mappa_libera')}
        arrow
        placement="top"
        TransitionComponent={Zoom}
      >
        <div>
          <Button
            className="z-3"
            type={MAP}
            onClick={disabled ? noop : () => toggleModality()}
            isActive={isMapModality}
          />
        </div>
        {/* div wrapper is necessary only to display the tooltip */}
      </Tooltip>

      <Tooltip
        title={useLocalization('guided_story')}
        arrow
        placement="top"
        TransitionComponent={Zoom}
      >
        <div>
          <Button
            className="z-3"
            type={STORY}
            onClick={disabled ? noop : () => toggleModality()}
            isActive={isStoryModality}
          />
        </div>
        {/* div wrapper is necessary only to display the tooltip */}
      </Tooltip>
    </div>
  )
})

interface PanelProp {
  className?: string
}
const Panel: React.FC<PanelProp> = observer(({ className = '' }) => {
  const {
    ui: {
      isStoryModality,
      isFilterPanelOpen,
      toggleFilterPanelOpen,
      isAreaPanelOpen,
      isOtherPanelOpen,
      toggleIsAreaPanelOpen,
      isStoryPanelOpen,
      toggleIsStoryPanelOpen,
      toggleIsOtherPanelOpen,
    },
    data: { story },
  } = useMst()

  const color = (isActive: boolean) => (isActive ? 'bg-white' : 'bg-transparent')

  return (
    <div className={`flex bg-light-grey rounded p-1 ${className}`}>
      {isStoryModality ? (
        <Tooltip title={useLocalization('story')} arrow placement="top" TransitionComponent={Zoom}>
          <div>
            <Button
              className={`mr-1 ${color(isStoryPanelOpen)}`}
              type={STORY_PANEL}
              isActive={isStoryPanelOpen}
              onClick={toggleIsStoryPanelOpen}
              notification={!isStoryPanelOpen && story?.isPlaying}
            />
          </div>
          {/* div wrapper is necessary only to display the tooltip */}
        </Tooltip>
      ) : (
        <Tooltip
          title={useLocalization('filters')}
          arrow
          placement="top"
          TransitionComponent={Zoom}
        >
          <div>
            <Button
              className={`mr-1 ${color(isFilterPanelOpen)}`}
              type={FILTERS_PANEL}
              isActive={isFilterPanelOpen}
              onClick={toggleFilterPanelOpen}
            />
          </div>
          {/* div wrapper is necessary only to display the tooltip */}
        </Tooltip>
      )}
      <Tooltip title={useLocalization('ranking')} arrow placement="top" TransitionComponent={Zoom}>
        <div>
          <Button
            className={`mr-1 ${color(isAreaPanelOpen)}`}
            type={STATISTIC_AREA_PANEL}
            isActive={isAreaPanelOpen}
            onClick={toggleIsAreaPanelOpen}
          />
        </div>
        {/* div wrapper is necessary only to display the tooltip */}
      </Tooltip>

      <Tooltip title={useLocalization('altro')} arrow placement="top" TransitionComponent={Zoom}>
        <div>
          <Button
            className={`${color(isOtherPanelOpen)}`}
            type={OTHER_PANEL}
            isActive={isOtherPanelOpen}
            onClick={toggleIsOtherPanelOpen}
          />
        </div>
        {/* div wrapper is necessary only to display the tooltip */}
      </Tooltip>
    </div>
  )
})

interface ButtonProp {
  className?: string
  type: ButtonType
  onClick?: () => void
  isActive?: boolean
  notification?: boolean
  disabled?: boolean
}
const Button: React.FC<ButtonProp> = ({
  className = '',
  type,
  onClick = noop,
  isActive = false,
  notification = false,
  disabled = false,
}) => {
  const Icon = iconComponent(type, 'w-5 h-5')

  return (
    <div
      className={`w-11 h-11 flex justify-center items-center rounded relative ${
        disabled
          ? 'text-not-to-light-grey'
          : isActive
          ? 'text-red cursor-pointer'
          : 'text-dark-grey cursor-pointer'
      } ${className}`}
      onClick={disabled ? noop : onClick}
    >
      {Icon}
      {notification && <div className="bg-red w-1 h-1 absolute top-2 right-2 rounded-full" />}
    </div>
  )
}

function iconComponent(type: ButtonType, className: string) {
  switch (type) {
    case MAP:
      return <MapIcon className={className} />
    case STORY:
      return <StoryIcon className={className} />
    case FILTERS_PANEL:
      return <FiltersPanelIcon className={className} />
    case STATISTIC_AREA_PANEL:
      return <StatisticAreaPanelIcon className={className} />
    case OTHER_PANEL:
      return <OtherPanelIcon className={className} />
    case STORY_PANEL:
      return <StoryPanelIcon className={className} />
    default:
      throw new Error(`"${type}" is not a valid button type.`)
  }
}
