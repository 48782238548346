import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import useSize from '@react-hook/size'
import { useMst } from '../state'
import { EN, HEADER_HEIGHT, IT } from '../lib/constants'
// @ts-ignore
import { ReactComponent as HamburgerIcon } from '../assets/hamburger_menu.svg'
import { ObservableFeatures } from './ObservableFeatures'
import { useLocalization } from '../lib/hooks/useLocalization'

interface Props {
  className?: string
}
export const Header: React.FC<Props> = observer(({ className = '' }) => {
  const {
    ui: { isMobile },
  } = useMst()

  return (
    <div className={`z-1 ${className}`} style={{ boxShadow: `0px 3px 5px #E1E1E1` }}>
      {isMobile ? <MobileHeader /> : <DesktopHeader />}
    </div>
  )
})

interface MobileHeaderProps {
  className?: string
}
const MobileHeader: React.FC<MobileHeaderProps> = observer(({ className = '' }) => {
  const {
    data: { isCrowdmapActive },
    ui: { isInHomepage, setIsMenuPageOpen, setIsInHomepage, headerHeight },
  } = useMst()

  const target = useRef(null)
  const [w, h] = useSize(target)

  return (
    <div className={`flex items-center ${className}`} style={{ height: headerHeight }}>
      <div className="w-14 h-full flex justify-center items-center border-r border-light-grey">
        <HamburgerIcon
          className="w-4 cursor-pointer text-black hover:text-red"
          onClick={() => {
            setIsMenuPageOpen(true)
            setIsInHomepage(false)
          }}
        />
      </div>
      <div className="h-full flex flex-grow items-center" ref={target}>
        {isInHomepage || isCrowdmapActive ? (
          <Logo className="pl-3" />
        ) : (
          <ObservableFeatures width={w} />
        )}
      </div>
    </div>
  )
})

interface DesktopHeaderProps {
  className?: string
}
const DesktopHeader: React.FC<DesktopHeaderProps> = observer(({ className = '' }) => {
  const {
    data: { activeDatasetInfo },
    ui: {
      isInHomepage,
      isMenuPageOpen,
      setIsMenuPageOpen,
      setIsAboutPageOpen,
      setIsTutorialPageOpen,
      headerHeight,
      showDoubleHeader,
      language,
      setLanguage,
      setIsVideoPageOpen,
    },
  } = useMst()

  const titleHeader = (
    <div
      className={`flex justify-between items-center px-8 ${className}`}
      style={{ height: HEADER_HEIGHT }}
    >
      <div className="flex items-center">
        <Logo />
        {!isInHomepage && !isMenuPageOpen && (
          <div className="text-super-grey text-lg pl-4">{activeDatasetInfo.subtitle}</div>
        )}
      </div>
      <div className={`flex ${className}`}>
        <Button
          className="mr-6 hover:text-red"
          textToShow={`${useLocalization('video')}`}
          onClick={() => setIsVideoPageOpen(true)}
        />
        <Button
          className="mr-6 hover:text-red"
          textToShow={`${useLocalization('tutorial')}`}
          onClick={() => setIsTutorialPageOpen(true)}
        />

        <Button
          className="mr-6 hover:text-red"
          textToShow={`${useLocalization('about')}`}
          onClick={() => setIsAboutPageOpen(true)}
        />

        <Button
          className="mr-6 hover:text-red"
          textToShow={`${useLocalization('menu')}`}
          onClick={() => setIsMenuPageOpen(true)}
        />

        <Button
          className={`pr-2 hover:text-red ${language === IT ? 'font-bold underline' : ''}`}
          textToShow={IT}
          onClick={() => setLanguage(IT)}
        />
        <Button
          className={`hover:text-red ${language === EN ? 'font-bold underline' : ''}`}
          textToShow={EN}
          onClick={() => setLanguage(EN)}
        />
      </div>
    </div>
  )

  return showDoubleHeader ? (
    <div className={`${className}`} style={{ height: headerHeight }}>
      {titleHeader}
      <ObservableFeatures width="100%" className="border-t border-light-grey" />
    </div>
  ) : (
    titleHeader
  )
})

interface ButtonProps {
  className?: string
  textToShow: string
  onClick: () => void
}
export const Button: React.FC<ButtonProps> = ({ className = '', textToShow, onClick }) => {
  return (
    <div className={`cursor-pointer text-super-grey uppercase ${className}`} onClick={onClick}>
      {textToShow}
    </div>
  )
}

interface LogoProps {
  className?: string
}
const Logo: React.FC<LogoProps> = observer(({ className = '' }) => {
  const {
    ui: {
      isMobile,
      setIsInHomepage,
      setIsMenuPageOpen,
      setIsAboutPageOpen,
      setIsTutorialPageOpen,
      setIsVideoPageOpen,
      setInfoDataset,
      closeFilterPanel,
      resetFeatureOpenFilters,
      resetNeighborhoodSearch,
      setIsCollaboraPanelOpen,
      setIsColaboratePopupVisible,
      resetModality,
      setIsAreaPanelOpen,
      setIsStoryPanelOpen,
      setIsStoryPanelCollapsed,
      setIsOtherPanelOpen,
      setIsInfoPanelOpen,
      setIsLayersPanelOpen,
      setCrowdmapAnswer,
      resetErrorMessages,
      setThereWasACloudFunctionError,
      setIsSelectedHomepageTabCrowdmap,
    },
    data: { story },
    map: {
      resetHoveredFeatureId,
      resetClickedFeatureId,
      resetZoomSilently,
      resetCenterSilently,
      resetMapStyles,
      resetMapStyleKey,
    },
    filters: { resetActiveFilters, resetObservingTab },
  } = useMst()

  const resetState = () => {
    // ui
    setIsInHomepage(true)
    setIsMenuPageOpen(false)
    setIsAboutPageOpen(false)
    setIsTutorialPageOpen(false)
    setIsVideoPageOpen(false)
    setInfoDataset(null)
    closeFilterPanel()
    resetFeatureOpenFilters()
    resetNeighborhoodSearch()
    setIsCollaboraPanelOpen(false)
    setIsColaboratePopupVisible(false)
    resetModality()
    setIsAreaPanelOpen(false)
    setIsStoryPanelOpen(true)
    setIsStoryPanelCollapsed(false)
    setIsOtherPanelOpen(false)
    setIsInfoPanelOpen(false)
    setIsLayersPanelOpen(false)
    setCrowdmapAnswer(null)
    resetErrorMessages()
    setThereWasACloudFunctionError(false)
    setIsSelectedHomepageTabCrowdmap(false)
    // story
    story?.resetStepIndex()
    story?.setIsPlaying(false)
    // map
    resetHoveredFeatureId()
    resetClickedFeatureId()
    resetZoomSilently()
    resetCenterSilently()
    resetMapStyles()
    resetMapStyleKey()
    // filters
    resetActiveFilters()
    resetObservingTab()
  }

  return (
    <div
      className={`cursor-pointer text-red ${
        isMobile ? '' : 'hover:underline'
      } text-2xl ${className}`}
      onClick={resetState}
    >
      {useLocalization('atlante_urbano')}
    </div>
  )
})
