import { get } from 'lodash-es'
import {
  AnimationOptions,
  CameraOptions,
  LngLatBoundsLike,
  PaddingOptions,
  PointLike,
} from 'mapbox-gl'
import * as turf from '@turf/turf'
import { GeoJSONFeature, Vector } from '../types'
import { FEATURES_FILL_ID_LAYER, FEATURES_STROKE_ID_LAYER } from './constants'
import { Feature, MultiPolygon, Polygon } from '@turf/turf'
import { crowdmapCircleBoundaryFeature } from './map-config'

export function buildGeojsonSource<T>(features: T[]) {
  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: features,
    },
  }
}

// ref: https://docs.mapbox.com/mapbox-gl-js/api/map/#map#fitbounds
interface Options extends AnimationOptions, CameraOptions {
  padding?: number | PaddingOptions
  linear?: boolean
  easing?: (time: number) => number
  offset?: PointLike
  maxZoom?: number
}
/**
 * Pans and zooms the map to contain its visible area within the specified geographical bounds.
 * This function will also reset the map's bearing to 0 if bearing is nonzero.
 * @param map map instance
 * @param bbox bounds to fit
 * @param options
 * @see https://docs.mapbox.com/mapbox-gl-js/api/map/#map#fitbounds
 */
export function fitBounds(map: mapboxgl.Map, bbox: LngLatBoundsLike, options: Options) {
  if (!map) throw new Error(`Map instance doesn't exist.`)
  const { maxZoom, padding } = options
  return map.fitBounds(bbox, {
    maxZoom: maxZoom,
    padding: padding,
  })
}

export function updateLayerDataSource(map: mapboxgl.Map, sourceId: string, dataSource: any) {
  if (!map) throw new Error(`Map instance doesn't exist.`)

  const oldDataSource = map.getSource(sourceId) as mapboxgl.GeoJSONSource
  if (!oldDataSource) throw new Error(`A source with id '${sourceId}' doesn't exist.`)
  oldDataSource.setData(dataSource as GeoJSON.FeatureCollection<GeoJSON.Geometry>)
}

export function updatePaintProperty(
  map: mapboxgl.Map,
  layerId: string,
  property: string,
  newValue: any
) {
  if (!map) throw new Error(`Map instance doesn't exist.`)
  if (!map.getLayer(layerId)) throw new Error(`A layer with id '${layerId}' doesn't exist.`)
  map.setPaintProperty(layerId, property, newValue)
}

export function printActiveLayers(map: mapboxgl.Map) {
  if (!map) return
  const activeLayers = map.getStyle().layers
  const addedLayers = activeLayers?.slice(151, activeLayers.length + 1)
  console.log('activeLayers: ', activeLayers)
  console.log(`addedLayers [${activeLayers?.length}]: ${addedLayers?.map((l) => l.id)}`)
  console.log(`sources`, map.getStyle().sources)
}

export function isFeaturesLayer(layers: mapboxgl.MapboxGeoJSONFeature[]) {
  const layersIds = layers.map((f) => f.layer.id)
  const isFeatureLayer =
    layersIds.includes(FEATURES_FILL_ID_LAYER) || layersIds.includes(FEATURES_STROKE_ID_LAYER)
  return isFeatureLayer
}

export function getAreaValue(feature: GeoJSONFeature): number {
  return get(feature, 'properties.measureValue') || 0
}

export function getCodiceAreaStatistica(feature: GeoJSONFeature): number {
  return get(feature, 'properties.codice_area_statistica')
}
export function getNomeAreaStatistica(feature: GeoJSONFeature): string {
  return get(feature, 'properties.nome_area_statistica')
}

export function getCodiceQuartiere(feature: GeoJSONFeature): string {
  return get(feature, 'properties.codice_quartiere')
}
export function getNomeQuartiere(feature: GeoJSONFeature): string {
  return get(feature, 'properties.nome_quartiere')
}

export function getCodiceZona(feature: GeoJSONFeature): string {
  return get(feature, 'properties.codice_zona')
}
export function getNomeZona(feature: GeoJSONFeature): string {
  return get(feature, 'properties.nome_zona')
}

export function getPoint2D(feature: GeoJSONFeature): Vector {
  return get(feature, 'properties.geo_point_2d')
}

export function isPointInPolygon(
  pointCoords: Vector,
  polygonFeature: Feature<Polygon | MultiPolygon>
): boolean {
  return turf.booleanPointInPolygon(pointCoords, polygonFeature)
}

export function isPointInCircleBoundary(pointCoords: Vector) {
  return isPointInPolygon(pointCoords, crowdmapCircleBoundaryFeature)
}
