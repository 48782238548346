import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../state'
// @ts-ignore
import { ReactComponent as CloseIcon } from '../assets/close.svg'
// @ts-ignore
import { ReactComponent as DownIcon } from '../assets/down.svg'
// @ts-ignore
import { ReactComponent as UpIcon } from '../assets/up.svg'

type Position = 'left' | 'right' | 'bottom'

function horizontalSpacePosition(position: Position) {
  if (position === 'left') return 'left-4'
  else if (position === 'right') return 'right-4'
  else if (position === 'bottom') return 'ml-auto mr-auto left-0 right-0'
  else throw new Error(`"${position}" is not a valid position.`)
}
function verticalSpacePosition(position: Position, showFiltersSummary: boolean) {
  if (position === 'left' || position === 'right') {
    if (showFiltersSummary) return 'top-4'
    else return 'top-4'
  } else if (position === 'bottom') return ''
  else throw new Error(`"${position}" is not a valid position.`)
}

interface PanelProps {
  className?: string
  position?: Position
  isCollapsed?: boolean
}
export const Panel: React.FC<PanelProps> = observer(
  ({ className = '', position = 'left', isCollapsed = false, children }) => {
    const {
      ui: { isMobile },
      filters: { showFiltersSummary },
    } = useMst()

    const horizontalSpace = horizontalSpacePosition(position)
    const verticalSpace = verticalSpacePosition(position, showFiltersSummary)

    return (
      <div
        className={`z-3 absolute bottom-20 rounded ${
          isMobile
            ? `${(isCollapsed && isMobile) || position === 'bottom' ? '' : 'top-2'} left-2 right-2`
            : `${verticalSpace} ${horizontalSpace} w-80`
        } flex flex-col justify-between bg-white text-super-grey shadow ${className}`}
      >
        {children}
      </div>
    )
  }
)

interface HeaderProps {
  className?: string
  onClick: () => void
  collapsable?: boolean
  subtitle?: string
  isCollapsed?: boolean
}
export const Header: React.FC<HeaderProps> = ({
  className = '',
  onClick,
  collapsable = false,
  isCollapsed = false,
  subtitle = undefined,
  children,
}) => {
  const Icon = () => {
    if (collapsable) {
      if (isCollapsed) {
        return (
          <UpIcon
            className="cursor-pointer w-3 text-black hover:text-dark-blue"
            onClick={onClick}
          />
        )
      } else {
        return (
          <DownIcon
            className="cursor-pointer w-3 text-black hover:text-dark-blue"
            onClick={onClick}
          />
        )
      }
    } else {
      return (
        <CloseIcon
          className="cursor-pointer w-3 text-black hover:text-red"
          onClick={onClick}
        />
      )
    }
  }

  // a refactor would be better
  return (
    <div className={`flex justify-between items-center px-6 pt-6 pb-5 ${className}`}>
      <div>
        <div className="text-xl text-red">{children}</div>
        {subtitle && <div className="text-xl">{subtitle}</div>}
      </div>
      <div>{Icon()}</div>
    </div>
  )
}

interface DividerProps {
  className?: string
}
export const Divider: React.FC<DividerProps> = ({ className = '' }) => (
  <div className={`w-full bg-light-grey h-px ${className}`} />
)
