import { observer } from 'mobx-react-lite'
import React from 'react'
import { useMst } from '../state'
import { useLocalization } from '../lib/hooks/useLocalization'
// @ts-ignore
import { ReactComponent as FiuLogo } from '../assets/fiu-logo.svg'
// @ts-ignore
import { ReactComponent as UniversitaLogo } from '../assets/universita-logo.svg'
// @ts-ignore
import { ReactComponent as ComuneLogo } from '../assets/comune-logo.svg'
// @ts-ignore
import { ReactComponent as RegioneLogo } from '../assets/regione-logo.svg'
// @ts-ignore
import { ReactComponent as UnipolisLogo } from '../assets/unipolis-logo.svg'
// @ts-ignore
import { ReactComponent as ErgoLogo } from '../assets/ergo-logo.svg'
// @ts-ignore
import { ReactComponent as MonteLogo } from '../assets/monte-logo.svg'
// @ts-ignore
import { ReactComponent as AcerLogo } from '../assets/acer-logo.svg'
// @ts-ignore
import { ReactComponent as AccuratLogo } from '../assets/accurat-logo.svg'
import { Button } from './Header'
import { EN, IT } from '../lib/constants'

export const AboutPage: React.FC = observer(() => {
  const {
    ui: {
      isMobile,
      setIsAboutPageOpen,
      setIsInHomepage,
      setIsMenuPageOpen,
      setIsTutorialPageOpen,
      setIsVideoPageOpen,
      language,
      setLanguage,
    },
  } = useMst()

  return (
    <div className="flex flex-col justify-between w-full h-full absolute px-6 py-4 top-0 left-0 z-5 bg-white">
      <div className="top-0 sticky z-1">
        <div className="flex">
          <Button
            className={`pr-2 hover:text-red ${language === IT ? 'font-bold underline' : ''}`}
            textToShow={IT}
            onClick={() => setLanguage(IT)}
          />
          <Button
            className={`hover:text-red ${language === EN ? 'font-bold underline' : ''}`}
            textToShow={EN}
            onClick={() => setLanguage(EN)}
          />
        </div>

        <div className="flex flex-col items-center">
          <div className="flex flex-row">
            <div
              className="px-3 pb-2 pt-3 mx-2 text-black text-md uppercase cursor-pointer border border-white hover:border-red"
              onClick={() => {
                setIsInHomepage(true)
                setIsAboutPageOpen(false)
                setIsTutorialPageOpen(false)
                setIsMenuPageOpen(false)
              }}
            >
              {useLocalization('homepage')}
            </div>

            <div
              className="px-3 pb-2 pt-3 mx-2 text-black text-md cursor-pointer uppercase border border-white hover:border-red"
              onClick={() => setIsVideoPageOpen(true)}
            >
              {useLocalization('video')}
            </div>

            <div
              className="px-3 pb-2 pt-3 mx-2 text-black text-md uppercase cursor-pointer border border-white hover:border-red"
              onClick={() => {
                setIsTutorialPageOpen(true)
                setIsAboutPageOpen(false)
              }}
            >
              {useLocalization('tutorial')}
            </div>

            <div
              className="underline px-3 pb-2 pt-3 mx-2 text-black text-md uppercase cursor-pointer border border-white hover:border-red"
              onClick={() => setIsAboutPageOpen(true)}
            >
              {useLocalization('about')}
            </div>

            <div
              className="px-3 pb-2 pt-3 mx-2 text-black text-md uppercase cursor-pointer border border-white hover:border-red"
              onClick={() => {
                setIsInHomepage(true)
                setIsAboutPageOpen(false)
                setIsTutorialPageOpen(false)
                setIsMenuPageOpen(false)
              }}
            >
              {useLocalization('back')}
            </div>
          </div>

          <div
            className="text-xs underline px-3 pb-2 pt-3 mx-2 text-black text-md cursor-pointer border border-white hover:border-red"
            onClick={() => {
              setIsAboutPageOpen(false)
              setIsTutorialPageOpen(false)
              setIsMenuPageOpen(false)
              setIsInHomepage(true)
            }}
          >
            {useLocalization('close')}
          </div>

          <div className="border-b border-gray-300 my-3 w-full"></div>
        </div>
      </div>
      <div className="flex flex-col items-center pt-10 overflow-y-auto">
        <div className={`${isMobile ? 'w-full' : 'w-3/5'} text-left`}>
          <div className="text-lg items-left text-left px-6">
            {/* progetto */}
            <div className="text-2xl mb-5 mt-4 text-super-grey">
              {useLocalization('the_project')}
            </div>
            <div className="mb-3">
              <div className="text-super-grey">{`${useLocalization('sponsored_by')}: `}</div>
              <div>{useLocalization('fiu')}</div>
            </div>

            <div className="mb-3">
              <div className="text-super-grey">{`${useLocalization('in_partnership')}: `}</div>
              <div>{useLocalization('municipality')}</div>
            </div>

            <div className="mb-3">
              <div className="text-super-grey">{`${useLocalization('funded_by')}: `}</div>
              <div>{useLocalization('funded_by_content')}</div>
            </div>

            <div className="mb-3">
              <div className="text-super-grey">{`${useLocalization('with_the_support_of')}: `}</div>
              <div>{useLocalization('unipolis_foundation')}</div>
            </div>

            <div className="mb-3">
              <div className="text-super-grey">{`${useLocalization('made_by')}: `}</div>
              <div>Accurat</div>
            </div>

            <div className="flex flex-wrap mt-8">
              <Logo className="mr-2 mb-2">
                <FiuLogo className="w-full h-full" />
              </Logo>
              <Logo className="mr-2 mb-2">
                <ComuneLogo className="w-full h-full" />
              </Logo>
              <Logo className="mr-2 mb-2">
                <UnipolisLogo className="w-full h-full" />
              </Logo>
              <Logo className="mr-2 mb-2">
                <AccuratLogo className="w-full h-full" />
              </Logo>
            </div>

            <div className="w-100 mt-6 mb-2 border"></div>
            <div className="w-100 mb-6 border"></div>

            {/*  HousingBO */}
            <div className="text-2xl mb-5 mt-14 text-super-grey">
              {useLocalization('first_chapter')}
            </div>

            <div className="mb-3">
              <div className="text-super-grey">{`${useLocalization('curated_by')}: `}</div>
              <div>{useLocalization('curated_by_content')}</div>
            </div>

            <div className="mb-3">
              <div className="text-super-grey">{`${useLocalization('sponsored_by')}: `}</div>
              <div>{useLocalization('sponsored_by_content')}</div>
            </div>

            <div className="mb-3">
              <div className="text-super-grey">{`${useLocalization('led_by')}: `}</div>
              <div>{useLocalization('fiu')}</div>
            </div>

            <div className="mb-3">
              <div className="text-super-grey">{`${useLocalization('in_partnership')}: `}</div>
              <div>{useLocalization('in_partnership_content')}</div>
            </div>

            <div className="flex flex-wrap mt-8">
              <Logo className="mr-2 mb-2">
                <UniversitaLogo className="w-full h-full" />
              </Logo>
              <Logo className="mr-2 mb-2">
                <ComuneLogo className="w-full h-full" />
              </Logo>
              <Logo className="mr-2 mb-2">
                <FiuLogo className="w-full h-full" />
              </Logo>
              <Logo className="mr-2 mb-2">
                <AcerLogo className="w-full h-full" />
              </Logo>
            </div>

            {/* divider */}
            <div className="w-100 my-6 border"></div>
            {/* divider */}
          </div>
        </div>
      </div>
    </div>
  )
})

interface LogoProps {
  className?: string
}
const Logo: React.FC<LogoProps> = ({ className = '', children }) => {
  return (
    <div
      className={`bg-white p-2 rounded-md border border-light-gray w-28 h-28 flex justify-center items-center ${className}`}
    >
      {children}
    </div>
  )
}
