import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../state'

export const Loader: React.FC<{}> = observer(({}) => {
  const {
    ui: { isLoading },
  } = useMst()

  return (
    <div
      className={`z-6 absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-white bg-opacity-80 ${
        isLoading ? 'visible' : 'invisible'
      }`}
    >
      <div id="loader-container">
        <div id="loader-border" />
        <div id="loader-dot" />
      </div>
    </div>
  )
})
