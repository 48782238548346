import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import mapboxgl, { LngLat } from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import LatLon from 'geodesy/latlon-spherical.js'
import { useMst } from '../state'
import {
  DOTS_ID_SOURCE,
  FEATURES_ID_SOURCE,
  DOTS_ID_LAYER,
  CROWDMAP_BOUNDARY_CIRCLE_ID_SOURCE,
  ERROR_COORDINATES,
} from '../lib/constants'
import {
  mapCenterLngLat,
  mapDefaultConfig,
  crowdmapDefaultConfig,
  maxZoom,
  minZoom,
  crowdmapCircleBoundarySource,
} from '../lib/map-config'
import {
  updateLayerDataSource,
  updatePaintProperty,
  isFeaturesLayer,
  printActiveLayers,
  isPointInCircleBoundary,
} from '../lib/map-utils'
import {
  createFeatureFillLayer,
  createFeatureStrokeLayer,
  createPointsLayer,
  createCrowdmapPointsLayer,
  createCrowdmapCircleBoundaryStrokeLayer,
  createCrowdmapCircleBoundaryFillLayer,
} from '../lib/style-utils'
import { ZoomControls } from './ZoomControls'
import { Marker } from './Marker'
import { isNil } from 'lodash-es'

// bind the MapboxGL access token
mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN as string

interface Props {
  className?: string
  children?: React.ReactNode
  isInteractive?: boolean
}

export const Map: React.FC<Props> = observer(
  ({ className = '', children, isInteractive = true }) => {
    const stateInstance = useMst()
    const {
      data: {
        pointsGeojsonSource,
        featuresGeojsonSource,
        statisticAreaMeasureValue: areaMeasureValue,
        filteredDataset,
        isCrowdmapActive,
        setCollaborateCoordinates,
      },
      filters: { observingFeature },
      ui: { isMobile, setIsLoading, collaborateMarkerPercentage, addErrorMessage, removeError },
      map: {
        setMapboxMap,
        unsetMapboxMap,
        mapboxMap,
        hoveredFeatureId,
        clickedFeatureId,
        setClickedFeatureId,
        setHoveredFeatureId,
        mapRendered,
        mapStyleKey,
        mapStyles,
        sortedLayersByZIndexFromBottom,
      },
    } = stateInstance

    const mapContainerRef = useRef<HTMLDivElement | null>(null)
    const [loaded, setLoaded] = useState<boolean>(false)
    const previousHoveredId = useRef<string | number | undefined>(undefined)
    const previousClickedId = useRef<string | number | undefined>(undefined)
    const [markerCoordinates, setMarkerLocalCoordinates] = useState<LngLat>(mapCenterLngLat)

    // create a popup, but don't add it to the map yet
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      focusAfterOpen: false,
    })

    // map initialization
    function initialize() {
      if (!mapContainerRef.current) return

      let isSubscribed = true
      setIsLoading(true)

      const mapConfig = isCrowdmapActive ? crowdmapDefaultConfig : mapDefaultConfig
      const startingZoom = stateInstance.map.zoom ?? mapConfig.zoom
      const startingCenter = stateInstance.map.center ?? mapConfig.center
      const startingPitch = 0

      const mapInstance = new mapboxgl.Map({
        container: mapContainerRef.current,
        interactive: isInteractive,
        ...mapConfig,
        zoom: startingZoom,
        center: startingCenter,
        pitch: startingPitch,
        preserveDrawingBuffer: true,
        style: isCrowdmapActive ? crowdmapDefaultConfig.style : mapStyles[mapStyleKey].url,
      })

      mapInstance.on('load', () => {
        if (isSubscribed) {
          setLoaded(true)
          setIsLoading(false)
        }
      })

      setMapboxMap(mapInstance)
      return () => {
        unsetMapboxMap()
        isSubscribed = false
      }
    }

    function addSource(id: string, source: mapboxgl.GeoJSONSourceRaw) {
      if (!mapboxMap || !loaded) return
      if (mapboxMap.getSource(id)) {
        return
      }
      mapboxMap.addSource(id, source)
    }

    function addLayer(layer: mapboxgl.AnyLayer) {
      if (!mapboxMap || !loaded) return
      if (mapboxMap.getLayer(layer.id)) return
      mapboxMap.addLayer(layer)
    }

    // set the data into the map when either:
    //  - map has loaded
    //  - new data has loaded
    function setLayers() {
      if (!mapboxMap || !loaded) return

      // add sources and layers
      if (isCrowdmapActive) {
        addSource(DOTS_ID_SOURCE, pointsGeojsonSource)
        addSource(CROWDMAP_BOUNDARY_CIRCLE_ID_SOURCE, crowdmapCircleBoundarySource)
        addLayer(createCrowdmapCircleBoundaryFillLayer())
        addLayer(createCrowdmapCircleBoundaryStrokeLayer())
        addLayer(createCrowdmapPointsLayer())
      } else {
        addSource(FEATURES_ID_SOURCE, featuresGeojsonSource)
        addSource(DOTS_ID_SOURCE, pointsGeojsonSource)
        addLayer(createFeatureFillLayer(areaMeasureValue))
        addLayer(createFeatureStrokeLayer())
        addLayer(createPointsLayer())
        updateLayersVisibility()
        updateLayersOrder()
      }
    }

    function updateLayersOrder() {
      if (!mapboxMap || !loaded) return
      sortedLayersByZIndexFromBottom.forEach(([layerId]) => {
        if (!mapboxMap.getLayer(layerId)) return
        mapboxMap.moveLayer(layerId)
      })
    }

    function getInteractedFeature(
      e: mapboxgl.MapMouseEvent & mapboxgl.EventData
    ): mapboxgl.MapboxGeoJSONFeature | null {
      const map = e.target
      const position = e.point
      const features = map.queryRenderedFeatures(position)
      const hasInteractedWithFeatures = features.length > 0 && isFeaturesLayer(features)
      return hasInteractedWithFeatures ? features[0] : null
    }

    function onMapClick(e: mapboxgl.MapMouseEvent & mapboxgl.EventData) {
      const feature = getInteractedFeature(e)
      setClickedFeatureId(feature ? feature.id : undefined)
    }

    function onMapHover(e: mapboxgl.MapMouseEvent & mapboxgl.EventData) {
      const feature = getInteractedFeature(e)
      setHoveredFeatureId(feature ? feature.id : undefined)
    }

    function clickedFeatureReaction() {
      if (!mapboxMap || !loaded || isCrowdmapActive) return
      const id = clickedFeatureId
      const oldId = previousClickedId.current
      if (id) mapboxMap.setFeatureState({ source: FEATURES_ID_SOURCE, id }, { click: true })
      if (oldId)
        mapboxMap.setFeatureState({ source: FEATURES_ID_SOURCE, id: oldId }, { click: false })
      previousClickedId.current = clickedFeatureId
    }

    function hoveredFeatureReaction() {
      if (!mapboxMap || !loaded || isCrowdmapActive) return
      const id = hoveredFeatureId
      const oldId = previousHoveredId.current
      if (id) mapboxMap.setFeatureState({ source: FEATURES_ID_SOURCE, id }, { hover: true })
      if (oldId)
        mapboxMap.setFeatureState({ source: FEATURES_ID_SOURCE, id: oldId }, { hover: false })
      previousHoveredId.current = hoveredFeatureId
    }

    function setInteractivity() {
      if (!mapboxMap || !loaded) return
      mapboxMap.on('click', onMapClick)
      mapboxMap.on('mousemove', onMapHover)

      return () => {
        mapboxMap.off('click', onMapClick)
        mapboxMap.off('mousemove', onMapHover)
      }
    }

    function setPointsInteractivity() {
      if (!mapboxMap || !loaded) return
      mapboxMap.on('mouseenter', DOTS_ID_LAYER, showPointPopup)
      mapboxMap.on('mouseleave', DOTS_ID_LAYER, hidePointPopup)

      return () => {
        mapboxMap.off('mouseenter', DOTS_ID_LAYER, showPointPopup)
        mapboxMap.off('mouseleave', DOTS_ID_LAYER, hidePointPopup)
      }
    }

    function showPointPopup(e: any) {
      if (!mapboxMap) return
      mapboxMap.getCanvas().style.cursor = 'pointer'
      const coordinates = e.features[0].geometry.coordinates.slice()
      const description = isCrowdmapActive
        ? e.features[0].properties.description
        : `${observingFeature.question}: ${e.features[0].properties[observingFeature.question]}`
      // ensure that if the map is zoomed out such that multiple copies of the
      // feature are visible, the popup appears over the copy being pointed to
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      // populate the popup and set its coordinates based on the feature found
      popup.setLngLat(coordinates).setHTML(description).addTo(mapboxMap)
    }

    function hidePointPopup() {
      if (!mapboxMap) return
      mapboxMap.getCanvas().style.cursor = ''
      popup.remove()
    }

    function getCollaboratePointerCoordinates(): LngLat {
      if (!mapboxMap || !loaded) return mapCenterLngLat
      const bounds = mapboxMap.getBounds()
      const xPercentage = collaborateMarkerPercentage.x
      const yPercentage = collaborateMarkerPercentage.y

      const boundsNorth = new LatLon(bounds.getNorth(), bounds.getCenter().lng)
      const boundsSouth = new LatLon(bounds.getSouth(), bounds.getCenter().lng)
      const boundsEast = new LatLon(bounds.getCenter().lat, bounds.getEast())
      const boundsWest = new LatLon(bounds.getCenter().lat, bounds.getWest())
      const lat = boundsNorth.intermediatePointTo(boundsSouth, yPercentage).lat
      const lng = boundsEast.intermediatePointTo(boundsWest, xPercentage).lng

      return { lng, lat } as LngLat
    }

    function onMapDrag() {
      const center = getCollaboratePointerCoordinates()
      setMarkerLocalCoordinates(center)
    }

    function onMapIdle() {
      const markerCoords = getCollaboratePointerCoordinates()
      setMarkerLocalCoordinates(markerCoords)
      setCollaborateCoordinates(markerCoords)
    }

    function saveMarkerCoordinates() {
      if (!mapboxMap || !loaded || !isCrowdmapActive) return
      // during drag save marker coords in local state only to update the visible coordinates
      mapboxMap.on('drag', onMapDrag)
      // when the last frame rendered before the map enters an "idle" state,
      // save the marker coordinates in both local state and mobx state
      mapboxMap.on('idle', onMapIdle)

      return () => {
        mapboxMap.off('drag', onMapDrag)
        mapboxMap.off('drag', onMapIdle)
      }
    }

    // update the features layer when either:
    //  - observingFeature changes
    //  - filteredDataset changes
    function updateFeaturesLayer() {
      if (!mapboxMap || !loaded) return
      // crowdmap has not fill
      if (!isCrowdmapActive) {
        const { id, paint } = createFeatureFillLayer(areaMeasureValue)
        updatePaintProperty(mapboxMap, id, 'fill-color', paint?.['fill-color'])
      }
      updateLayerDataSource(mapboxMap, FEATURES_ID_SOURCE, featuresGeojsonSource?.data)
    }

    // update the dots layer when:
    //  - filteredDataset changes
    function updateDotsLayer() {
      if (!mapboxMap || !loaded) return
      // filter dots to show only the filtered ones
      const filteredIds = filteredDataset.map((s) => s.id)
      if (filteredDataset.length > 0) {
        if (!mapboxMap.getLayer(DOTS_ID_LAYER) || filteredIds.some((id) => isNil(id))) return
        mapboxMap.setFilter(DOTS_ID_LAYER, ['match', ['get', 'id'], filteredIds, true, false])
      }
    }

    function updateLayersVisibility() {
      if (!mapboxMap || !loaded) return
      const layers = mapStyles[mapStyleKey].layers
      Object.entries(layers).forEach(([layerKey, { visibility }]) => {
        if (!mapboxMap.getLayer(layerKey)) return
        mapboxMap.setLayoutProperty(layerKey, 'visibility', visibility)
      })
    }

    function updateMapStyle() {
      if (!mapboxMap || !loaded) return
      mapboxMap.setStyle(mapStyles[mapStyleKey].url)
      // style.load is not a documentated event but I didn't find a working solution using documented events
      mapboxMap.on('style.load', () => setLayers())
    }

    function setMarkerErrorMessage() {
      if (!mapboxMap || !loaded || !isCrowdmapActive) return
      const { lng, lat } = markerCoordinates
      if (!isPointInCircleBoundary([lng, lat])) addErrorMessage(ERROR_COORDINATES)
      else removeError(ERROR_COORDINATES)
    }

    useEffect(initialize, [])
    useEffect(setLayers, [loaded, mapRendered])
    useEffect(setInteractivity, [loaded, mapRendered])
    useEffect(setPointsInteractivity, [loaded, mapRendered, observingFeature])
    useEffect(saveMarkerCoordinates, [loaded, mapRendered])
    useEffect(updateFeaturesLayer, [observingFeature, filteredDataset])
    useEffect(updateDotsLayer, [filteredDataset])
    useEffect(hoveredFeatureReaction, [hoveredFeatureId])
    useEffect(clickedFeatureReaction, [clickedFeatureId])
    useEffect(updateLayersVisibility, [mapStyles[mapStyleKey]])
    useEffect(updateMapStyle, [mapStyleKey])
    useEffect(setMarkerErrorMessage, [markerCoordinates])

    return (
      <div
        className={`w-full h-full overflow-hidden ${className} ${
          isInteractive ? '' : 'pointer-events-none'
        }`}
      >
        <div className="w-full h-full relative" ref={mapContainerRef}>
          {isCrowdmapActive && <Marker centerCoordinates={markerCoordinates} />}

          {!isMobile && (
            <ZoomControls
              isInteractive={isInteractive}
              currentZoom={(mapboxMap && mapboxMap.getZoom()) as number}
              minZoom={minZoom}
              maxZoom={maxZoom}
              onZoomIn={() => mapboxMap?.zoomIn({ duration: 500 })}
              onZoomOut={() => mapboxMap?.zoomOut({ duration: 500 })}
            />
          )}

          {children}
        </div>
      </div>
    )
  }
)
