import { types as t, getRoot, Instance } from 'mobx-state-tree'
import { debounce, uniq, without } from 'lodash-es'
import { addOrRemove } from '../lib/array-utils'
import { CollaborateDatum, DatasetsSpreadsheetRow, Language } from '../types'
import {
  ERROR_COORDINATES,
  ERROR_DESCRIPTION_LENGTH,
  HEADER_HEIGHT,
  LANGUAGE,
  MAX_DESCRIPTION_LENGTH,
} from '../lib/constants'
import { MAP, Modality, STORY } from '../components/FloatingButtons'
import { ADMIN, getEnableDownloadData, getUserType } from '../lib/user'
import { isPointInCircleBoundary } from '../lib/map-utils'

const MOBILE_THRESHOLD = 700 // 1080

export const UIModel = t
  .model('UIModel', {
    isLoading: false,
    isInHomepage: true,
    isMenuPageOpen: false,
    isAboutPageOpen: false,
    isTutorialPageOpen: false,
    isVideoPageOpen: false,
    infoDataset: t.optional(t.maybeNull(t.frozen<DatasetsSpreadsheetRow>()), null),
    isFilterPanelOpen: false,
    userType: t.frozen(getUserType()),
    featureOpenFilters: t.optional(t.array(t.string), []),
    windowWidth: 0,
    windowHeight: 0,
    neighborhoodSearch: t.optional(t.string, ''),
    isCollaboraPanelOpen: false,
    isColaboratePopupVisible: false,
    modality: t.optional(t.frozen<Modality>(), STORY),
    isAreaPanelOpen: false,
    isStoryPanelOpen: true,
    isStoryPanelCollapsed: false,
    isOtherPanelOpen: false,
    isInfoPanelOpen: false,
    isLayersPanelOpen: false,
    crowdmapAnswer: t.optional(t.maybeNull(t.frozen<CollaborateDatum>()), null),
    errorMessages: t.optional(t.array(t.string), []),
    thereWasACloudFunctionError: false,
    isSelectedHomepageTabCrowdmap: false,
    language: LANGUAGE,
  })
  .views((self) => ({
    get root() {
      return getRoot(self) as any
    },

    get isMobile() {
      return self.windowWidth <= MOBILE_THRESHOLD
    },

    get isAdmin() {
      return Boolean(self.userType) && self.userType === ADMIN
    },

    get enabledDownloadData() {
      return Boolean(getEnableDownloadData())
    },

    get isHomepageModalDatasetOpen() {
      return self.infoDataset !== null
    },

    get isMapModality() {
      return self.modality === MAP
    },

    get isStoryModality() {
      return self.modality === STORY
    },
  }))
  .views((self) => ({
    get showDoubleHeader() {
      const {
        data: { isCrowdmapActive },
      } = self.root
      return !isCrowdmapActive && !self.isMobile && !self.isInHomepage
    },

    isFeatureFilterOpen(question: string) {
      return self.featureOpenFilters.includes(question)
    },

    get collaborateMarkerPercentage(): { x: number; y: number } {
      return self.isMobile ? { x: 0.5, y: 0.2 } : { x: 0.5, y: 0.5 }
    },
  }))
  .views((self) => ({
    get headerHeight() {
      return self.showDoubleHeader ? 2 * HEADER_HEIGHT : HEADER_HEIGHT
    },

    isFeatureFilterOpen(feature: string) {
      return self.featureOpenFilters.includes(feature)
    },
  }))
  .views((self) => ({
    get userCanInteract() {
      const {
        data: { hasStory, story },
      } = self.root
      return !hasStory || !story.isPlaying
    },
  }))
  .views((self) => ({
    get showDatasetSelector() {
      return true
    },

    get showFloatingButtons() {
      return true
    },

    get showFiltersPanel() {
      return self.isFilterPanelOpen && self.userCanInteract // why usercaninyteract
    },

    get showAreaPanel() {
      return self.isAreaPanelOpen
    },

    get showStoryPanel() {
      return self.isStoryPanelOpen && self.isStoryModality
    },

    get showOtherPanel() {
      return self.isOtherPanelOpen
    },

    get showInfoPanel() {
      return self.isInfoPanelOpen
    },

    get showCollaboratePanel() {
      const {
        data: { isCrowdmapActive },
      } = self.root
      return isCrowdmapActive
    },
  }))
  .actions((self) => ({
    addErrorMessage(error: string) {
      self.errorMessages.replace(uniq([...self.errorMessages, error]))
    },
    removeError(error: string) {
      self.errorMessages.replace(without(self.errorMessages, error))
    },
    resetErrorMessages() {
      self.errorMessages.replace([])
    },

    setIsLoading(value: boolean) {
      self.isLoading = value
    },

    setIsInHomepage(isInHomepage: boolean) {
      // remove state param value
      if (isInHomepage) {
        const urlParams = new URLSearchParams(window.location.search)
        urlParams.delete('state')
      }
      self.isInHomepage = isInHomepage
    },

    setIsMenuPageOpen(isMenuPageOpen: boolean) {
      self.isMenuPageOpen = isMenuPageOpen
    },

    setIsAboutPageOpen(isAboutPageOpen: boolean) {
      self.isAboutPageOpen = isAboutPageOpen
    },

    setIsTutorialPageOpen(isTutorialPageOpen: boolean) {
      self.isTutorialPageOpen = isTutorialPageOpen
    },

    setIsVideoPageOpen(isVideoPageOpen: boolean) {
      self.isVideoPageOpen = isVideoPageOpen
    },

    setInfoDataset(datasetInfo: DatasetsSpreadsheetRow | null) {
      self.infoDataset = datasetInfo
    },

    openFilterPanel() {
      self.isFilterPanelOpen = true
    },

    closeFilterPanel() {
      self.isFilterPanelOpen = false
    },

    toggleFilterPanelOpen() {
      self.isFilterPanelOpen = !self.isFilterPanelOpen
    },

    resetFeatureOpenFilters() {
      self.featureOpenFilters.replace([])
    },

    toggleFeatureOpenFilters(question: string) {
      const newValues = addOrRemove(self.featureOpenFilters, question)
      self.featureOpenFilters.replace(newValues)
    },

    computeWindowSize() {
      self.windowWidth = window.innerWidth
      self.windowHeight = window.innerHeight
    },

    setNeighborhoodSearch(searchText: string) {
      self.neighborhoodSearch = searchText
    },

    resetNeighborhoodSearch() {
      self.neighborhoodSearch = ''
    },

    setIsCollaboraPanelOpen(isCollaboraPanelOpen: boolean) {
      self.isCollaboraPanelOpen = isCollaboraPanelOpen
    },

    setIsColaboratePopupVisible(isColaboratePopupVisible: boolean) {
      self.isColaboratePopupVisible = isColaboratePopupVisible
    },

    setIsAreaPanelOpen(value: boolean) {
      self.isAreaPanelOpen = value
    },

    toggleIsAreaPanelOpen() {
      self.isAreaPanelOpen = !self.isAreaPanelOpen
    },

    setIsStoryPanelOpen(value: boolean) {
      const {
        data: { hasStory },
      } = self.root
      self.isStoryPanelOpen = hasStory && value
    },

    toggleIsStoryPanelOpen() {
      self.isStoryPanelOpen = !self.isStoryPanelOpen
    },

    setIsStoryPanelCollapsed(isStoryPanelCollapsed: boolean) {
      self.isStoryPanelCollapsed = isStoryPanelCollapsed
    },

    toggleIsStoryPanelCollapsed() {
      self.isStoryPanelCollapsed = !self.isStoryPanelCollapsed
    },

    setIsOtherPanelOpen(value: boolean) {
      self.isOtherPanelOpen = value
    },

    toggleIsOtherPanelOpen() {
      self.isOtherPanelOpen = !self.isOtherPanelOpen
    },

    setIsInfoPanelOpen(value: boolean) {
      self.isInfoPanelOpen = value
    },

    toggleIsInfoPanelOpen() {
      self.isInfoPanelOpen = !self.isInfoPanelOpen
    },

    setIsLayersPanelOpen(value: boolean) {
      self.isLayersPanelOpen = value
    },

    toggleIsLayersPanelOpen() {
      self.isLayersPanelOpen = !self.isLayersPanelOpen
    },

    setThereWasACloudFunctionError(value: boolean) {
      self.thereWasACloudFunctionError = value
    },

    setIsSelectedHomepageTabCrowdmap(value: boolean) {
      self.isSelectedHomepageTabCrowdmap = value
    },

    setLanguage(language: Language) {
      self.language = language
    },
  }))
  .actions((self) => ({
    setCrowdmapErrors() {
      if (self.crowdmapAnswer === null) return

      self.resetErrorMessages()
      const { description, Latitude, Longitude } = self.crowdmapAnswer

      let isTextValid = true
      let isCoordsValid = true
      if (description.length > MAX_DESCRIPTION_LENGTH) {
        isTextValid = false
        self.addErrorMessage(ERROR_DESCRIPTION_LENGTH)
      }
      if (!isPointInCircleBoundary([Longitude, Latitude])) {
        isCoordsValid = false
        self.addErrorMessage(ERROR_COORDINATES)
      }
    },
  }))
  .actions((self) => ({
    setCrowdmapAnswer(answer: CollaborateDatum | null) {
      self.crowdmapAnswer = answer
      self.setCrowdmapErrors()
    },
  }))
  .views((self) => ({
    get isCrowdmapAnswerValid() {
      return self.errorMessages.length === 0
    },
  }))
  .actions((self) => ({
    setModality(modality: Modality) {
      self.modality = modality
      self.setIsStoryPanelOpen(modality === STORY)
    },

    resetModality() {
      self.modality = STORY
    },
  }))
  .actions((self) => ({
    toggleModality() {
      const {
        data: { story },
      } = self.root
      if (self.isMapModality) {
        self.setModality(STORY)
      } else {
        story?.pauseStory()
        self.setModality(MAP)
      }
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.computeWindowSize()
      window.addEventListener('resize', debounce(self.computeWindowSize, 50))
    },
  }))

export interface UIModelInstance extends Instance<typeof UIModel> {}
